import React, { useState, useEffect } from "react";
import TopbarComponent from "../components/Topbar";
import GuidelineComponent from "../components/Guideline6";
import Nextbutton from "../components/Nextbutton";
import DropdownComponent from "../components/Dropdown";
import kakaoaa from "../image/kakaoaa.png";
import AlertHeaderPc1 from "../components/AlertHeaderPc1";
import "../text.css";
import nextbtn_new from "../image/nextbtn_new.png";
import icon1 from "../image/pc-g-icon-1.svg";
import icon2 from "../image/pc-g-icon-2.svg";
import icon3 from "../image/pc-g-icon-3.svg";
import icon4 from "../image/pc-g-icon-4.svg";
import icon5 from "../image/pc-g-icon-5.svg";
import icon6 from "../image/pc-g-icon-6.svg";
import icon7 from "../image/pc-g-icon-7.svg";
import icon8 from "../image/pc-g-icon-8.svg";
import icon9 from "../image/pc-g-icon-9.svg";
import icon10 from "../image/pc-g-icon-10.svg";
import icon11 from "../image/pc-g-icon-11.svg";
import icon12 from "../image/pc-g-icon-12.svg";
import icon13 from "../image/pc-g-icon-13.svg";
import icon14 from "../image/pc-g-icon-14.svg";
import icon15 from "../image/pc-g-icon-15.svg";
import icon16 from "../image/pc-g-icon-16.svg";
import icon17 from "../image/pc-g-icon-17.svg";
import icon18 from "../image/pc-g-icon-18.svg";
import blu1 from "../image/pc-b-icon-1.svg";
import blu2 from "../image/pc-b-icon-2.svg";
import blu3 from "../image/pc-b-icon-3.svg";
import blu4 from "../image/pc-b-icon-4.svg";
import blu5 from "../image/pc-b-icon-5.svg";
import blu6 from "../image/pc-b-icon-6.svg";
import blu7 from "../image/pc-b-icon-7.svg";
import blu8 from "../image/pc-b-icon-8.svg";
import blu9 from "../image/pc-b-icon-9.svg";
import blu10 from "../image/pc-b-icon-10.svg";
import blu11 from "../image/pc-b-icon-11.svg";
import blu12 from "../image/pc-b-icon-12.svg";
import blu13 from "../image/pc-b-icon-13.svg";
import blu14 from "../image/pc-b-icon-14.svg";
import blu15 from "../image/pc-b-icon-15.svg";
import blu16 from "../image/pc-b-icon-16.svg";
import blu17 from "../image/pc-b-icon-17.svg";
import blu18 from "../image/pc-b-icon-18.svg";
import ask3 from "../image/znzn.png";
import $ from "jquery";
import new_blue from "../image/new_blue.png";
import uploadBtn from "../image/uploadBtn.png";
import BrowserHeader from "../components/BrowserHeader";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  IsMobile,
  TabletView,
} from "react-device-detect";
const preloadImages = (imageArray) => {
  imageArray.forEach((image) => {
    const img = new Image();
    img.src = image;
  });
};
var classList = [];
var classList2 = [];
function BrandCategoryRegister() {
  const [count, setCount] = useState(0);
  const [eventcss, setEventcss] = useState("disabled-link");
  const [btntext, setBtntext] = useState("다음");
  const [nextbtn, setNextbtn] = useState(nextbtn_new);
  const [btntext2, setBtntext2] = useState(0);
  const [img1, setText1] = useState([icon1, "categorybox"]);
  const [img2, setText2] = useState([icon2, "categorybox"]);
  const [img3, setText3] = useState([icon3, "categorybox"]);
  const [img4, setText4] = useState([icon4, "categorybox"]);
  const [img5, setText5] = useState([icon5, "categorybox"]);
  const [img6, setText6] = useState([icon6, "categorybox"]);
  const [img7, setText7] = useState([icon7, "categorybox"]);
  const [img8, setText8] = useState([icon8, "categorybox"]);
  const [img9, setText9] = useState([icon9, "categorybox"]);
  const [img10, setText10] = useState([icon10, "categorybox"]);
  const [img11, setText11] = useState([icon11, "categorybox"]);
  const [img12, setText12] = useState([icon12, "categorybox"]);
  const [img13, setText13] = useState([icon13, "categorybox"]);
  const [img14, setText14] = useState([icon14, "categorybox"]);
  const [img15, setText15] = useState([icon15, "categorybox"]);
  const [img16, setText16] = useState([icon16, "categorybox"]);
  const [img17, setText17] = useState([icon17, "categorybox"]);
  const [img18, setText18] = useState([icon18, "categorybox"]);
  useEffect(() => {
    // 사전 로드할 이미지 배열
    const imagesToPreload = [
      icon1,
      icon2,
      icon3,
      icon4,
      icon5,
      icon6,
      icon7,
      icon8,
      icon9,
      icon10,
      icon11,
      icon12,
      icon13,
      icon14,
      icon15,
      icon16,
      icon17,
      icon18,
      blu1,
      blu2,
      blu3,
      blu4,
      blu5,
      blu6,
      blu7,
      blu8,
      blu9,
      blu10,
      blu11,
      blu12,
      blu13,
      blu14,
      blu15,
      blu16,
      blu17,
      blu18,
      new_blue,
      kakaoaa,
      ask3,
    ];

    preloadImages(imagesToPreload);

    // 기존 onKeyUP 호출
  }, []);
  useEffect(() => {
    onKeyUP();
  }, [count]);
  const onClick1 = (i) => {
    if (img1[0] == icon1) {
      setText1([blu1, "categorybox2"]);
      setCount(count + 1);
      classList.push(35);
      classList2.push("온라인쇼핑몰/ 스마트스토어 (35류)");
    } else {
      setText1([icon1, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 35) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "온라인쇼핑몰/ 스마트스토어 (35류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick2 = (i) => {
    if (img2[0] == icon2) {
      setText2([blu2, "categorybox2"]);
      setCount(count + 1);
      classList.push(43);
      classList2.push("음식점/ 카페/ 주점 (43류)");
    } else {
      setText2([icon2, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 43) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "음식점/ 카페/ 주점 (43류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick3 = (i) => {
    if (img3[0] == icon3) {
      setText3([blu3, "categorybox2"]);
      setCount(count + 1);
      classList.push(44);
      classList2.push("헤어/ 메이크업/ 네일 등 뷰티샵 (44류)");
    } else {
      setText3([icon3, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 44) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "헤어/ 메이크업/ 네일 등 뷰티샵 (44류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick4 = (i) => {
    if (img4[0] == icon4) {
      setText4([blu4, "categorybox2"]);
      setCount(count + 1);
      classList.push(444);
      classList2.push("병원/ 약국 등 건강진료 서비스 (44류)");
    } else {
      setText4([icon4, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 444) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "병원/ 약국 등 건강진료 서비스 (44류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick5 = (i) => {
    if (img5[0] == icon5) {
      setText5([blu5, "categorybox2"]);
      setCount(count + 1);
      classList.push(30);
      classList2.push("커피/ 차/ 빵/ 과자 등 (30류)");
    } else {
      setText5([icon5, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 30) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "커피/ 차/ 빵/ 과자 등 (30류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick6 = (i) => {
    if (img6[0] == icon6) {
      setText6([blu6, "categorybox2"]);
      setCount(count + 1);
      classList.push(31);
      classList2.push("애완동물용 사료 및 간식 (31류)");
    } else {
      setText6([icon6, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 31) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "애완동물용 사료 및 간식 (31류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick7 = (i) => {
    if (img7[0] == icon7) {
      setText7([blu7, "categorybox2"]);
      setCount(count + 1);
      classList.push(3);
      classList2.push("화장품/ 세면용품/ 향수 등 (3류)");
    } else {
      setText7([icon7, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 3) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "화장품/ 세면용품/ 향수 등 (3류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick8 = (i) => {
    if (img8[0] == icon8) {
      setText8([blu8, "categorybox2"]);
      setCount(count + 1);
      classList.push(5);
      classList2.push("약제 및 건강기능식품 (5류)");
    } else {
      setText8([icon8, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 5) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "약제 및 건강기능식품 (5류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick9 = (i) => {
    if (img9[0] == icon9) {
      setText9([blu9, "categorybox2"]);
      setCount(count + 1);
      classList.push(29);
      classList2.push("가공식품/ 건강보조식품 (29류)");
    } else {
      setText9([icon9, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 29) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "가공식품/ 건강보조식품 (29류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick10 = (i) => {
    if (img10[0] == icon10) {
      setText10([blu10, "categorybox2"]);
      setCount(count + 1);
      classList.push(25);
      classList2.push("의류/ 신발/ 모자 등 (25류)");
    } else {
      setText10([icon10, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 25) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "의류/ 신발/ 모자 등 (25류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick11 = (i) => {
    if (img11[0] == icon11) {
      setText11([blu11, "categorybox2"]);
      setCount(count + 1);
      classList.push(18);
      classList2.push("가방/ 지갑/ 가죽류/ 애완동물 용품 (18류)");
    } else {
      setText11([icon11, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 18) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "가방/ 지갑/ 가죽류/ 애완동물 용품 (18류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick12 = (i) => {
    if (img12[0] == icon12) {
      setText12([blu12, "categorybox2"]);
      setCount(count + 1);
      classList.push(14);
      classList2.push("귀금속제 장신구 (14류)");
    } else {
      setText12([icon12, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 14) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "귀금속제 장신구 (14류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick13 = (i) => {
    if (img13[0] == icon13) {
      setText13([blu13, "categorybox2"]);
      setCount(count + 1);
      classList.push(28);
      classList2.push("스포츠 용품 (28류)");
    } else {
      setText13([icon13, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 28) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "스포츠 용품 (28류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick14 = (i) => {
    if (img14[0] == icon14) {
      setText14([blu14, "categorybox2"]);
      setCount(count + 1);
      classList.push(41);
      classList2.push("공방/ 학원 등 교육 관련 서비스 (41류)");
    } else {
      setText14([icon14, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 41) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "공방/ 학원 등 교육 관련 서비스 (41류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick15 = (i) => {
    if (img15[0] == icon15) {
      setText15([blu15, "categorybox2"]);
      setCount(count + 1);
      classList.push(37);
      classList2.push("건축/ 인테리어 업체 (37류)");
    } else {
      setText15([icon15, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 37) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "건축/ 인테리어 업체 (37류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };

  const onClick16 = (i) => {
    if (img16[0] == icon16) {
      setText16([blu16, "categorybox2"]);
      setCount(count + 1);
      classList.push(38);
      classList2.push("인플루언서/ 크리에이터 (38류)");
    } else {
      setText16([icon16, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 38) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "인플루언서/ 크리에이터 (38류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };

  const onClick17 = (i) => {
    if (img17[0] == icon17) {
      setText17([blu17, "categorybox2"]);
      setCount(count + 1);
      classList.push(9);
      classList2.push("앱/ 웹 서비스 (9류)");
    } else {
      setText17([icon17, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 9) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "앱/ 웹 서비스 (9류)") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const onClick18 = (i) => {
    if (img18[0] == icon18) {
      setText18([blu18, "categorybox2"]);
      setCount(count + 1);
      classList.push(111);
      classList2.push("여기에 없는 특별한 분야");
    } else {
      setText18([icon18, "categorybox"]);
      setCount(count - 1);
      for (var i = 0; i < classList.length; i++) {
        if (classList[i] === 111) {
          classList.splice(i, 1);
          i--;
        }
      }
      for (var i = 0; i < classList2.length; i++) {
        if (classList2[i] === "여기에 없는 특별한 분야") {
          classList2.splice(i, 1);
          i--;
        }
      }
    }
  };
  const search991 = () => {
    let selectclasses = [];
    for (let i = 0; i < classList.length; i++) {
      selectclasses.push(classList[i] + "류이이");
    }
    sessionStorage.setItem("btn_value", classList2);
    sessionStorage.setItem("goodsSearchWord", ".");
    sessionStorage.setItem("selectclasses", selectclasses);
    classList = [];
    classList2 = [];
  };

  const onKeyUP = () => {
    if (count > 0) {
      $("#next_button").css("background-color", "#4a64f5");
      $("#dam").css("color", "#ffffff");
      setEventcss("");
      let textas = count + "개 선택 완료";
      setBtntext(textas);
      setBtntext2(count);
      setNextbtn(new_blue);
    } else {
      $("#next_button").css("background-color", "#efefef");
      $("#dam").css("color", "#b7b7b7");
      setEventcss("disabled-link");
      setBtntext("다음");
      setBtntext2(count);
      setNextbtn(nextbtn_new);
    }
  };
  return (
    <div>
      <MobileView>
        <div style={{ margin: "auto", maxWidth: "520px" }}>
          <TopbarComponent page={"03"} path={"/brandlogoregister"} />

          <div style={{ width: "342px", margin: "auto" }}>
            <GuidelineComponent
              title1={"어떤 제품, 서비스에"}
              title2={"상표를 사용하세요"}
              help={"원하는 분류가 없는 경우, 마지막에 있는"}
              help2={"'여기에 없는 특별한 분야' 를 선택하세요 :)"}
            />
          </div>

          <div
            id="1"
            onClick={() => onClick1()}
            style={{ fontSize: "16px", lineHeight: "1", marginLeft: "20px" }}
            className={img1[1]}
          >
            <img
              src={img1[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">온라인쇼핑몰, 스마트스토어</a>
              <a className="Light1">의 상호명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                35류 / 온라인 판매를 하는 곳이라면 필수 선택! :)
              </a>
            </div>
          </div>
          <div
            id="2"
            onClick={() => onClick2()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img2[1]}
          >
            <img
              src={img2[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">음식점, 카페, 주점</a>
              <a className="Light1">의 가게 이름</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                43류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                식당, 커피숍, 베이커리 등 요식업 매장의 이름
              </a>
            </div>
          </div>
          <div
            id="3"
            onClick={() => onClick3()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img3[1]}
          >
            <img
              src={img3[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">헤어, 메이크업, 네일 등 뷰티샵</a>
              <a className="Light1">의 상호명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                44류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                미용실, 관리샵 등 뷰티와 관련된 샵의 이름
              </a>
            </div>
          </div>
          <div
            id="4"
            onClick={() => onClick4()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img4[1]}
          >
            <img
              src={img4[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">병원, 약국 등 건강진료 서비스</a>
              <a className="Light1">의 상호명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                44류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                병원, 약국, 상담 치료소 등의 이름
              </a>
            </div>
          </div>
          <div
            id="5"
            onClick={() => onClick5()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img5[1]}
          >
            <img
              src={img5[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">커피, 차, 빵, 과자 등</a>
              <a className="Light1">의 제품명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                30류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                커피, 차, 빵, 과자, 초콜릿, 아이스크림 등의 이름
              </a>
            </div>
          </div>
          <div
            id="6"
            onClick={() => onClick6()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img6[1]}
          >
            <img
              src={img6[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">애완동물용 사료 및 간식</a>
              <a className="Light1">의 제품명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                31류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                애완동물용 사료 및 간식의 이름
              </a>
            </div>
          </div>
          <div
            id="7"
            onClick={() => onClick7()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img7[1]}
          >
            <img
              src={img7[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">화장품, 세면용품, 향수 등</a>
              <a className="Light1">의 뷰티 제품명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                3류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                직접 브랜딩, 제조하는 뷰티 제품의 이름
              </a>
            </div>
          </div>
          <div
            id="8"
            onClick={() => onClick8()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img8[1]}
          >
            <img
              src={img8[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">약제 및 건강기능식품</a>
              <a className="Light1">의 제품명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                5류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                인증받은 약제, 건강기능식품의 이름
              </a>
            </div>
          </div>
          <div
            id="9"
            onClick={() => onClick9()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img9[1]}
          >
            <img
              src={img9[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">가공식품, 건강보조식품</a>
              <a className="Light1">의 제품명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                29류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                가공식품 및 건강보조식품의 이름
              </a>
            </div>
          </div>
          <div
            id="10"
            onClick={() => onClick10()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img10[1]}
          >
            <img
              src={img10[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">의류, 신발, 모자 등</a>
              <a className="Light1">의 제품명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                25류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                의류, 신발, 모자 등 각종 패션 제품의 이름
              </a>
            </div>
          </div>
          <div
            id="11"
            onClick={() => onClick11()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img11[1]}
          >
            <img
              src={img11[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">가방, 지갑, 가죽류, 애완동물 용품</a>
              <a className="Light1">의 제품명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                18류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                가방, 지갑, 가죽제품, 애완동물 용품 등의 이름
              </a>
            </div>
          </div>
          <div
            id="12"
            onClick={() => onClick12()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img12[1]}
          >
            <img
              src={img12[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">귀금속제 장신구</a>
              <a className="Light1">의 제품명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                14류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                반지, 귀걸이, 목걸이 등의 귀금속 액세서리의 이름
              </a>
            </div>
          </div>
          <div
            id="13"
            onClick={() => onClick13()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img13[1]}
          >
            <img
              src={img13[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">스포츠 용품</a>
              <a className="Light1">의 제품명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                28류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                운동, 스포츠에 필요한 각종 용품의 이름
              </a>
            </div>
          </div>

          <div
            id="14"
            onClick={() => onClick14()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img14[1]}
          >
            <img
              src={img14[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">공방, 학원 등 교육 관련 서비스</a>
              <a className="Light1">의 이름</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                41류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                00공방, 00학원 등 교육과 관련된 서비스의 이름
              </a>
            </div>
          </div>
          <div
            id="15"
            onClick={() => onClick15()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img15[1]}
          >
            <img
              src={img15[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">건축, 인테리어 업체</a>
              <a className="Light1">의 상호명</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                37류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                건축 사무소, 인테리어 업체의 이름
              </a>
            </div>
          </div>
          <div
            id="16"
            onClick={() => onClick16()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img16[1]}
          >
            <img
              src={img16[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">인플루언서, 크리에이터</a>
              <a className="Light1">의 채널 이름</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                38류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                유튜브, 인스타그램 등 SNS 계정 및 채널의 이름
              </a>
            </div>
          </div>

          <div
            id="17"
            onClick={() => onClick17()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img17[1]}
          >
            <img
              src={img17[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Bold1">앱, 웹 서비스</a>
              <a className="Light1">의 이름</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Bold1">
                9류 /{" "}
              </a>
              <a style={{ fontSize: "12px" }} className="Light1">
                앱 서비스, 온라인 웹 서비스의 이름
              </a>
            </div>
          </div>

          <div
            id="18"
            onClick={() => onClick18()}
            style={{
              fontSize: "16px",
              lineHeight: "1",
              marginTop: "10px",
              marginLeft: "20px",
            }}
            className={img18[1]}
          >
            <img
              src={img18[0]}
              style={{
                display: "inline-block",
                width: "35px",
                marginLeft: "12px",
                marginBottom: "-3px",
              }}
            />
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                lineHeight: "1.2",
              }}
            >
              <a className="Light1">여기에 없는</a>
              <a className="Bold1"> 특별한 분야</a>
              <br />
              <a style={{ fontSize: "12px" }} className="Light1">
                {" "}
                보기에 없는 분야는 결제 후 상담을 통해 진행하세요!
              </a>
            </div>
          </div>
          <div style={{ height: "150px" }}></div>
          <Link to="/success" className={eventcss} onClick={() => search991()}>
            <div
              id="next_button"
              className="noto"
              style={{
                cursor: "pointer",
                position: "fixed",
                bottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#efefef",
              }}
            >
              <div id="dam" style={{ color: "#b7b7b7" }}>
                {" "}
                {btntext}
              </div>
            </div>
          </Link>
        </div>
      </MobileView>
      <BrowserView>
        <div style={{ margin: "0 auto" }}>
          <BrowserHeader />
          <AlertHeaderPc1 />
          <div style={{ width: "1400px", paddingLeft: "calc(50% - 700px)" }}>
            <img
              src={ask3}
              style={{
                marginTop: "123px",
                marginLeft: "calc(50% - 602px)",
                width: "850px",
              }}
            />
            <div
              style={{
                width: "934px",
                marginLeft: "calc(50% - 467px)",
                marginTop: "36px",
                marginBottom: "200px",
              }}
            >
              <div
                id="1"
                onClick={() => onClick1()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img1[1]}
              >
                <img
                  src={img1[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">온라인쇼핑몰, 스마트스토어</a>
                  <a className="Light1">의 상호명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    35류 / 온라인 판매를 하는 곳이라면 필수 선택! :)
                  </a>
                </div>
              </div>
              <div
                id="2"
                onClick={() => onClick2()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img2[1]}
              >
                <img
                  src={img2[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">음식점, 카페, 주점</a>
                  <a className="Light1">의 가게 이름</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    43류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    식당, 커피숍, 베이커리 등 요식업 매장의 이름
                  </a>
                </div>
              </div>
              <div
                id="3"
                onClick={() => onClick3()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img3[1]}
              >
                <img
                  src={img3[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">헤어, 메이크업, 네일 등 뷰티샵</a>
                  <a className="Light1">의 상호명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    44류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    미용실, 관리샵 등 뷰티와 관련된 샵의 이름
                  </a>
                </div>
              </div>
              <div
                id="4"
                onClick={() => onClick4()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img4[1]}
              >
                <img
                  src={img4[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">병원, 약국 등 건강진료 서비스</a>
                  <a className="Light1">의 상호명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    44류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    병원, 약국, 상담 치료소 등의 이름
                  </a>
                </div>
              </div>
              <div
                id="5"
                onClick={() => onClick5()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img5[1]}
              >
                <img
                  src={img5[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">커피, 차, 빵, 과자 등</a>
                  <a className="Light1">의 제품명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    30류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    커피, 차, 빵, 과자, 초콜릿, 아이스크림 등의 이름
                  </a>
                </div>
              </div>
              <div
                id="6"
                onClick={() => onClick6()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img6[1]}
              >
                <img
                  src={img6[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">애완동물용 사료 및 간식</a>
                  <a className="Light1">의 제품명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    31류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    애완동물용 사료 및 간식의 이름
                  </a>
                </div>
              </div>
              <div
                id="7"
                onClick={() => onClick7()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img7[1]}
              >
                <img
                  src={img7[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">화장품, 세면용품, 향수 등</a>
                  <a className="Light1">의 뷰티 제품명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    3류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    직접 브랜딩, 제조하는 뷰티 제품의 이름
                  </a>
                </div>
              </div>
              <div
                id="8"
                onClick={() => onClick8()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img8[1]}
              >
                <img
                  src={img8[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">약제 및 건강기능식품</a>
                  <a className="Light1">의 제품명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    5류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    인증받은 약제, 건강기능식품의 이름
                  </a>
                </div>
              </div>
              <div
                id="9"
                onClick={() => onClick9()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img9[1]}
              >
                <img
                  src={img9[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">가공식품, 건강보조식품</a>
                  <a className="Light1">의 제품명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    29류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    가공식품 및 건강보조식품의 이름
                  </a>
                </div>
              </div>
              <div
                id="10"
                onClick={() => onClick10()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img10[1]}
              >
                <img
                  src={img10[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">의류, 신발, 모자 등</a>
                  <a className="Light1">의 제품명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    25류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    의류, 신발, 모자 등 각종 패션 제품의 이름
                  </a>
                </div>
              </div>
              <div
                id="11"
                onClick={() => onClick11()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  width: "450px",
                  display: "inline-block",
                  marginTop: "20px",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img11[1]}
              >
                <img
                  src={img11[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">가방, 지갑, 가죽류, 애완동물 용품</a>
                  <a className="Light1">의 제품명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    18류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    가방, 지갑, 가죽제품, 애완동물 용품 등의 이름
                  </a>
                </div>
              </div>
              <div
                id="12"
                onClick={() => onClick12()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img12[1]}
              >
                <img
                  src={img12[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">귀금속제 장신구</a>
                  <a className="Light1">의 제품명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    14류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    반지, 귀걸이, 목걸이 등의 귀금속 액세서리의 이름
                  </a>
                </div>
              </div>
              <div
                id="13"
                onClick={() => onClick13()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img13[1]}
              >
                <img
                  src={img13[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">스포츠 용품</a>
                  <a className="Light1">의 제품명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    28류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    운동, 스포츠에 필요한 각종 용품의 이름
                  </a>
                </div>
              </div>

              <div
                id="14"
                onClick={() => onClick14()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img14[1]}
              >
                <img
                  src={img14[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">공방, 학원 등 교육 관련 서비스</a>
                  <a className="Light1">의 이름</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    41류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    00공방, 00학원 등 교육과 관련된 서비스의 이름
                  </a>
                </div>
              </div>
              <div
                id="15"
                onClick={() => onClick15()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img15[1]}
              >
                <img
                  src={img15[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">건축, 인테리어 업체</a>
                  <a className="Light1">의 상호명</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    37류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    건축 사무소, 인테리어 업체의 이름
                  </a>
                </div>
              </div>
              <div
                id="16"
                onClick={() => onClick16()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img16[1]}
              >
                <img
                  src={img16[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">인플루언서, 크리에이터</a>
                  <a className="Light1">의 채널 이름</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    38류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    유튜브, 인스타그램 등 SNS 계정 및 채널의 이름
                  </a>
                </div>
              </div>

              <div
                id="17"
                onClick={() => onClick17()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img17[1]}
              >
                <img
                  src={img17[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Bold1">앱, 웹 서비스</a>
                  <a className="Light1">의 이름</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Bold1">
                    9류 /{" "}
                  </a>
                  <a style={{ fontSize: "14px" }} className="Light1">
                    앱 서비스, 온라인 웹 서비스의 이름
                  </a>
                </div>
              </div>

              <div
                id="18"
                onClick={() => onClick18()}
                style={{
                  fontSize: "18px",
                  lineHeight: "1.2",
                  marginTop: "20px",
                  width: "450px",
                  display: "inline-block",
                  marginRight: "15px",
                  paddingBottom: "18px",
                }}
                className={img18[1]}
              >
                <img
                  src={img18[0]}
                  style={{
                    display: "inline-block",
                    width: "35px",
                    marginLeft: "22px",
                  }}
                />
                <div style={{ display: "inline-block", marginLeft: "22px" }}>
                  <a className="Light1">여기에 없는</a>
                  <a className="Bold1"> 특별한 분야</a>
                  <br />
                  <a style={{ fontSize: "14px" }} className="Light1">
                    {" "}
                    보기에 없는 분야는 결제 후 상담을 통해 진행하세요!
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            id="introFooter"
            style={{
              float: "right",
              backgroundColor: "#f5f6ff",
              height: "116px",
              lineHeight: "110px",
            }}
          >
            <Link
              to="/success"
              className={eventcss}
              onClick={() => search991()}
            >
              <img
                src={nextbtn}
                style={{
                  cursor: "pointer",
                  width: "200px",
                  marginLeft: "20px",
                  marginTop: "27px",
                  float: "right",
                  marginRight: "calc(50% - 488px)",
                }}
              />
            </Link>
            <a href="http://pf.kakao.com/_lWuhs/chat">
              <img
                src={kakaoaa}
                style={{
                  cursor: "pointer",
                  width: "283px ",
                  marginTop: "27px",
                  float: "right",
                }}
              />
            </a>
            <div
              className="Bold1"
              style={{ fontSize: "30px", marginLeft: "calc(50% - 485px)" }}
            >
              <a>
                총 <a style={{ color: "#4a64f5" }}>{btntext2}</a>개 카테고리
                선택 완료
              </a>
            </div>
          </div>
        </div>
      </BrowserView>
    </div>
  );
}

export default BrandCategoryRegister;
