import React, { useState, useEffect } from "react";
import TopbarComponentWithoutPage from "../components/Topbarwithoutpage";
import Nextbutton from "../components/Nextbutton";
import "../style.css";
import x_round from "../image/x_round@3x.png";
import couponbtn3 from "../image/couponbtn3.png";
import ask111 from "../image/zxzxxz.png";
import GuidelineComponent2 from "../components/Guideline2";
import AlertHeader from "../components/AlertHeader";
import AlertHeader2 from "../components/AlertHeader2";
import new_blue from "../image/new_blue.png";
import kakaoaa from "../image/kakaoaa.png";
import cocencle from "../image/cocencle.png";
import cobtn from "../image/cobtn.png";
import $ from "jquery";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  IsMobile,
  TabletView,
} from "react-device-detect";
import BrowserHeader from "../components/BrowserHeader";
function addDay(date) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1
  );

  var year = addMonthFirstDate.getFullYear();
  var month = new String(addMonthFirstDate.getMonth() + 1);
  var day = new String(addMonthFirstDate.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return year + "-" + month + "-" + day;
}
function addMonth(date, month) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth() + month,
    date.getDate()
  );

  // month달 후의 말일
  let addMonthLastDate = new Date(
    addMonthFirstDate.getFullYear(),
    addMonthFirstDate.getMonth() + month,
    0
  );

  let result = addMonthFirstDate;
  if (date.getDate() > addMonthLastDate.getDate()) {
    result.setDate(addMonthLastDate.getDate());
  } else {
    result.setDate(date.getDate());
  }

  var year = result.getFullYear();
  var month = new String(result.getMonth() + 1);
  var day = new String(result.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return year + "-" + month + "월 예상";
}
function addMonth2(date, month) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth() + month,
    date.getDate()
  );

  // month달 후의 말일
  let addMonthLastDate = new Date(
    addMonthFirstDate.getFullYear(),
    addMonthFirstDate.getMonth() + month,
    0
  );

  let result = addMonthFirstDate;
  if (date.getDate() > addMonthLastDate.getDate()) {
    result.setDate(addMonthLastDate.getDate());
  } else {
    result.setDate(date.getDate());
  }

  var year = result.getFullYear();
  var month = new String(result.getMonth() + 1);
  var day = new String(result.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return "심사 단축 시 " + year + "-" + month + "월 예상";
}
function patentPrice(plus) {
  let firstgoodsList = sessionStorage.getItem("firstgoods");
  let firstgoodsListParse = JSON.parse(firstgoodsList);
  let firstCount = 0;
  for (let i = 0; i < firstgoodsListParse.length; i++) {
    if (firstgoodsListParse[i] == plus) {
      firstCount++;
    }
  }
  if (firstCount > 0) {
    return "212,000원";
  } else {
    return "52,000원";
  }
}
function patentPrice2(plus) {
  let firstgoodsList = sessionStorage.getItem("firstgoods");
  let firstgoodsListParse = JSON.parse(firstgoodsList);
  let firstCount = 0;
  for (let i = 0; i < firstgoodsListParse.length; i++) {
    if (firstgoodsListParse[i] == plus) {
      firstCount++;
    }
  }
  if (firstCount > 0) {
    return "310,000원";
  } else {
    return "110,000원";
  }
}
function patentMonth(plus) {
  let firstgoodsList = sessionStorage.getItem("firstgoods");
  let firstgoodsListParse = JSON.parse(firstgoodsList);
  let firstCount = 0;
  for (let i = 0; i < firstgoodsListParse.length; i++) {
    if (firstgoodsListParse[i] == plus) {
      firstCount++;
    }
  }
  if (firstCount > 0) {
    return addMonth(janLastDate, 3);
  } else {
    return addMonth(janLastDate, 15);
  }
}
function patentSelect(plus) {
  let firstgoodsList = sessionStorage.getItem("firstgoods");
  let firstgoodsListParse = JSON.parse(firstgoodsList);
  let firstCount = 0;
  for (let i = 0; i < firstgoodsListParse.length; i++) {
    if (firstgoodsListParse[i] == plus) {
      firstCount++;
    }
  }
  if (firstCount > 0) {
    return "선택함";
  } else {
    return "선택하지 않음";
  }
}
var goodsList = [];
let janLastDate = new Date();
var tomorow = addDay(janLastDate);
var month12 = addMonth(janLastDate, 15);
var month5 = addMonth(janLastDate, 3);
var month52 = addMonth2(janLastDate, 3);

function Successmo(props) {
  const animals = props.skarb;
  const animalList = animals.map((animal) => (
    <div
      key={animal.id}
      style={{
        borderBottom: "2px solid #8989894a",
        marginLeft: "5%",
        marginRight: "5%",
        marginBottom: "20px",
      }}
    >
      <div
        id=""
        className="Regular"
        style={{ fontSize: "18px", color: "#4a64f5" }}
      >
        {" "}
        제품/서비스{" "}
      </div>

      <div
        className="Medium"
        style={{
          marginTop: "4px",
          fontSize: "22px",
          color: "#000000",
          display: "inline-block",
        }}
      >
        {animal.lastgoods.split("+")[0]}
      </div>

      <div style={{ marginTop: "5%", fontSize: "14px" }}>
        <div style={{ display: "block" }}>
          <div
            className="Regular"
            style={{
              color: "#515151",
              fontSize: "19px",
              opacity: "0.7",
              display: "inline-block",
              float: "left",
            }}
          >
            특허청 관납료
          </div>

          <div
            className="1st Regular"
            style={{
              color: "#515151",
              display: "inline-block",
              float: "right",
              fontSize: "19px",
            }}
          >
            {patentPrice(animal.lastgoods[0])}
          </div>
          <div
            id={animal.lastgoods[0] + "a"}
            style={{ display: "none", lineHeight: "1" }}
          >
            <div>
              <br />
              <br />
            </div>
            <div
              className="Regular"
              style={{ color: "#515151", float: "right", fontSize: "19px" }}
            >
              {patentPrice2(animal.lastgoods[0])}
            </div>
          </div>
        </div>
        <br />

        <div>
          <div style={{ marginTop: "10px" }}>
            <div
              className=" Regular"
              style={{
                fontSize: "19px",
                marginTop: "7px",
                float: "left",
                color: "#515151",
                opacity: "0.7",
              }}
            >
              네임텍 수수료
            </div>

            <div
              className="2nd Regular"
              style={{
                opacity: "1",
                color: "#515151",
                display: "inline-block",
                float: "right",
                fontSize: "19px",
                marginTop: "7px",
              }}
            >
              {patentPrice2(animal.lastgoods[0])}
            </div>
          </div>

          <div
            id={animal.lastgoods[0] + "b"}
            style={{ display: "none", lineHeight: "0.867" }}
          >
            <div>
              <br />
              <br />
              <br />
            </div>
            <div
              className="Regular"
              style={{ color: "#4a64f5", float: "right", fontSize: "19px" }}
            >
              +200,000원
            </div>
          </div>
        </div>

        <div
          className="in2"
          style={{
            margin: "0px 17px 10px 0px",
            color: "#515151",
            display: "inline-block",
            width: "100%",
            paddingBottom: "10px",
            fontSize: "14px",
          }}
        >
          <div
            className="sucin2"
            style={{ color: "#515151", marginTop: "10px" }}
          >
            <p
              className="Regular"
              style={{ display: "inline", opacity: " 0.7", fontSize: "19px" }}
            >
              상표 출원 예상일
            </p>
            <span
              className=" Regular"
              style={{ fontSize: "19px", color: "#515151", float: "right" }}
            >
              {tomorow}
            </span>
          </div>

          <div
            id={animal.lastgoods[0] + "c"}
            className="sucin2"
            style={{ color: "#515151", marginTop: "10px" }}
          >
            <p
              className="Regular"
              style={{ display: "inline", opacity: "0.7", fontSize: "19px" }}
            >
              심사 결과 통보 예상일{" "}
            </p>
            <span
              className=" Regular"
              style={{ color: "#515151", fontSize: "19px", float: "right" }}
            >
              {patentMonth(animal.lastgoods[0])}
            </span>
          </div>
          <div
            id={animal.lastgoods[0] + "d"}
            className="sucin2"
            style={{ color: "#515151", marginTop: "10px" }}
          >
            <p
              className="Regular"
              style={{ display: "inline", opacity: "0.7", fontSize: "19px" }}
            >
              심사기간 단축 옵션{" "}
            </p>
            <span
              className=" Regular"
              style={{ color: "#515151", fontSize: "19px", float: "right" }}
            >
              {patentSelect(animal.lastgoods[0])}
            </span>
          </div>
        </div>
      </div>
    </div>
  ));

  return <div>{animalList}</div>;
}

function Successmo2(props) {
  const animals = props.skarb;
  const animalList = animals.map((animal) => (
    <div
      key={animal.id}
      style={{
        borderBottom: "1px solid #898989",
        marginLeft: "5%",
        marginRight: "5%",
        marginBottom: "20px",
      }}
    >
      <div id="" style={{ fontSize: "12px", color: "#4a64f5" }}>
        {" "}
        제품/서비스{" "}
      </div>
      <div
        className="Medium"
        style={{ fontSize: "16px", color: "#000000", display: "inline-block" }}
      >
        {animal.lastgoods.split("+")[0]}
      </div>

      <div style={{ marginTop: "5%", fontSize: "14px" }}>
        <div style={{ display: "block" }}>
          <div
            style={{
              color: "#898989",
              opacity: "0.7",
              display: "inline-block",
              float: "left",
            }}
          >
            특허청 관납료
          </div>

          <div
            className="1st"
            style={{
              color: "#515151",
              display: "inline-block",
              float: "right",
            }}
          >
            {patentPrice(animal.lastgoods[0])}
          </div>

          <div id={animal.lastgoods[0] + "a"} style={{ display: "none" }}>
            <div>
              <br />
            </div>
            <div id="divplus10" style={{ color: "#4a64f5", float: "right" }}>
              +160,000원{" "}
            </div>
          </div>
        </div>
        <br />

        <div>
          <div className="l2">네임텍 수수료</div>
          <div
            className="2nd"
            style={{
              opacity: "1",
              color: "#515151",
              display: "inline-block",
              float: "right",
            }}
          >
            {patentPrice2(animal.lastgoods[0])}
          </div>
          <div id={animal.lastgoods[0] + "b"} style={{ display: "none" }}>
            <div>
              <br />
            </div>
            <div id="divplus10" style={{ color: "#4a64f5", float: "right" }}>
              +200,000원{" "}
            </div>
          </div>
        </div>

        <div
          className="in2"
          style={{
            margin: "0px 17px 10px 0px",
            color: "#898989",
            display: "inline-block",
            width: "100%",
            paddingBottom: "10px",
            fontSize: "14px",
          }}
        >
          <div className="sucin2" style={{ color: "#898989" }}>
            <p style={{ display: "inline", opacity: " 0.7" }}>
              상표 출원 예상일
            </p>
            <span
              className="Regular"
              style={{ color: "#515151", float: "right" }}
            >
              {tomorow}
            </span>
          </div>

          <div
            id={animal.lastgoods[0] + "c"}
            className="sucin2"
            style={{ color: "#898989" }}
          >
            <p style={{ display: "inline", opacity: "0.7" }}>
              심사 결과 통보 예상일
            </p>
            <span
              className="Regular"
              style={{ color: "#515151", float: "right" }}
            >
              {patentMonth(animal.lastgoods[0])}
            </span>
          </div>
          <div
            id={animal.lastgoods[0] + "c"}
            className="sucin2"
            style={{ color: "#898989" }}
          >
            <p style={{ display: "inline", opacity: "0.7" }}>
              심사기간 단축 옵션
            </p>
            <span
              className="Regular"
              style={{ color: "#515151", float: "right" }}
            >
              {patentSelect(animal.lastgoods[0])}
            </span>
          </div>
        </div>
      </div>
    </div>
  ));

  return <div>{animalList}</div>;
}
function Test7() {
  function formatKoreanMobilePhoneNumber(number) {
    // 휴대전화 형식: 세 자리(010), 그 다음 세 자리, 마지막 네 자리
    var regex = /(\d{3})(\d{3,4})(\d{4})/;
    return number.replace(regex, "$1-$2-$3");
  }
  let firstgoodsk = JSON.parse(sessionStorage.getItem("firstgoods")).length;
  const [prcount, setPrcount] = useState(0);
  const [comment, setComment] = useState("");
  const [text, setText] = useState("");
  const [coevent, setCoevent] = useState("");
  const [cobtn1, setCobtn] = useState(couponbtn3);
  const [btn, setBtn] = useState(0);
  const [style1, setStyle1] = useState();
  const valuelist = JSON.parse(sessionStorage.getItem("valuelist3"));
  let brandname = sessionStorage.getItem("brandname");
  let customeremail = localStorage.getItem("real_email");
  let customerPhone1 = localStorage.getItem("customerPhone");
  let customerPhone = formatKoreanMobilePhoneNumber(customerPhone1);
  const [valuelist2, setValuelist2] = useState([]);
  const [valuelistz, setValuelistz] = useState([]);
  var aspr = sessionStorage.getItem("nprice");
  let skarbads = parseInt(aspr, 10).toLocaleString();
  const [price, setPrice] = useState(skarbads + "원");
  const [price2, setPrice2] = useState(skarbads);
  const [price3, setPrice3] = useState(skarbads + "원");
  const [price4, setPrice4] = useState(skarbads);
  const [discount, setDiscount] = useState(0);
  let person = "";
  let product = "";
  let percom = "";
  const [sucproduct2, setSucproduct2] = useState("로고 없음");
  const [product3, setProduct3] = useState("로고 없음");
  const onChange = (e) => {
    setText(e.target.value);
  };
  useEffect(() => {
    for (let i = 0; i < valuelist.length; i++) {
      valuelist[i]["valuelist2"] = "checkgray";
      valuelist[i]["month"] = month12;
    }
    setValuelistz(valuelist);
    sessionStorage.setItem("price", skarbads);
  }, []);

  useEffect(() => {
    let imgToken = sessionStorage.getItem("token");
    $.ajax({
      type: "POST",
      url: "https://nametecadmin.kr/getfolderdata1",
      data: JSON.stringify({
        token: imgToken,
      }),
      processData: false,
      contentType: "application/json",
      async: false,
      success: function (data) {
        if (data.status == 200) {
          product = data.data;
          $("#disp").css("display", "");
          sessionStorage.setItem("fast", "N");
          if (product == null) {
          } else {
            setProduct3(
              <img
                style={{ height: "100px", width: "140px" }}
                src={"https://nametecadmin.kr/static/data/" + product}
              />
            );
            setSucproduct2(
              <img
                style={{ height: "74px", width: "122px" }}
                src={"https://nametecadmin.kr/static/data/" + product}
              />
            );
          }
        } else {
        }
      },
      err: function (err) {
        alert(err.status);
      },
    });
  }, []);
  const applyCoupon = () => {
    sessionStorage.removeItem("coupon");
    let fast = sessionStorage.getItem("fast");
    if (fast == "Y") {
      setText("");
      setComment("");
      sessionStorage.setItem("fast", "N");
      const numbern = price2.replace(/,/g, "");
      const number3n = Number(numbern);
      let number4n = 0;
      number4n = number3n + 33000 * firstgoodsk;
      let skarbadsaas = number4n.toLocaleString();
      setPrice3(skarbadsaas + "원");
      setPrice4(skarbadsaas);
      setDiscount(0);
      $("#discountAmount").css("border-bottom", "solid 2px #b7b7b7");
      setCobtn(couponbtn3);
    } else {
      $.ajax({
        type: "POST",
        url: "https://nametecadmin.kr/checkcoupon",
        data: JSON.stringify({
          coupon_number: text,
        }),
        processData: false,
        contentType: "application/json",
        async: false,
        success: function (data) {
          if (data.status == 200) {
            let askrb = data.data[0].coupon_name;
            if (
              askrb.indexOf("tiny33000") != -1 ||
              askrb.indexOf("ground") != -1
            ) {
              sessionStorage.setItem("fast", "Y");
              let skakalal = 0;
              skakalal = 33000 * firstgoodsk;
              setDiscount(skakalal.toLocaleString());
              const numbern3 = price2.replace(/,/g, "");
              const number3an = Number(numbern3);
              let number4ns = 0;
              number4ns = number3an - skakalal;
              let skarbadsasas = number4ns.toLocaleString();
              setPrice3(skarbadsasas + "원");
              setPrice4(skarbadsasas);
            }
            let firstgoods = sessionStorage.getItem("firstgoods");
            if (firstgoods == "[]" || firstgoods == "") {
              setComment("심사기간 단축 선택시 적용되는 쿠폰입니다.");
              setCobtn(cocencle);
              $("#couponComment").css("color", "#4a64f5");
              sessionStorage.setItem("coupon", askrb);
            } else {
              setComment("쿠폰이 적용되었습니다.");
              setCobtn(cocencle);
              $("#couponComment").css("color", "#898989");
              sessionStorage.setItem("coupon", askrb);
            }
          } else {
            setCobtn(cobtn);
            setComment("쿠폰이 유효하지 않습니다.");
            $("#couponComment").css("color", "#4a64f5");
          }
        },
      });
    }
  };
  const onKeyUP = (event) => {
    if (event.target.value.length > 0) {
      $("#discountAmount").css("border-bottom", "solid 2px #4a64f5");
      setCobtn(cobtn);
    } else {
      setCobtn(couponbtn3);
    }
  };
  const onReset = () => {
    setText("");
    setComment("");
    let coupons = sessionStorage.getItem("coupon");
    if (coupons == null) {
    } else {
      const numbern = price2.replace(/,/g, "");
      const number3n = Number(numbern);
      let number4n = 0;
      number4n = number3n + 33000 * firstgoodsk;
      let skarbadsaas = number4n.toLocaleString();
      setPrice3(skarbadsaas + "원");
      setPrice4(skarbadsaas);
      setDiscount(0);
    }

    sessionStorage.removeItem("coupon");
    sessionStorage.setItem("fast", "N");
    $("#discountAmount").css("border-bottom", "solid 2px #b7b7b7");
    setCobtn(couponbtn3);
  };
  function getIndex(listS, valuelist) {
    return listS.findIndex((obj) => obj.valuelist === valuelist);
  }
  const [renderFlag, setRenderFlag] = useState(true);
  const setfirst = (props, successList) => {
    if (successList.valuelist2 === "checkgray") {
      successList.valuelist2 = "checkblue";
      goodsList.push(successList.lastgoods[0]);
      $("#" + successList.lastgoods[0] + "a").css("display", "");
      $("#" + successList.lastgoods[0] + "b").css("display", "");
      $("#" + successList.lastgoods[0] + "c").css("display", "none");
      $("#" + successList.lastgoods[0] + "d").css("display", "");
      $("#" + successList.lastgoods[0] + "e").css("display", "none");
      $("#" + successList.lastgoods[0] + "f").css("display", "");
      const number = price2.replace(/,/g, "");
      const number3 = Number(number);
      let number4 = 0;
      let fast = sessionStorage.getItem("fast");
      let anana = prcount;
      setPrcount((c) => c + 1);
      if (fast == "Y") {
        number4 = 327000 + number3;
        let skakalal = 0;
        skakalal = 33000 * (anana + 1);
        setDiscount(skakalal.toLocaleString());
      } else {
        number4 = 360000 + number3;
      }
      let skarbadsa = number4.toLocaleString();
      setPrice3(skarbadsa + "원");
      setPrice4(skarbadsa);
    } else {
      for (var i = 0; i < goodsList.length; i++) {
        if (goodsList[i] === successList.lastgoods[0]) {
          goodsList.splice(i, 1);
          i--;
        }
      }
      successList.valuelist2 = "checkgray";
      successList.month = month12;
      $("#" + successList.lastgoods[0] + "a").css("display", "none");
      $("#" + successList.lastgoods[0] + "b").css("display", "none");
      $("#" + successList.lastgoods[0] + "c").css("display", "");
      $("#" + successList.lastgoods[0] + "d").css("display", "none");
      $("#" + successList.lastgoods[0] + "e").css("display", "");
      $("#" + successList.lastgoods[0] + "f").css("display", "none");
      const number2 = price2.replace(/,/g, "");
      const number5 = Number(number2);
      let number6 = 0;
      let fast2 = sessionStorage.getItem("fast");
      let anana = prcount;
      setPrcount((c) => c - 1);
      if (fast2 == "Y") {
        let anana = prcount;
        number6 = number5 - 327000;
        let skakalal = 0;
        skakalal = 33000 * (anana - 1);
        setDiscount(skakalal.toLocaleString());
      } else {
        number6 = number5 - 360000;
      }
      let skarbadsa1 = number6.toLocaleString();
      setPrice3(skarbadsa1 + "원");
      setPrice4(skarbadsa1);
    }
    setRenderFlag(!renderFlag);
  };
  useEffect(() => {
    setPrice(price3);
    setPrice2(price4);
    sessionStorage.setItem("price", price3);
  }, [renderFlag, price3]);

  return (
    <div>
      <TabletView>
        <div
          style={{
            margin: "auto",
            maxWidth: "520px",
            fontFamily: "'Noto Sans KR', sans-serif",
          }}
        >
          <TopbarComponentWithoutPage path={"/infomcheck"} />
          <div style={{ height: "5px" }}></div>
          <div style={{ width: "342px", margin: "auto" }}>
            <GuidelineComponent2
              title1={"변리사에게 전달할"}
              title2={"내용을 확인하세요."}
              help={"접수 이후에도 변리사의 상담을 통해"}
              help2={"내용을 변경할 수 있습니다."}
            />
          </div>
          <div>
            <div
              style={{
                marginRight: "calc(5% + 102px)",
                float: "right",
                color: "#4a64f5",
                fontSize: "12px",
              }}
            >
              로고
            </div>
            <div
              style={{
                marginLeft: "5%",
                color: "#4a64f5",
                fontSize: "12px",
                marginTop: "25px",
              }}
            >
              상표명
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                border: "1px solid rgb(204, 204, 204)",
                width: "122px",
                height: "74px",
                marginRight: "5%",
                float: "right",
                color: "rgb(137, 137, 137)",
              }}
            >
              {sucproduct2}
            </div>
            <div
              className="Medium"
              id="brandNamea"
              style={{
                marginLeft: "5%",
                color: "#000000",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              {brandname}
            </div>

            <div
              style={{ marginLeft: "5%", color: "#4a64f5", fontSize: "12px" }}
            >
              전화번호
            </div>
            <div
              className="Medium"
              id="lastCheckPhonenumber"
              style={{
                marginLeft: "5%",
                color: "#000000",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              {customerPhone}
            </div>
            <div
              style={{ marginLeft: "5%", color: "#4a64f5", fontSize: "12px" }}
            >
              이메일
            </div>
            <div
              className="Medium"
              id="lastCheckEmail"
              style={{
                marginBottom: "10px",
                marginLeft: "5%",
                color: "#000000",
                fontSize: "16px",
                borderBottom: "2px dashed #8989894a",
                marginRight: "5%",
                paddingBottom: "18px",
              }}
            >
              {customeremail}
            </div>

            <Successmo2 skarb={valuelistz} setfirst={setfirst} />

            <div
              className=""
              id="textconcheck"
              style={{
                width: "calc(100% - 40px)",
                paddingLeft: "3px",
                margin: "24px 0 0 20px",
              }}
            >
              <div
                style={{
                  color: "#4a64f5",
                  fontSize: "12px",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                할인 내역
              </div>
              <div style={{ height: "30px" }}>
                <div
                  style={{
                    color: "#898989",
                    opacity: "0.7",
                    display: "inline-block",
                    float: "left",
                  }}
                >
                  쿠폰 할인금액
                </div>
                <div
                  id="discountAmount1"
                  style={{
                    color: "#515151",
                    display: "inline-block",
                    float: "right",
                    marginRight: "13px",
                  }}
                >
                  -{discount}원
                </div>
              </div>
              <div style={{ display: "block" }}>
                <input
                  autoComplete="off"
                  type="text"
                  name=""
                  id="discountAmount"
                  onChange={onChange}
                  value={text}
                  onKeyUp={onKeyUP}
                  className="informchecktext Medium"
                  placeholder="할인쿠폰 코드입력"
                  style={{
                    border: "none",
                    color: "#000000",
                    borderBottom: "solid 2px #e0e0e0",
                    marginTop: "30px",
                    opacity: "0.7",
                  }}
                />

                <img
                  id="cobtn13"
                  src={cobtn1}
                  onClick={applyCoupon}
                  style={{ height: "40px", marginTop: "30px", float: "right" }}
                />
              </div>
              <div
                id="couponComment"
                style={{
                  fontSize: "12px",
                  color: "#4a64f5",
                  marginBottom: "20px",
                }}
              >
                <a>{comment}</a>
              </div>
            </div>
            <div className="res" style={{ maxWidth: "520px" }} id="2222">
              <div
                style={{
                  paddingBottom: "30px",
                  borderBottom: "solid 2px #efefef",
                  paddingTop: "24px",
                }}
              >
                <div
                  id="mas"
                  style={{
                    display: "inline",
                    float: "left",
                    fontSize: "20px",
                    fontWeight: "500",
                    marginLeft: "5%",
                    transform: "translateY(-15px)",
                  }}
                >
                  총계
                </div>
                <div
                  id="res"
                  style={{
                    display: "inline",
                    fontWeight: "500",
                    float: "right",
                    fontSize: "20px",
                    marginRight: "5%",
                    transform: "translateY(-15px)",
                  }}
                >
                  {price}
                </div>
              </div>
            </div>
            <div style={{ height: "150px" }}></div>
            <div
              style={{
                backgroundColor: "#ffffff",
                textAlign: "center",
                width: "100%",
                boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.14)",
                position: "fixed",
                bottom: " 0px",
                maxWidth: "520px",
              }}
              id="1111"
            ></div>
          </div>

          <Link to="/test7">
            <div
              id="next_button"
              className="noto"
              style={{
                cursor: "pointer",
                position: "fixed",
                bottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#4a64f5",
              }}
            >
              <div id="dam" style={{ color: "#ffffff" }}>
                {" "}
                출원하기
              </div>
            </div>
          </Link>
        </div>
      </TabletView>
      <MobileView>
        <div
          style={{
            margin: "auto",
            maxWidth: "520px",
            fontFamily: "'Noto Sans KR', sans-serif",
          }}
        >
          <TopbarComponentWithoutPage path={"/infomcheck"} />
          <div style={{ height: "5px" }}></div>
          <div style={{ width: "342px", margin: "auto" }}>
            <GuidelineComponent2
              title1={"변리사에게 전달할"}
              title2={"내용을 확인하세요."}
              help={"접수 이후에도 변리사의 상담을 통해"}
              help2={"내용을 변경할 수 있습니다."}
            />
          </div>
          <div>
            <div
              style={{
                marginRight: "calc(5% + 102px)",
                float: "right",
                color: "#4a64f5",
                fontSize: "12px",
              }}
            >
              로고
            </div>
            <div
              style={{
                marginLeft: "5%",
                color: "#4a64f5",
                fontSize: "12px",
                marginTop: "25px",
              }}
            >
              상표명
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                border: "1px solid rgb(204, 204, 204)",
                width: "122px",
                height: "74px",
                marginRight: "5%",
                float: "right",
                color: "rgb(137, 137, 137)",
              }}
            >
              {sucproduct2}
            </div>
            <div
              className="Medium"
              id="brandNamea"
              style={{
                marginLeft: "5%",
                color: "#000000",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              {brandname}
            </div>

            <div
              style={{ marginLeft: "5%", color: "#4a64f5", fontSize: "12px" }}
            >
              전화번호
            </div>
            <div
              className="Medium"
              id="lastCheckPhonenumber"
              style={{
                marginLeft: "5%",
                color: "#000000",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              {customerPhone}
            </div>
            <div
              style={{ marginLeft: "5%", color: "#4a64f5", fontSize: "12px" }}
            >
              이메일
            </div>
            <div
              className="Medium"
              id="lastCheckEmail"
              style={{
                marginBottom: "10px",
                marginLeft: "5%",
                color: "#000000",
                fontSize: "16px",
                borderBottom: "1px solid #898989",
                marginRight: "5%",
                paddingBottom: "18px",
              }}
            >
              {customeremail}
            </div>

            <Successmo2 skarb={valuelistz} setfirst={setfirst} />

            <div
              className=""
              id="textconcheck"
              style={{
                width: "calc(100% - 40px)",
                paddingLeft: "3px",
                margin: "24px 0 0 20px",
              }}
            >
              <div
                style={{
                  color: "#4a64f5",
                  fontSize: "12px",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                할인 내역
              </div>
              <div style={{ height: "30px" }}>
                <div
                  style={{
                    color: "#898989",
                    opacity: "0.7",
                    display: "inline-block",
                    float: "left",
                  }}
                >
                  쿠폰 할인금액
                </div>
                <div
                  id="discountAmount1"
                  style={{
                    color: "#515151",
                    display: "inline-block",
                    float: "right",
                    marginRight: "13px",
                  }}
                >
                  -{discount}원
                </div>
              </div>
              <div style={{ display: "block" }}>
                <input
                  autoComplete="off"
                  type="text"
                  name=""
                  id="discountAmount"
                  onChange={onChange}
                  value={text}
                  onKeyUp={onKeyUP}
                  className="informchecktext Medium"
                  placeholder="할인쿠폰 코드입력"
                  style={{
                    border: "none",
                    color: "#000000",
                    borderBottom: "solid 2px #e0e0e0",
                    marginTop: "30px",
                    opacity: "0.7",
                  }}
                />

                <img
                  id="cobtn13"
                  src={cobtn1}
                  onClick={applyCoupon}
                  style={{ height: "40px", marginTop: "30px", float: "right" }}
                />
              </div>
              <div
                id="couponComment"
                style={{
                  fontSize: "12px",
                  color: "#4a64f5",
                  marginBottom: "20px",
                }}
              >
                <a>{comment}</a>
              </div>
            </div>
            <div className="res" style={{ maxWidth: "520px" }} id="2222">
              <div
                style={{
                  paddingBottom: "30px",
                  borderBottom: "solid 2px #efefef",
                  paddingTop: "24px",
                }}
              >
                <div
                  id="mas"
                  style={{
                    display: "inline",
                    float: "left",
                    fontSize: "20px",
                    fontWeight: "500",
                    marginLeft: "5%",
                    transform: "translateY(-15px)",
                  }}
                >
                  총계
                </div>
                <div
                  id="res"
                  style={{
                    display: "inline",
                    fontWeight: "500",
                    float: "right",
                    fontSize: "20px",
                    marginRight: "5%",
                    transform: "translateY(-15px)",
                  }}
                >
                  {price}
                </div>
              </div>
            </div>
            <div style={{ height: "150px" }}></div>
            <div
              style={{
                backgroundColor: "#ffffff",
                textAlign: "center",
                width: "100%",
                boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.14)",
                position: "fixed",
                bottom: " 0px",
                maxWidth: "520px",
              }}
              id="1111"
            ></div>
          </div>

          <Link to="/test8">
            <div
              id="next_button"
              className="noto"
              style={{
                cursor: "pointer",
                position: "fixed",
                bottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#4a64f5",
              }}
            >
              <div id="dam" style={{ color: "#ffffff" }}>
                {" "}
                출원하기
              </div>
            </div>
          </Link>
        </div>
      </MobileView>
      <BrowserView>
        <div style={{ margin: "0 auto" }}>
          <BrowserHeader />
          <div
            id="disp"
            style={{
              width: "1400px",
              display: "none",
              paddingLeft: "calc(50% - 700px)",
            }}
          >
            <img
              src={ask111}
              style={{
                marginTop: "123px",
                marginLeft: "calc(50% - 602px)",
                width: "690px",
              }}
            />
            <div
              id="askBox33"
              className="askBox33"
              style={{
                border: "1px solid #e0e0e0",
                width: "940px",
                marginLeft: "calc(50% - 492px)",
                marginTop: "50px",
                borderRadius: "10px",
                boxShadow: "1px 1px 4px 0 rgba(0, 0, 0, 0.16)",
                paddingLeft: "50px",
                paddingTop: "50px",
                paddingBottom: "40px",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  width: "300px",
                  position: "absolute",
                }}
              >
                <div
                  className="Regular"
                  style={{
                    marginLeft: "5%",
                    color: "#4a64f5",
                    fontSize: "18px",
                  }}
                >
                  상표명
                </div>

                <div
                  className="Medium"
                  id="brandNamea"
                  style={{
                    marginLeft: "5%",
                    color: "#000000",
                    fontSize: "22px",
                    marginBottom: "10px",
                  }}
                >
                  {brandname}
                </div>

                <div
                  className="Regular"
                  style={{
                    marginTop: "12px",
                    marginLeft: "5%",
                    color: "#4a64f5",
                    fontSize: "18px",
                  }}
                >
                  전화번호
                </div>
                <div
                  className="Medium"
                  id="lastCheckPhonenumber"
                  style={{
                    marginLeft: "5%",
                    color: "#000000",
                    fontSize: "22px",
                    marginBottom: "10px",
                  }}
                >
                  {customerPhone}
                </div>
                <div
                  className="Regular"
                  style={{
                    marginTop: "12px",
                    marginLeft: "5%",
                    color: "#4a64f5",
                    fontSize: "18px",
                  }}
                >
                  이메일
                </div>
                <div
                  className="Medium"
                  id="lastCheckEmail"
                  style={{
                    marginBottom: "10px",
                    marginLeft: "5%",
                    color: "#000000",
                    fontSize: "22px",
                    paddingBottom: "18px",
                  }}
                >
                  {customeremail}
                </div>
                <div
                  className="Regular"
                  style={{
                    marginTop: "12px",
                    marginLeft: "5%",
                    color: "#4a64f5",
                    fontSize: "18px",
                  }}
                >
                  로고
                </div>
                <div
                  className="Medium"
                  style={{
                    textAlign: "center",
                    marginTop: "5px",
                    border: "1px solid rgb(204, 204, 204)",
                    width: "140px",
                    height: "140px",
                    marginLeft: "5%",
                    color: "rgb(137, 137, 137)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {product3}
                </div>
              </div>

              <div
                style={{
                  display: "inline-block",
                  marginLeft: "300px",
                  width: "600px",
                }}
              >
                <Successmo skarb={valuelistz} setfirst={setfirst} />

                <div
                  className=""
                  id="textconcheck"
                  style={{
                    width: "calc(100% - 40px)",
                    paddingLeft: "3px",
                    margin: "14px 0 0 5%",
                  }}
                >
                  <div
                    className="Regular"
                    style={{
                      color: "#4a64f5",
                      fontSize: "19px",
                      marginTop: "20px",
                      marginBottom: "10px",
                    }}
                  >
                    할인 내역
                  </div>
                  <div style={{ height: "30px" }}>
                    <div
                      className="Medium"
                      style={{
                        fontSize: "22px",
                        display: "inline-block",
                        float: "left",
                      }}
                    >
                      쿠폰 할인금액
                    </div>
                    <div
                      id="discountAmount1"
                      className="Regular"
                      style={{
                        color: "#515151",
                        display: "inline-block",
                        float: "right",
                        marginRight: "5%",
                        fontSize: "19px",
                      }}
                    >
                      -{discount}원
                    </div>
                  </div>
                  <div style={{ display: "block" }}>
                    <input
                      autoComplete="off"
                      type="text"
                      name=""
                      id="discountAmount"
                      onChange={onChange}
                      value={text}
                      onKeyUp={onKeyUP}
                      className="informchecktext2 Medium"
                      placeholder="할인쿠폰 코드입력"
                      style={{
                        opacity: "0.7",
                        border: "none",
                        color: "#000000",
                        borderBottom: "solid 2px #e0e0e0",
                        marginTop: "30px",
                      }}
                    />
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "39px",
                        marginLeft: "-30px",
                        position: "absolute",
                      }}
                      onClick={onReset}
                      src={x_round}
                    />
                    <img
                      id="cobtn13"
                      src={cobtn1}
                      onClick={applyCoupon}
                      style={{
                        width: "120px",
                        height: "40px",
                        marginTop: "30px",
                        position: "absolute",
                      }}
                    />
                  </div>
                  <div
                    id="couponComment"
                    style={{
                      fontSize: "12px",
                      color: "#898989",
                      marginBottom: "20px",
                    }}
                  >
                    <a>{comment}</a>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "300px" }}></div>
          </div>
          <div
            id="introFooter"
            style={{
              float: "right",
              backgroundColor: "#f5f6ff",
              height: "116px",
              lineHeight: "110px",
            }}
          >
            <Link to="/test8">
              <img
                src={new_blue}
                style={{
                  cursor: "pointer",
                  width: "200px",
                  marginLeft: "20px",
                  marginTop: "27px",
                  float: "right",
                  marginRight: "calc(50% - 485px)",
                }}
              />
            </Link>
            <a href="http://pf.kakao.com/_lWuhs/chat">
              <img
                src={kakaoaa}
                style={{
                  cursor: "pointer",
                  width: "283px ",
                  marginTop: "27px",
                  float: "right",
                }}
              />
            </a>
            <div
              className="Bold1"
              style={{ fontSize: "30px", marginLeft: "calc(50% - 485px)" }}
            >
              <a>
                총 <a style={{ color: "#4a64f5" }}>{price2}</a>원
              </a>
            </div>
          </div>
        </div>
      </BrowserView>
    </div>
  );
}

export default Test7;
