import React, { useState, useEffect } from "react";
import menu from "../image/menu@3x.png";
import "../text.css";
import x_btn from "../image/x_menuicon@3x.png";
import backarrow from "../data/backarrow@3x.png";
import user_profile from "../image/user_profile.png";
import modify_profile from "../image/modify_profile@2x.png";
import arrowdown from "../image/mypagearrow@3x.png";
import arrowup from "../image/uparrow@3x.png";
import zxxzas from "../image/zxxzas.png";
import "../text.css";
import inf from "../image/inf.png";
import nametecLogo from "../image/nametecLogo.png";
import zxzx from "../image/zxzx.png";
import new_blue from "../image/zx2.png";
import af389 from "../image/1f389.png";
import BrowserHeader from "../components/BrowserHeader";
import kakaoaa from "../image/kakaoaa.png";
import mypagearrow from "../image/mypagearrow.png";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  IsMobile,
  TabletView,
} from "react-device-detect";
import $ from "jquery";

function Successmoa(props) {
  const animals = props.skarb;
  const animalList = animals.map((animal) => (
    <div
      id="application_show"
      style={{
        width: "90%",
        marginLeft: "5%",
        borderRadius: "10px",
        border: "solid 1px #e0e0e0",
        boxShadow: "1px 1px 4px 0 rgba(0, 0, 0, 0.14)",
        marginTop: "23px",
      }}
    >
      <div style={{ margin: "22px 5% 0px 5%" }}>
        <div
          id="maindiv0"
          style={{ cursor: "pointer" }}
          onClick={() => onclickClass121(animal.application_seq)}
        >
          <div
            className="Regular"
            style={{
              fontSize: "16px",
              color: "rgb(74, 100, 245)",
              textAlign: "center",
              display: "inline-block",
              width: "70px",
              height: "24px",
              transform: "translateY(-5px)",
              position: "absolute",
              marginTop: "9px",
            }}
          >
            {animal.progress_status.length > 4
              ? animal.progress_status.substr(0, 4) +
                "\n" +
                animal.progress_status.substr(4)
              : animal.progress_status}
          </div>

          <div style={{ display: "inline-block", marginLeft: "110px" }}>
            <div style={{ display: "inline-block" }}>
              {animal.real_brandname == ""
                ? animal.real_brandname
                : animal.brandname}
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgb(183, 183, 183)",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "140px",
              }}
            >
              {animal.category}
            </div>
          </div>

          <img
            id={"imgdiv" + animal.application_seq}
            src={arrowdown}
            style={{
              float: "right",
              display: "inline-block",
              marginTop: "16px",
              width: "10px",
            }}
          />
        </div>

        <div
          style={{
            width: "111%",
            height: "1px",
            marginLeft: "-5.5%",
            backgroundColor: "rgb(224, 224, 224)",
            marginTop: "20px",
          }}
        ></div>

        <div
          id={"div" + animal.application_seq}
          class="backGR"
          style={{
            backgroundColor: "rgb(250, 250, 250)",
            height: "290px",
            width: "111%",
            marginLeft: "-5.5%",
            display: "none",
          }}
        >
          <div
            style={{
              borderBottom: "1px solid rgb(224, 224, 224)",
              marginLeft: "5%",
              marginRight: "5%",
            }}
          >
            <div
              className="Medium"
              style={{
                paddingTop: "10px",
                color: "rgb(137, 137, 137)",
                fontSize: "16px",
              }}
            >
              결제일
            </div>
            <div
              className="Medium"
              style={{
                color: "rgb(81, 81, 81)",
                paddingBottom: "8px",
                fontSize: "16px",
              }}
            >
              {animal.payment_date_rework}
            </div>
            {animal.done == "0" ? (
              <div
                onClick={() => {
                  window.location =
                    "https://nametecadmin.kr/imform?merchant_uid=" +
                    animal.merchant_uid;
                }}
                className="Medium"
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  borderRadius: "5px",
                  boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 4px 0px",
                  textAlign: "center",
                  height: "36px",
                  width: "130px",
                  left: "50%",
                  marginLeft: "30px",
                  marginTop: "12px",
                  lineHeight: "3",
                  color: "#4a64f5",
                }}
              >
                출원인 정보 입력하기
              </div>
            ) : (
              <div
                className="Medium"
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  borderRadius: "5px",
                  boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 4px 0px",
                  textAlign: "center",
                  height: "36px",
                  width: "130px",
                  left: "50%",
                  marginLeft: "30px",
                  marginTop: "12px",
                  lineHeight: "3",
                  backgroundColor: "rgb(239, 239, 239)",
                  color: "rgb(137, 137, 137)",
                }}
              >
                출원인 정보 입력완료
              </div>
            )}
          </div>

          <div
            style={{
              borderBottom: "1px solid rgb(224, 224, 224)",
              marginLeft: "5%",
              marginRight: "5%",
            }}
          >
            <div
              className="Medium"
              style={{
                paddingTop: "10px",
                color: "rgb(137, 137, 137)",
                fontSize: "16px",
              }}
            >
              출원인 정보
            </div>
            <div
              className="Medium"
              style={{
                color: "rgb(81, 81, 81)",
                paddingBottom: "8px",
                fontSize: "16px",
              }}
            >
              {animal.applicant == "" ? animal.applicant : "내용없음"}
            </div>
            {animal.application_date == "0" ? (
              <div
                className="Medium"
                style={{
                  fontSize: "12px",
                  position: "absolute",
                  borderRadius: "5px",
                  boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 4px 0px",
                  textAlign: "center",
                  height: "36px",
                  width: "130px",
                  left: "50%",
                  marginLeft: "30px",
                  marginTop: "12px",
                  lineHeight: "3",
                  backgroundColor: "rgb(239, 239, 239)",
                  color: "rgb(137, 137, 137)",
                }}
              >
                출원 내역 상세보기
              </div>
            ) : (
              <a
                href={
                  "https://nametecadmin.kr/static/data/" +
                  animal.details_of_application
                }
              >
                <div
                  className="Medium"
                  style={{
                    fontSize: "12px",
                    position: "absolute",
                    borderRadius: "5px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 4px 0px",
                    textAlign: "center",
                    height: "36px",
                    width: "130px",
                    left: "50%",
                    marginLeft: "30px",
                    marginTop: "12px",
                    lineHeight: "3",
                    color: "#4a64f5",
                  }}
                >
                  출원 내역 상세보기
                </div>
              </a>
            )}
          </div>

          <ItemContent
            title="출원일"
            subtitle={
              animal.application_date == 0
                ? "날짜 미정"
                : animal.application_date
            }
            current="등록료 결제하기"
          />
          <ItemContent
            title="등록료 납부 상태"
            subtitle={
              animal.progress_status == "등록요금 납부대기"
                ? "납부 대기중"
                : animal.progress_status === "등록요금 납부완료"
                ? "납부 완료"
                : "납부일 미정"
            }
            current={null}
          />
          <ItemContent2
            title="등록일"
            subtitle={
              animal.registration_date_rework == "0"
                ? "날짜 미정"
                : animal.registration_date_rework
            }
          />
        </div>
      </div>
    </div>
  ));

  return <div>{animalList}</div>;
}
function Successpc(props) {
  const animals = props.skarb;
  const animalList = animals.map((animal) => (
    <div
      id={animal.application_seq}
      style={{
        paddingLeft: "5%",
        paddingRight: "5%",
        paddingTop: "20px",
        borderRadius: "10px",
      }}
    >
      <div
        id={animal.application_seq + "k"}
        style={{ cursor: "pointer" }}
        onClick={() => onclickClass11(animal.application_seq)}
      >
        <div className="Medium asd" style={{ whiteSpace: "pre-line" }}>
          {animal.progress_status.length > 5
            ? animal.progress_status.substr(0, 4) +
              "\n" +
              animal.progress_status.substr(4)
            : animal.progress_status}
        </div>
        <div style={{ display: "inline-block", marginLeft: "92px" }}>
          <a
            id={animal.application_seq + "a"}
            class="Regular"
            style={{
              color: "#515151",
              fontSize: "16px",
              position: "absolute",
              marginTop: "-37px",
            }}
          >
            {animal.real_brandname == ""
              ? animal.real_brandname
              : animal.brandname}
          </a>
          <a
            id={animal.application_seq + "a"}
            class="Regular asdaa"
            style={{
              color: "#898989",
              position: "absolute",
              fontSize: "12px",
              marginTop: "-17px",
            }}
          >
            {animal.category}
          </a>
        </div>

        <img
          id={"imgdiv" + animal.application_seq}
          src={mypagearrow}
          style={{
            float: "right",
            display: "inline-block",
            marginTop: "13px",
            width: "21px",
          }}
        />
      </div>
      <div
        style={{
          width: "111%",
          height: "1px",
          marginLeft: "-5.5%",
          backgroundColor: "rgb(224, 224, 224)",
          marginTop: "20px",
        }}
      ></div>
      <div
        id={"div" + animal.application_seq}
        class="backGR"
        style={{
          display: "none",
          backgroundColor: "rgb(250, 250, 250)",
          height: "284px",
          width: " 111%",
          marginLeft: "-5.5%",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid rgb(224, 224, 224)",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <div
            className="Medium"
            style={{
              fontSize: "16px",
              paddingTop: "10px",
              color: "rgb(137, 137, 137)",
              display: "inline-block",
              marginTop: "15px",
              marginBottom: "25px",
              width: "73.05px",
              textAlign: "center",
            }}
          >
            결제일
          </div>
          <div
            className="Regular"
            style={{
              color: "rgb(81, 81, 81)",
              paddingBottom: "8px",
              display: "inline-block",
              marginLeft: "92px",
            }}
          >
            {animal.payment_date_rework}
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid rgb(224, 224, 224)",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <div
            className="Medium"
            style={{
              fontSize: "16px",
              paddingTop: "10px",
              color: "rgb(137, 137, 137)",
              display: "inline-block",
              marginTop: "15px",
              marginBottom: "25px",
              width: "73.05px",
              textAlign: "center",
            }}
          >
            출원인 정보
          </div>
          <div
            className="Regular"
            style={{
              color: "rgb(81, 81, 81)",
              paddingBottom: "8px",
              display: "inline-block",
              marginLeft: "92px",
            }}
          >
            {animal.applicant == "" ? "내용없음" : animal.applicant}
          </div>

          {animal.done == "0" ? (
            <div
              onClick={() => {
                window.location =
                  "https://nametecadmin.kr/imform?merchant_uid=" +
                  animal.merchant_uid;
              }}
              className="Regular"
              style={{
                fontSize: "16px",
                borderRadius: "5px",
                boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 4px 0px",
                textAlign: "center",
                height: "36px",
                width: "160px",
                left: "50%",
                marginLeft: "30px",
                marginTop: "18px",
                lineHeight: "2.34",
                color: "#4a64f5",
                display: "inline-block",
                float: "right",
                cursor: "pointer",
              }}
            >
              출원인 정보 입력하기
            </div>
          ) : (
            <div
              className="Regular"
              style={{
                fontSize: "16px",
                borderRadius: "5px",
                boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 4px 0px",
                textAlign: "center",
                height: "36px",
                width: "160px",
                left: "50%",
                marginLeft: "30px",
                marginTop: "18px",
                lineHeight: "2.34",
                backgroundColor: "rgb(239, 239, 239)",
                color: "rgb(137, 137, 137)",
                display: "inline-block",
                float: "right",
              }}
            >
              출원인 정보 입력완료
            </div>
          )}
        </div>
        <div
          style={{
            borderBottom: "1px solid rgb(224, 224, 224)",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <div
            className="Medium"
            style={{
              fontSize: "16px",
              paddingTop: "10px",
              color: "rgb(137, 137, 137)",
              display: "inline-block",
              marginTop: "5px",
              marginBottom: "15px",
              width: "73.05px",
              textAlign: "center",
            }}
          >
            등록료 <br />
            납부 상태
          </div>
          <div
            className="Regular"
            style={{
              color: "rgb(81, 81, 81)",
              position: "absolute",
              marginTop: "26px",
              display: "inline-block",
              marginLeft: "92px",
            }}
          >
            {animal.progress_status === "등록요금 납부대기"
              ? "납부 대기중"
              : animal.progress_status === "등록요금 납부완료"
              ? "납부 완료"
              : "납부일 미정"}
          </div>
          <div
            className="Regular"
            style={{
              fontSize: "16px",
              borderRadius: "5px",
              boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 4px 0px",
              textAlign: "center",
              height: "36px",
              width: "160px",
              left: "50%",
              marginLeft: "30px",
              marginTop: "18px",
              lineHeight: "2.34",
              backgroundColor: "rgb(239, 239, 239)",
              color: "rgb(137, 137, 137)",
              display: "inline-block",
              float: "right",
            }}
          >
            등록료 결제하기
          </div>
        </div>
        <div style={{ marginLeft: "5%", marginRight: "5%" }}>
          <div
            className="Medium"
            style={{
              fontSize: "16px",
              paddingTop: "10px",
              color: "rgb(137, 137, 137)",
              display: "inline-block",
              marginTop: "15px",
              marginBottom: "25px",
              width: "73.05px",
              textAlign: "center",
            }}
          >
            등록일
          </div>
          <div
            className="Regular"
            style={{
              color: "rgb(81, 81, 81)",
              paddingBottom: "8px",
              display: "inline-block",
              marginLeft: "92px",
            }}
          >
            {animal.registration_date_rework == "0"
              ? "날짜 미정"
              : animal.registration_date_rework}
          </div>
        </div>
      </div>
    </div>
  ));

  return <div>{animalList}</div>;
}

const MyInfoItem = ({ name, email }) => {
  return (
    <div
      class="conList"
      style={{
        width: "90%",
        marginLeft: "5%",
        marginTop: "39px",
        paddingBottom: "20px",
        paddingTop: "20px",
        border: "solid 1px #e0e0e0",
        borderRadius: "10px",
        margin: "15px 20px 0x 20px",
        textAlign: "left",
        display: "grid",
        gridTemplateColumns: "0.3fr 0.8fr 0.2fr",
        boxShadow: "1px 1px 4px 0 rgba(0, 0, 0, 0.14)",
      }}
    >
      <img
        src={user_profile}
        style={{
          marginLeft: "18px",
          marginRight: "10px",
          display: "inline-block",
        }}
      />
      <div style={{ display: "inline-block", width: "90%" }}>
        <div style={{ display: "inline-block", marginTop: "2px" }}>
          <div id="customername" className="noto" style={{ fontSize: "16px" }}>
            {name}
          </div>
          <div
            id="customeremail"
            className="noto"
            style={{ fontSize: "10px", color: "#b7b7b7" }}
          >
            {email}
          </div>
        </div>
      </div>
      <div style={{ display: "inline-block", float: "right" }}>
        <Link to="/member">
          <img
            src={modify_profile}
            style={{
              cursor: "pointer",
              marginTop: "15px",
              marginRight: "20px",
              width: "68px",
            }}
          />
        </Link>
      </div>
    </div>
  );
};

const ItemContent = ({ title, subtitle, current }) => {
  return (
    <div
      style={{
        borderBottom: "1px solid rgb(224, 224, 224)",
        marginLeft: "5%",
        marginRight: "5%",
      }}
    >
      {/* font 상속이 되나 싶어서 부모에 className을 넣었는데 상속 X */}
      <div
        className="Medium"
        style={{
          paddingTop: "10px",
          color: "rgb(137, 137, 137)",
          fontSize: "16px",
        }}
      >
        {title}
      </div>
      <div
        className="Medium"
        style={{
          color: "rgb(81, 81, 81)",
          paddingBottom: "8px",
          fontSize: "16px",
        }}
      >
        {subtitle}
      </div>
      {typeof current === "string" ? (
        <div
          className="Medium"
          style={{
            fontSize: "12px",
            position: "absolute",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 4px 0px",
            textAlign: "center",
            height: "36px",
            width: "130px",
            left: "50%",
            marginLeft: "30px",
            marginTop: "18px",
            lineHeight: "3",
            backgroundColor: "rgb(239, 239, 239)",
            color: "rgb(137, 137, 137)",
          }}
        >
          {current}
        </div>
      ) : null}
      {/* current값이 있으면 넣고 없으면 null */}
    </div>
  );
};
const ItemContent2 = ({ title, subtitle, current }) => {
  return (
    <div style={{ borderRadius: "10px", marginLeft: "5%", marginRight: "5%" }}>
      {/* font 상속이 되나 싶어서 부모에 className을 넣었는데 상속 X */}
      <div
        className="Medium"
        style={{
          paddingTop: "10px",
          color: "rgb(137, 137, 137)",
          fontSize: "16px",
        }}
      >
        {title}
      </div>
      <div
        className="Medium"
        style={{
          color: "rgb(81, 81, 81)",
          paddingBottom: "8px",
          fontSize: "16px",
        }}
      >
        {subtitle}
      </div>
      {typeof current === "string" ? (
        <div
          className="Medium"
          style={{
            fontSize: "12px",
            position: "absolute",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.1) 1px 1px 4px 0px",
            textAlign: "center",
            height: "36px",
            width: "130px",
            left: "50%",
            marginLeft: "30px",
            marginTop: "18px",
            lineHeight: "3",
            backgroundColor: "rgb(239, 239, 239)",
            color: "rgb(137, 137, 137)",
          }}
        >
          {current}
        </div>
      ) : null}
      {/* current값이 있으면 넣고 없으면 null */}
    </div>
  );
};

const MypageTopbar = () => {
  let product = "";
  const [display, setDisplay] = useState("none");

  const modalHandler = () => {
    if (display === "none") {
      setDisplay("inline-block");
    } else {
      setDisplay("none");
    }
    return;
  };

  const [nickname, setNickname] = useState("");
  const [customeremail, setCustomeremail] = useState("");
  const [valuelist24, setValuelist24] = useState([]);
  const [valuelist24a, setValuelist24s] = useState([]);
  useEffect(() => {
    setNickname(localStorage.getItem("customername"));
    setCustomeremail(localStorage.getItem("customeremail"));
  }, []);

  useEffect(() => {
    let asd = localStorage.getItem("customeremail");
    $.ajax({
      type: "POST",
      url: "https://nametecadmin.kr/showapplicationstatus",
      data: JSON.stringify({
        email: asd,
      }),
      processData: false,
      contentType: "application/json",
      async: false,
      success: function (data) {
        if (data.status == 200) {
          product = data.data;
          setValuelist24(product);
          if (product == null) {
          } else {
          }
        } else {
        }
      },
      err: function (err) {
        alert(err.status);
      },
    });
  }, []);
  return (
    // 마이페이지 헤더
    <div>
      <TabletView>
        <div style={{ margin: "auto", maxWidth: "520px" }}>
          <div
            style={{
              top: "0px",
              Width: "100%",
              backgroundColor: "#FFFFFF",
              zIndex: "10010",
              maxWidth: "520px",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #e0e0e0",
              height: "55px",
            }}
          >
            <Link to="/">
              <img
                className="topbar-backbutton"
                src={backarrow}
                style={{
                  objectFit: "contain",
                  width: "14px",
                  height: "23px",
                  margin: "16px 0 0 20px",
                }}
                alt="<"
              />
            </Link>

            <div
              className="Bold"
              style={{
                display: "inline-block",
                marginTop: "12px",
                fontWeight: "500",
                fontSize: "20px",
              }}
            >
              마이페이지
            </div>

            <img
              src={menu}
              style={{
                cursor: "pointer",
                display: "inline-block",
                float: "right",
                width: "22px",
                height: "17px",
                marginTop: "20px",
                marginRight: "20px",
              }}
              onClick={modalHandler}
            />
          </div>

          {/* state를 정하고 export하는 컴포넌트에서 state를 받아서 사용 */}
          {/* 상위, 하위의 개념이 아니라 둘다 동시에 받으면 된다 그러니까  */}

          {/* 마이 페이지 메뉴모달 */}

          <div
            id="menuContainer"
            style={{
              display: display,
              position: "fixed",
              top: "0px",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              fontFamily: "Bold",
              fontSize: "24px",
              maxWidth: "520px",
              margin: "auto",
              zIndex: "20000",
            }}
          >
            <div style={{ backgroundColor: "#ffffff", width: "100%" }}>
              <div className="menuicon1">
                <img
                  src={nametecLogo}
                  class=""
                  style={{
                    display: "inline-block",
                    width: "105px",
                    transform: "translate(28px)",
                    marginTop: "12px",
                  }}
                />
                <img
                  src={x_btn}
                  class=""
                  id="xbtn"
                  onClick={modalHandler}
                  style={{
                    cursor: "pointer",
                    width: "18px",
                    float: "right",
                    marginRight: "20px",
                    marginTop: "20px",
                  }}
                />
              </div>
              <div
                style={{
                  backgroundColor: "#4a64f5",
                  float: "right",
                  width: "60%",
                  height: "20000px",
                }}
              >
                <div className="menuicon1" style={{ height: "50px" }}>
                  <Link to="/">
                    <span
                      style={{
                        cursor: "pointer",
                        marginRight: "20px",
                        marginTop: "40px",
                        float: "right",
                        display: "inline-block",
                        color: "#fff",
                      }}
                    >
                      메인화면
                    </span>
                  </Link>
                </div>

                <Link
                  style={{
                    display: "block",
                    marginTop: "40px",
                    marginRight: "20px",
                  }}
                  to="/costprocess"
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      display: "inline-block",
                      float: "right",
                    }}
                  >
                    비용 및 절차 안내
                  </span>
                </Link>
                <Link
                  style={{
                    display: "block",
                    marginTop: "90px",
                    marginRight: "20px",
                  }}
                  to="/center"
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      display: "inline-block",
                      float: "right",
                    }}
                  >
                    {" "}
                    고객센터
                  </span>
                </Link>

                <Link
                  style={{
                    display: "block",
                    marginTop: "140px",
                    marginRight: "20px",
                  }}
                  to="/"
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      display: "inline-block",
                      float: "right",
                    }}
                  >
                    로그아웃
                  </span>
                </Link>

                <div
                  className="Medium"
                  style={{
                    marginLeft: "30px",
                    fontSize: "15px",
                    marginTop: "100px",
                    marginRight: "20px",
                    float: "right",
                    color: "#ffffff",
                  }}
                >
                  Copyright©2021 티타티티
                </div>

                <div style={{ height: "33px" }}></div>
              </div>
            </div>
          </div>

          {/* 마이페이지 리스트 */}
          <div>
            <MyInfoItem name={nickname} email={customeremail} />

            <Successmoa skarb={valuelist24} />
          </div>
        </div>
      </TabletView>
      <MobileView>
        <div style={{ margin: "auto", maxWidth: "520px" }}>
          <div
            style={{
              top: "0px",
              Width: "100%",
              backgroundColor: "#FFFFFF",
              zIndex: "10010",
              maxWidth: "520px",
              margin: "auto",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #e0e0e0",
              height: "55px",
            }}
          >
            <Link to="/">
              <img
                className="topbar-backbutton"
                src={backarrow}
                style={{
                  objectFit: "contain",
                  width: "14px",
                  height: "23px",
                  margin: "16px 0 0 20px",
                }}
                alt="<"
              />
            </Link>

            <div
              className="Bold"
              style={{
                display: "inline-block",
                marginTop: "12px",
                fontWeight: "500",
                fontSize: "20px",
              }}
            >
              마이페이지
            </div>

            <img
              src={menu}
              style={{
                cursor: "pointer",
                display: "inline-block",
                float: "right",
                width: "22px",
                height: "17px",
                marginTop: "20px",
                marginRight: "20px",
              }}
              onClick={modalHandler}
            />
          </div>

          {/* state를 정하고 export하는 컴포넌트에서 state를 받아서 사용 */}
          {/* 상위, 하위의 개념이 아니라 둘다 동시에 받으면 된다 그러니까  */}

          {/* 마이 페이지 메뉴모달 */}

          <div
            id="menuContainer"
            style={{
              display: display,
              position: "fixed",
              top: "0px",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              fontFamily: "Bold",
              fontSize: "24px",
              maxWidth: "520px",
              margin: "auto",
              zIndex: "20000",
            }}
          >
            <div style={{ backgroundColor: "#ffffff", width: "100%" }}>
              <div className="menuicon1">
                <img
                  src={nametecLogo}
                  class=""
                  style={{
                    display: "inline-block",
                    width: "105px",
                    transform: "translate(28px)",
                    marginTop: "12px",
                  }}
                />
                <img
                  src={x_btn}
                  class=""
                  id="xbtn"
                  onClick={modalHandler}
                  style={{
                    cursor: "pointer",
                    width: "18px",
                    float: "right",
                    marginRight: "20px",
                    marginTop: "20px",
                  }}
                />
              </div>
              <div
                style={{
                  backgroundColor: "#4a64f5",
                  float: "right",
                  width: "60%",
                  height: "20000px",
                }}
              >
                <div className="menuicon1" style={{ height: "50px" }}>
                  <Link to="/">
                    <span
                      style={{
                        cursor: "pointer",
                        marginRight: "20px",
                        marginTop: "40px",
                        float: "right",
                        display: "inline-block",
                        color: "#fff",
                      }}
                    >
                      메인화면
                    </span>
                  </Link>
                </div>

                <Link
                  style={{
                    display: "block",
                    marginTop: "40px",
                    marginRight: "20px",
                  }}
                  to="/costprocess"
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      display: "inline-block",
                      float: "right",
                    }}
                  >
                    비용 및 절차 안내
                  </span>
                </Link>
                <Link
                  style={{
                    display: "block",
                    marginTop: "90px",
                    marginRight: "20px",
                  }}
                  to="/center"
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      display: "inline-block",
                      float: "right",
                    }}
                  >
                    {" "}
                    고객센터
                  </span>
                </Link>

                <Link
                  style={{
                    display: "block",
                    marginTop: "140px",
                    marginRight: "20px",
                  }}
                  to="/"
                >
                  <span
                    style={{
                      cursor: "pointer",
                      color: "#fff",
                      display: "inline-block",
                      float: "right",
                    }}
                  >
                    로그아웃
                  </span>
                </Link>

                <div
                  className="Medium"
                  style={{
                    marginLeft: "30px",
                    fontSize: "15px",
                    marginTop: "100px",
                    marginRight: "20px",
                    float: "right",
                    color: "#ffffff",
                  }}
                >
                  Copyright©2021 티타티티
                </div>

                <div style={{ height: "33px" }}></div>
              </div>
            </div>
          </div>

          {/* 마이페이지 리스트 */}
          <div>
            <MyInfoItem name={nickname} email={customeremail} />

            <Successmoa skarb={valuelist24} />
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <BrowserHeader />
        <div style={{ minWidth: "1000px" }}>
          <div style={{ minWidth: "1000px" }}>
            <div
              className="Bold"
              style={{
                fontSize: "40px",
                textAlign: "center",
                marginTop: "146px",
              }}
            >
              마이페이지
            </div>
            <div
              className="Medium"
              style={{
                marginLeft: "calc(50% - 470px)",
                marginTop: "25px",
                fontSize: "18px",
              }}
            >
              기본 정보
              <div
                className="askBox123"
                style={{
                  width: "900px",
                  border: "1px solid #e0e0e0",
                  paddingTop: "48px",
                  paddingLeft: "54px",
                  paddingBottom: "47px",
                  marginTop: "21px",
                  borderRadius: "10px",
                  boxShadow: "1px 1px 4px 0 rgba(0, 0, 0, 0.16)",
                }}
              >
                <div style={{ marginBottom: "4px" }}>
                  <a style={{ fontSize: "18px", color: "#515151" }}>
                    {nickname}
                  </a>
                </div>
                <a style={{ fontSize: "18px", color: "#b7b7b7" }}>
                  {customeremail}
                </a>
                <Link to="/member">
                  <img
                    src={inf}
                    style={{
                      width: "140px",
                      float: "right",
                      marginTop: "-13px",
                      cursor: "pointer",
                      marginRight: "50px",
                    }}
                  />
                </Link>
              </div>
            </div>
            <div
              className="Medium"
              style={{
                marginLeft: "calc(50% - 470px)",
                marginTop: "45px",
                fontSize: "18px",
              }}
            >
              출원 과정 안내
              <div>
                <img src={zxzx} style={{ width: "956px", marginTop: "40px" }} />
              </div>
            </div>
            <div
              className="Medium"
              style={{
                marginLeft: "calc(50% - 470px)",
                marginTop: "45px",
                fontSize: "18px",
              }}
            >
              출원 내역
            </div>
          </div>

          <div
            style={{
              marginLeft: "calc(50% - 470px)",
              width: "956px",
              borderRadius: "10px",
              borderTop: "solid 1px #e0e0e0",
              borderLeft: "solid 1px #e0e0e0",
              borderRight: "solid 1px #e0e0e0",
              boxShadow: "1px 1px 4px 0 rgba(0, 0, 0, 0.14)",
              marginTop: "40px",
              lineHeight: "1.2",
            }}
          >
            <Successpc skarb={valuelist24} />
          </div>
        </div>
        <div style={{ height: "200px" }}></div>
        <div
          id="introFooter"
          style={{
            float: "right",
            backgroundColor: "#f5f6ff",
            height: "116px",
          }}
        >
          <div style={{ marginTop: "34px" }}>
            <a
              className="Bold1"
              style={{ fontSize: "20px", marginLeft: "calc(50% - 470px)" }}
            >
              상표출원은 <a style={{ color: "#4a64f5" }}>365일, 24시간</a>{" "}
              아무때나! 카톡 상담은 평일 09:00~18:00
            </a>
          </div>
          <div style={{ marginTop: "5px" }}>
            <a
              className="Medium1"
              style={{
                color: "#898989",
                fontSize: "16px",
                marginLeft: "calc(50% - 470px)",
              }}
            >
              *상담 시간 안내 : 점심 시간 12:00~13:00, 공휴일 휴무이니 참고해
              주세요 :)
            </a>
          </div>

          <div
            style={{
              float: "right",
              marginTop: "-81px",
              marginRight: "calc(50% - 468px)",
            }}
          >
            <Link to="/brandregister">
              <img
                src={new_blue}
                style={{
                  cursor: "pointer",
                  height: "61px",
                  width: "223px",
                  marginLeft: "320px",
                  marginTop: "28px",
                  marginRight: "calc(50% - 685px)",
                }}
              />
            </Link>
            <a href="http://pf.kakao.com/_lWuhs/chat">
              <img
                src={kakaoaa}
                style={{
                  cursor: "pointer",
                  height: "61px",
                  width: "283px ",
                  marginTop: "27px",
                  float: "right",
                }}
              />
            </a>
          </div>
        </div>
      </BrowserView>
    </div>
  );
};

export default MypageTopbar;

function onclickClass11(i) {
  let div = document.getElementById(i);
  let divk = document.getElementById(i + "k");
  let div2 = document.getElementById("div" + i);
  if (div2.style.display == "") {
    div2.style.display = "none";
  } else {
    div2.style.display = "";
  }
}
function onclickClass121(i) {
  let div2 = document.getElementById("div" + i);
  if (div2.style.display == "") {
    div2.style.display = "none";
  } else {
    div2.style.display = "";
  }
}
