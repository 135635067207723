import React, {useState, useEffect} from 'react';
import TopbarComponent from '../components/Topbar';
import Nextbutton from '../components/Nextbutton';
import '../style.css';
import x_round from '../image/x_round.png';
import ask1234 from '../image/zxc.png';
import character_profile from '../image/character_profile@3x.png';
import zxczxas from '../image/zxczxas.png'
import kakaoaa from '../image/kakaoaa.png'
import zxa13 from '../image/zxa13.png'
import znznzq1 from '../image/znznzq1.png'
import $ from 'jquery';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";
import styled from "styled-components";
import BrowserHeader from '../components/BrowserHeader';
import ReactPixel from 'react-facebook-pixel';
const PIXEL_ID = '266266868791844';
ReactPixel.init(PIXEL_ID);
ReactPixel.track('Purchase', {currency: 'KRW',value: '0'});

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding-left:10px;
  padding-right:10px;
  padding-top:30px;
  padding-bottom:30px;
  border-radius: 10px;
  text-align: center;
  max-width: 350px;
  width: 100%;
  margin-left:10px;
  margin-right:10px;
`;

function LoadingModal({ show, onClose }) {
  if (!show) return null;
  return (
    <ModalOverlay>
      <ModalContent>
      <MobileView>
        <h2 style={{fontSize:'23px'}}>🔔 상표출원을 완료하신 고객님!</h2>
        <p className='Medium1' style={{marginTop :'6px'}}>혹시 로고가 아직 없으신가요? 🤔</p>
        <p className='Medium1' style={{marginTop:'1px'}}>브랜드의 얼굴인 <a style={{color:"#4a64f5"}}>로고 제작</a>도 🎨</p>
        <p className='Medium1' style={{marginTop:'1px'}}><a style={{color:"#4a64f5"}}>네임텍</a>에서 도와드릴게요! 🙋🏻‍♂️</p>
        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
    <div
        className='Regular1'
        onClick={onClose}
        style={{
            backgroundColor: '#b7b7b7',
            paddingTop: '10px',
            paddingBottom: '10px',
            color: '#fff',
            width: '45%',
            cursor:'pointer',
            textAlign: 'center' // 텍스트 가운데 정렬
        }}
    >
        다음에 할게요
    </div>
    <div
    className='Regular1'
    onClick={() => window.open('https://smartstore.naver.com/nametec/products/10601875189', '_blank')}
    style={{
        backgroundColor: '#4a64f5',
        paddingTop: '10px',
        paddingBottom: '10px',
        color: '#fff',
        width: '45%',
        textAlign: 'center',
        cursor: 'pointer' // 클릭 가능한 커서 표시
    }}
>
    로고 맡기러 가기
</div>

</div>
</MobileView>
<BrowserView>
<h2 style={{fontSize:'23px'}}>🔔 상표출원을 완료하신 고객님!</h2>
        <p className='Medium1' style={{marginTop :'10px'}}>혹시 로고가 아직 없으신가요? 🤔</p>
        <p className='Medium1' style={{marginTop:'5px'}}>브랜드의 얼굴인 <a style={{color:"#4a64f5"}}>로고 제작</a>도 🎨</p>
        <p className='Medium1' style={{marginTop:'5px'}}><a style={{color:"#4a64f5"}}>네임텍</a>에서 도와드릴게요! 🙋🏻‍♂️</p>
        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
    <div
        className='Regular1'
        onClick={onClose}
        style={{
            backgroundColor: '#b7b7b7',
            paddingTop: '10px',
            paddingBottom: '10px',
            color: '#fff',
            width: '45%',
            cursor:'pointer',
            textAlign: 'center' // 텍스트 가운데 정렬
        }}
    >
        다음에 할게요
    </div>
    <div
    className='Regular1'
    onClick={() => window.open('https://smartstore.naver.com/nametec/products/10601875189', '_blank')}
    style={{
        backgroundColor: '#4a64f5',
        paddingTop: '10px',
        paddingBottom: '10px',
        color: '#fff',
        width: '45%',
        textAlign: 'center',
        cursor: 'pointer' // 클릭 가능한 커서 표시
    }}
>
    로고 맡기러 가기
</div>
</div>
</BrowserView>
      </ModalContent>
    </ModalOverlay>
  );
}
function addDay(date) {
    // month달 후의 1일
    let addMonthFirstDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
    );

    var year = addMonthFirstDate.getFullYear();
    var month = new String(addMonthFirstDate.getMonth() + 1);
    var day = new String(addMonthFirstDate.getDate());


    if (month.length == 1) {
        month = "0" + month;
    }
    if (day.length == 1) {
        day = "0" + day;
    }

    return year + "년 " + month + "월 " +day +"일";
}
const  getQueryString = (key) => {

    // 전체 Url을 가져온다.
    var str = window.location.href;

    // QueryString의 값을 가져오기 위해서, ? 이후 첫번째 index값을 가져온다.
    var index = str.indexOf("?") + 1;

    // Url에 #이 포함되어 있을 수 있으므로 경우의 수를 나눴다.
    var lastIndex = str.indexOf("#") > -1 ? str.indexOf("#") + 1 : str.length;

    // index 값이 0이라는 것은 QueryString이 없다는 것을 의미하기에 종료
    if (index == 0) {
        return "";
    }

    // str의 값은 a=1&b=first&c=false
    str = str.substring(index, lastIndex);

    // key/value로 이뤄진 쌍을 배열로 나눠서 넣는다.
    str = str.split("&");

    // 결과값
    var rst = "";

    for (var i = 0; i < str.length; i++) {

        // key/value로 나눈다.
        // arr[0] = key
        // arr[1] = value
        var arr = str[i].split("=");

        // arr의 length가 2가 아니면 종료
        if (arr.length != 2) {
            break;
        }

        // 매개변수 key과 일치하면 결과값에 셋팅
        if (arr[0] == key) {
            rst = arr[1];
            break;
        }
    }
    return rst;
}
function patentPrice(){
    let firstgoodsList = JSON.parse(sessionStorage.getItem('firstgoods'))
    let valuelist3 = JSON.parse(sessionStorage.getItem('valuelist3'))
    if(firstgoodsList.length >0){
        return (212000*valuelist3.length).toLocaleString()+'원'
    }else{
        return (52000*valuelist3.length).toLocaleString()+'원'
    }

}
function patentPrice2(){
    let firstgoodsList = JSON.parse(sessionStorage.getItem('firstgoods'))
    let valuelist3 = JSON.parse(sessionStorage.getItem('valuelist3'))
    if(firstgoodsList.length >0){
        return (330000*valuelist3.length).toLocaleString()+'원'
    }else{
        return (110000*valuelist3.length).toLocaleString()+'원'
    }

}
function addMonth(date, month) {
    // month달 후의 1일
    let addMonthFirstDate = new Date(
        date.getFullYear(),
        date.getMonth() + month,
        date.getDate()
    );

    // month달 후의 말일
    let addMonthLastDate = new Date(
        addMonthFirstDate.getFullYear(),
        addMonthFirstDate.getMonth() + month
        , 0
    );

    let result = addMonthFirstDate;
    if (date.getDate() > addMonthLastDate.getDate()) {
        result.setDate(addMonthLastDate.getDate());
    } else {
        result.setDate(date.getDate());
    }

    var year = result.getFullYear();
    var month = new String(result.getMonth() + 1);
    var day = new String(result.getDate());


    if (month.length == 1) {
        month = "0" + month;
    }
    if (day.length == 1) {
        day = "0" + day;
    }

    return year + "년 " + month + "월 " +day +"일";
}
function Successmo(props) {

    const animals = props.skarb
      const animalList = animals.map((animal) => (
      <div  key={animal.id} style={{marginBottom:'10px'}}>
  
          <div className="Medium" style={{fontSize: '22px',color: '#000000',display: 'inline-block'}}>
              {animal.lastgoods.split('+')[0]}</div>
  
              
      </div>
    ))
  
    return <div>{animalList}</div>
  }
  
  function Successmo2(props) {
  
    const animals = props.skarb
      const animalList = animals.map((animal) => (
      <div  key={animal.id} style={{marginBottom:'4px'}}>
          
          <div className="Regular" style={{fontSize: '16px',color: '#000000',display: 'inline-block'}}>
              {animal.lastgoods.split('+')[0]}</div>
  
          
      </div>
    ))
  
    return <div>{animalList}</div>
  }
let janLastDate = new Date();
var tomorow = addDay(janLastDate);
var month12 = addMonth(janLastDate, 12);
var month5 = addMonth(janLastDate, 5);
var customername = localStorage.getItem("customername");
var customerPhone = localStorage.getItem("customerPhone");
var real_email = localStorage.getItem("real_email");
function Payment2(){
const [isModalOpen, setIsModalOpen] = useState(false); 
const closeModal = () => {
    setIsModalOpen(false);
    };
let first = ""
let datezx = ""
try {
    let firstgoods = getQueryString('firstgoods');
    if (firstgoods.length>2){
        first = '선택함'
        datezx = month5
       }else{
        first = '선택하지 않음'
        datezx = month12
       }
  } catch (error) {
    first = '선택하지 않음'
    datezx = month12
  }
  

let product = "";
const [product2, setProduct2] = useState('로고 없음');
const [product3, setProduct3] = useState('로고 없음');
const [brandname, setBrandname] = useState('');
const [price3, setPrice3] = useState('');
const [category, setCategory] = useState('');
const [pay_ment , setPay_ment] = useState('')
const [ment1,setMent1] = useState('')
const [ment2,setMent2] = useState('')
const [ment3,setMent3] = useState('')
const [ment4,setMent4] = useState('')
const [ment5,setMent5] = useState('')
const valuelistz = JSON.parse(sessionStorage.getItem("valuelist3"));
let firstgoodsList = JSON.parse(sessionStorage.getItem('firstgoods'))
    let valuelist3 = JSON.parse(sessionStorage.getItem('valuelist3'))
    let checkDiscount =0
const [discount, setDiscount] = useState(0);
let realPrice =sessionStorage.getItem('price')
const numericString = realPrice.replace(/[^0-9]/g, "");

    const priceNumber = parseInt(numericString, 10);
useEffect(() => {
 setBrandname(sessionStorage.getItem('brandname'))
setPrice3(sessionStorage.getItem('price'));
let categoysession = sessionStorage.getItem('btn_value')
  let categoysession2 = categoysession.split(',')
if (categoysession2.length ==1){
setCategory(categoysession2[0])
}else{
setCategory(categoysession2[0] + ' 외 '+(categoysession2.length-1)+'종')
}



    if(firstgoodsList.length >0){
        checkDiscount = (522000*valuelist3.length)
    }else{
         checkDiscount =  (162000*valuelist3.length)
    }

    if (priceNumber == checkDiscount){
        setDiscount(0)
    }else{
        
        setDiscount((checkDiscount - priceNumber).toLocaleString())
    }

    
},[]);


    useEffect(() => {
        var check = getQueryString("imp_success");
    if (check == 'true'){
    var imp_uid = getQueryString("imp_uid");
    var merchant_uid = getQueryString("merchant_uid");
    let vbank = getQueryString("vbank");
    if (vbank == "T"){
            setPay_ment('결제 금액')
            setMent1('상표출원 신청 완료')
            setMent2('님의  상표 출원이 신청되었습니다. 입금계좌는 카카오톡 알림톡, 이메일로 전달드렸으니 확인해주세요:)')
            setMent3('님의  상표 출원이 신청되었습니다.')
            setMent4('입금계좌는 카카오톡 알림톡, 이메일로')
            setMent5('전달드렸으니 확인해주세요:)')
            
        }else{
            setPay_ment('결제 금액')
            setMent1('결제완료')
            setMent2('님의  상표 출원이 신청되었습니다.')
        }
    if (vbank == 'T' || vbank == 'F'){
        
    }else{
        $.ajax({
            type: "POST",
            url: "https://nametecadmin.kr/get_import_token82",
            data: JSON.stringify({
            "merchant_uid" :merchant_uid
                }),
            processData: false,
            contentType: "application/json",
            async: true,
            success: function (data) {
                if (data.status == 200) {
                     vbank = data.payme
                } else {
                }
            }
            ,
            err: function (err) {
                alert(err.status);
            }
        });
    }
   

    if (vbank =="T"){
    $.ajax({
        type: "POST",
        url: "https://nametecadmin.kr/get_import_token2",
        data: JSON.stringify({
        "imp_uid" :imp_uid
            }),
        processData: false,
        contentType: "application/json",
        async: true,
        success: function (data) {
            if (data.status == 200) {

            } else {
                window.location.replace("/payment")
            }
        }
        ,
        err: function (err) {
            alert(err.status);
        }
    });

    }else{
    $.ajax({
        type: "POST",
        url: "https://nametecadmin.kr/get_import_token",
        data: JSON.stringify({
        "imp_uid" :imp_uid
            }),
        processData: false,
        contentType: "application/json",
        async: true,
        success: function (data) {
            if (data.status == 200) {

            } else {
                window.location.replace("/payment")
            }
        }
        ,
        err: function (err) {
            alert(err.status);
        }
    });
    }

    }else{
        window.location.replace("/payment")
    }

    },[]);
useEffect(() => {

           let imgToken = sessionStorage.getItem("token");
            $.ajax({
                type: "POST",
                url: "https://nametecadmin.kr/getfolderdata1",
                data: JSON.stringify({
                        "token": imgToken
                    }),
                processData: false,
                contentType: "application/json",
                async: true,
                success: function (data) {
                    if (data.status == 200) {
                        product = data.data;
                        console.log(product)
                        if (product == null){
                            setIsModalOpen(true);
                        }else{
                        setProduct3(<img style={{maxHeight:'140px',maxWidth:'140px'}} src={"https://nametecadmin.kr/static/data/"+product} />);
                        setProduct2(<img style={{height:'74px',width:'122px'}} src={"https://nametecadmin.kr/static/data/"+product} />);
                       }
                    } else {


                    }
                }
                ,
                err: function (err) {
                    alert(err.status);
                }
            });
            },[]);
        
    return (

    <div>
    <MobileView>
         <LoadingModal show={isModalOpen} onClose={closeModal} />
        <div id="lastPayPage" style={{maxWidth: '520px', margin: 'auto'}}>


        
        <div id="sum" style={{marginTop:'100px',paddingBottom:'200px',backgroundColor:'#ffffff', paddingLeft: '5%', paddingRight: '5%'}}>
            <div>
          <img src={zxa13} style={{marginTop:'-3px',width: '72px',position:'absolute'}}/>
          <div id="namep" style={{ lineHeight: '1.4', marginLeft: '100px', display: 'inline-block' }}>
                <a className="Bold1" style={{ fontSize: '30px' }}>
                    {ment1}
                    <a style={{ color: '#4A64F5' }}>.</a>
                </a>
                <br />
                <a className="Light" style={{ color: '#4a64f5', fontSize: '14px' }}>
                    {customername}
                    {ment5 === '' ? (
                    <>
                        {ment2}
                    </>
                    ) : (
                    <>
                        {ment3}
                        <br />
                        {ment4}
                        <br />
                        {ment5}
                    </>
                    )}
                </a>
                </div>


                </div>

            <div style={{marginTop:'46px'}}>
            <div>
                <div className="Regular" style={{fontSize:'12px', color: '#4a64f5',marginBottom:'3px'}}>상표명</div>
                <div className="Medium" id="brandname" style={{fontSize:'16px', color: '#000000',fontWeight: '500',marginBottom : '10px'}}>{brandname}</div>
            </div>
            <div>
                <div className="Regular" style={{fontSize:'12px', color:'#4a64f5',marginBottom:'3px'}}>전화번호</div>
                <div className="Medium" id="phonenumber" style={{fontSize:'16px', color: '#000000',fontWeight: '500',marginBottom : '10px'}}>{customerPhone}</div>
            </div>
                <div>
                <div className="Regular" style={{fontSize:'12px', color: '#4a64f5',marginBottom:'3px'}}>이메일</div>
                <div className="Medium" id="email" style={{fontSize:'16px', color: '#000000',fontWeight: '500',marginBottom : '10px'}}>{real_email}</div>
            </div>
            </div>

            <div>

            <div className="Regular" style={{fontSize:'12px', color: '#4a64f5',marginBottom:'3px'}}>
                제품/서비스
            </div>
                       <div>
                       <Successmo2  skarb={valuelistz}/>
                       </div>
                       
            </div>

            <div style={{marginTop:'10px',fontSize : "14px",borderBottom:'1px solid #898989',paddingBottom:'20px'}}>

            <div style={{display:'block'}}>

            <div  className="sucin2" style={{color: '#898989'}}>
                    <p style={{display: 'inline'}}>심사기간 단축 옵션</p>
                    <span className="Regular" style={{color:'#515151',float:'right'}}>{first}</span>
                </div>

                <div className="sucin2" style={{color: '#898989'}}>
                    <p style={{display: 'inline'}}>상표 출원 예상일</p>
                    <span className="Regular" style={{color:'#515151',float:'right'}}>{tomorow}</span>
                </div>

                <div className="sucin2" style={{color: '#898989'}}>
                    <p style={{display: 'inline'}}>심사 결과 통보 예상일</p>
                    <span className="Regular" style={{color:'#515151',float:'right'}}>{datezx}</span>
                </div>
                <div className="sucin2" style={{color: '#898989'}}>
                    <p style={{display: 'inline'}}>특허청 관납료</p>
                    <span className="Regular" style={{color:'#515151',float:'right'}}>{patentPrice()}</span>
                </div>
                <div className="sucin2" style={{color: '#898989'}}>
                    <p style={{display: 'inline'}}>네임텍 수수료</p>
                    <span className="Regular" style={{color:'#515151',float:'right'}}>{patentPrice2()}</span>
                </div>
                <div className="sucin2" style={discount==0
                        ? {display:'none'} 
                        : {color: '#898989'}}>
                    <p style={{display: 'inline'}}>프로모션 할인</p>
                    <span className="Regular" style={{color:'#515151',float:'right'}}>-{discount}원</span>
                </div>
            </div>

                </div> 

                <div className="noto"style={{width:'100%', marginTop:'20px',  fontWeight: '500',display:'inline',float:'left',fontSize:'25px'}}>결제금액<div id="price" style={{display:'inline',float:'right',fontSize:'25px'}}>{price3}</div></div>

        </div>


        <div style={{textAlign : 'center', position: 'fixed',maxWidth : '520px',  bottom : '0px',width : '100%'}}>
        <Link to="/">
        <div id="next_button" className="noto"
                                             style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#4a64f5'}}>
                                            <div id="dam" style={{color:'#ffffff'}}> 완료</div>
                                        </div>
           </Link>
            </div>

        </div>
        </MobileView>
        <BrowserView>
        <LoadingModal show={isModalOpen} onClose={closeModal} />
        <div style={{margin : "0 auto"}}>
                            <BrowserHeader/>
                            <div style={{width:'1400px',paddingLeft:"calc(50% - 700px)",marginBottom:'200px'}}>
                            <div style={{marginLeft : 'calc(50% - 602px)',marginTop : '123px'}}>
                                
                            <img src={znznzq1} style={{width: '102px',position:'absolute',marginTop : '9px'}}/>
                                        <div id="namep" className="Medium" style={{paddingTop : '14px' , marginLeft : '130px',marginBottom: '30px', display: 'inlineBlock',lineHeight:'1.5'}}><a className="Bold1" style={{fontSize:'40px'}}>{ment1}<a style={{color:'#4A64F5'}}>.</a></a> <br/> <a className="Light" style={{color:'#4a64f5',fontSize:'22px'}}>{customername}{ment2}</a></div>
                                            </div>
                       <div className='askBox33' style={{border: '1px solid #e0e0e0' ,display:'flex',width:'910px',marginLeft : 'calc(50% - 500.5px)',marginTop:'50px',borderRadius:'10px',boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)',paddingLeft:'50px',paddingTop:'50px',paddingBottom:'45px'}}>
                       <div style={{display:'inline-block',width:'300px'}}>

                       <div className="Regular" style={{marginLeft:'13px', color:'#4a64f5', fontSize:'18px'}}>상표명</div>

                       <div className="Medium" id="brandNamea" style={{marginLeft:'13px', color:'#000000', fontSize:'22px',marginBottom:'10px'}}>{brandname}</div>

                       <div className="Regular" style={{marginLeft:'13px', color:'#4a64f5', fontSize:'18px'}}>전화번호</div>
                       <div className="Medium" id="lastCheckPhonenumber" style={{marginLeft:'13px', color:'#000000', fontSize:'22px'}}>{customerPhone}</div>
                       <div className="Regular" style={{marginLeft:'13px', color:'#4a64f5', fontSize:'18px',marginTop:'12px'}}>이메일</div>
                       <div className="Medium" id="lastCheckEmail" style={{marginBottom : '10px',marginLeft:'13px', color:'#000000', fontSize:'22px',paddingBottom :'18px'}}>{real_email}</div>
                       <div className="Regular" style={{marginLeft:'13px',color:'#4a64f5', fontSize:'18px'}}>로고</div>
                                      <div className="Medium" style={{textAlign: 'center', marginTop: '5px', border: '1px solid rgb(204, 204, 204)', width: '140px', height: '140px', marginLeft: '13px', color: 'rgb(137, 137, 137)',display:'flex',justifyContent:'center',alignItems:'center'}}>
                                      {product3}
                                      </div>
                                      </div>


                       <div style={{display:'inline-block',width:'600px'}}>
                       <div id="" className="Regular" style={{fontSize: '18px',color: '#4a64f5',marginLeft: '13px'}}> 제품/서비스 </div>
                       <div style={{marginLeft:'13px',marginTop:'8px'}}>
                       <Successmo  skarb={valuelistz}/>
                       </div>
                       
                                        <div style={{float: 'right',marginRight: '13px'}}>

                                        </div>





                                           <div className="in2" style={{margin:'5px 17px 10px 0px',color:'#515151', display:'inline-block',width:'90%',paddingBottom:'20px',marginLeft:'13px',borderBottom:'1px solid #898989'}}>
<div className="sucin2" style={{color: '#898989'}}>
        <p className="Regular" style={{display: 'inline',fontSize:'19px'}}>심사기간 단축 옵션 </p>
        <span  className=" Regular" style={{color:'#515151' ,fontSize:'19px',float:'right'}}>{first}</span>
    
    </div>
    <div className="sucin2" style={{color: '#898989',marginTop:'7px'}}>
        <p className="Regular" style={{display: 'inline',fontSize:'19px'}}>상표 출원 예상일</p>
        <span className=" Regular" style={{fontSize:'19px',color:'#515151',float:'right'}}>{tomorow}</span>
    </div>

    <div className="sucin2" style={{color: '#515898989151',marginTop:'7px'}}>
        <p className="Regular" style={{display: 'inline',fontSize:'19px'}}>심사 결과 통보 예상일 </p>
        <span  className=" Regular" style={{color:'#515151' ,fontSize:'19px',float:'right'}}>{datezx}</span>
    
    </div>
    <div className="sucin2" style={{color: '#898989',marginTop:'7px'}}>
        <p className="Regular" style={{display: 'inline',fontSize:'19px'}}>특허청 관납료</p>
        <span  className=" Regular" style={{color:'#515151' ,fontSize:'19px',float:'right'}}>{patentPrice()}</span>
    
    </div>
    <div className="sucin2" style={{color: '#898989',marginTop:'7px'}}>
        <p className="Regular" style={{display: 'inline',fontSize:'19px'}}>네임텍 수수료</p>
        <span  className=" Regular" style={{color:'#515151' ,fontSize:'19px',float:'right'}}>{patentPrice2()}</span>
    
    </div>
    <div className="sucin2" style={discount==0
            ? {display:'none'} 
            : {color: '#898989',marginTop:'7px'}}>
        <p className="Regular" style={{display: 'inline',fontSize:'19px'}}>프로모션 할인</p>
        <span  className=" Regular" style={{color:'#515151' ,fontSize:'19px',float:'right'}}>-{discount}원</span>
    
    </div>
    </div>
    <div className="" id="textconcheck" style={{width: 'calc(100% - 47px)',marginTop:'12px'}}>
       <div className="" style={{marginTop:'25px'}}>
                                                      <p className="Medium1" style={{fontSize:'25px',display: 'inline',marginLeft:'12px'}}>{pay_ment} </p>
                                                      <span className="Medium1" style={{ fontSize:'25px',float: 'right'}}>{price3}</span></div>

                       </div>





                       
                              </div></div>
                              </div>
                              <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px',lineHeight:'110px'}}>
                                                <Link to="/">
                                                <img src={zxczxas}  style={{cursor:'pointer',width : '200px',marginLeft:'20px',marginTop:'27px',float:'right',marginRight:'calc(50% - 485px)'}}/>
                                                </Link>
                                                <a href='http://pf.kakao.com/_lWuhs/chat'>
                                                         <img src={kakaoaa}  style={{cursor:'pointer',width : '283px ',marginTop:'27px',float:'right'}}/>
                                                         </a>
                                                         <div className="Bold1" style={{fontSize:"30px",marginLeft:'22%'}}>

                                                                                         </div>
                                                         </div>

                 </div>
        </BrowserView>
        </div>

    )

}

export default Payment2;