import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  Route,
  Switch, // Switch를 사용
  Link,
  NavLink,
} from "react-router-dom";
import GoogleTagManager from "./components/Gtm";
import First from "./pages/First";
import Test from "./pages/Test";
import Test2 from "./pages/Test2";
import Test3 from "./pages/Test3";
import Test4 from "./pages/Test4";
import Test5 from "./pages/Test5";
import Test6 from "./pages/Test6";
import Test7 from "./pages/Test7";
import Test8 from "./pages/Test8";
import TestLogin from "./pages/TestLogin";
import BrandRegister from "./pages/BrandRegister";
import BrandSearch from "./pages/BrandSearch";
import BrandLogoRegister from "./pages/BrandLogoRegister";
import BrandCategoryRegister from "./pages/BrandCategoryRegister";
import Mypage from "./pages/Mypage";
import Member from "./pages/Member";
import CostProcessGuide from "./pages/CostProcessGuide";
import Center from "./pages/Center";
import Infomcheck from "./pages/Infomcheck";
import Infomcheck2 from "./pages/Infomcheck2";
import Success3 from "./pages/Success3";
import Success from "./pages/Success";
import Payment from "./pages/Payment";
import Payment2 from "./pages/Payment2";
import All from "./pages/All";
import Login from "./pages/Login";
import Personalpayment from "./pages/Personalpayment";
import Select from "./pages/Select";

function App() {
  return (
    <div className="app">
      <GoogleTagManager gtmId={"GTM-W7CRKH2"} />
      <Switch> {/* Routes 대신 Switch 사용 */}
        <Route exact path="/" component={First} />
        <Route exact path="/test" component={Test} />
        <Route path="/test2" component={Test2} />
        <Route path="/test3" component={Test3} />
        <Route path="/test4" component={Test4} />
        <Route path="/test5" component={Test5} />
        <Route path="/test6" component={Test6} />
        <Route path="/test7" component={Test7} />
        <Route path="/test8" component={Test8} />
        <Route path="/testlogin" component={TestLogin} />
        <Route path="/brandregister" component={BrandRegister} />
        <Route path="/brandSearch" component={BrandSearch} />
        <Route path="/brandlogoregister" component={BrandLogoRegister} />
        <Route path="/brandcategoryregister" component={BrandCategoryRegister} />
        <Route path="/mypage" component={Mypage} />
        <Route path="/member" component={Member} />
        <Route path="/costprocess" component={CostProcessGuide} />
        <Route path="/center" component={Center} />
        <Route path="/infomcheck" component={Infomcheck} />
        <Route path="/success" component={Success} />
        <Route path="/success3" component={Success3} />
        <Route path="/payment" component={Payment} />
        <Route path="/payment2" component={Payment2} />
        <Route path="/login" component={Login} />
        <Route path="/all" component={All} />
        <Route path="/Personalpayment" component={Personalpayment} />
        <Route path="/Select" component={Select} />
        <Route path="*">
          <div>404 Not Found</div> {/* Not Found 처리 */}
        </Route>
      </Switch>
    </div>
  );
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();