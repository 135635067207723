import React, { useState, useEffect } from "react";
import TopbarComponentWithoutPage from "../components/Topbarwithoutpage";
import Nextbutton from "../components/Nextbutton";
import "../style.css";
import x_round from "../image/x_round@3x.png";
import couponbtn3 from "../image/couponbtn3.png";
import ask111 from "../image/zxzxxz.png";
import GuidelineComponent2 from "../components/Guideline2";
import AlertHeader from "../components/AlertHeader";
import AlertHeader2 from "../components/AlertHeader2";
import new_blue from "../image/new_blue.png";
import kakaoaa from "../image/kakaoaa.png";
import cocencle from "../image/cocencle.png";
import cobtn from "../image/cobtn.png";
import $ from "jquery";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  NavLink,
  useParams,
} from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  IsMobile,
  TabletView,
} from "react-device-detect";
import BrowserHeader from "../components/BrowserHeader";
function addDay(date) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1
  );

  var year = addMonthFirstDate.getFullYear();
  var month = new String(addMonthFirstDate.getMonth() + 1);
  var day = new String(addMonthFirstDate.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return year + "년 " + month + "월 " + day + "일";
}
function addMonth(date, month) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth() + month,
    date.getDate()
  );

  // month달 후의 말일
  let addMonthLastDate = new Date(
    addMonthFirstDate.getFullYear(),
    addMonthFirstDate.getMonth() + month,
    0
  );

  let result = addMonthFirstDate;
  if (date.getDate() > addMonthLastDate.getDate()) {
    result.setDate(addMonthLastDate.getDate());
  } else {
    result.setDate(date.getDate());
  }

  var year = result.getFullYear();
  var month = new String(result.getMonth() + 1);
  var day = new String(result.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return year + "년 " + month + "월 " + day + "일";
}
function addMonth2(date, month) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
    date.getFullYear(),
    date.getMonth() + month,
    date.getDate()
  );

  // month달 후의 말일
  let addMonthLastDate = new Date(
    addMonthFirstDate.getFullYear(),
    addMonthFirstDate.getMonth() + month,
    0
  );

  let result = addMonthFirstDate;
  if (date.getDate() > addMonthLastDate.getDate()) {
    result.setDate(addMonthLastDate.getDate());
  } else {
    result.setDate(date.getDate());
  }

  var year = result.getFullYear();
  var month = new String(result.getMonth() + 1);
  var day = new String(result.getDate());

  if (month.length == 1) {
    month = "0" + month;
  }
  if (day.length == 1) {
    day = "0" + day;
  }

  return "심사 단축 시 " + year + "-" + month + "월 예상";
}
function patentPrice() {
  let firstgoodsList = JSON.parse(sessionStorage.getItem("firstgoods"));
  let valuelist3 = JSON.parse(sessionStorage.getItem("valuelist3"));
  if (firstgoodsList.length > 0) {
    return (212000 * valuelist3.length).toLocaleString() + "원";
  } else {
    return (52000 * valuelist3.length).toLocaleString() + "원";
  }
}
function patentPrice2() {
  let firstgoodsList = JSON.parse(sessionStorage.getItem("firstgoods"));
  let valuelist3 = JSON.parse(sessionStorage.getItem("valuelist3"));
  if (firstgoodsList.length > 0) {
    return (330000 * valuelist3.length).toLocaleString() + "원";
  } else {
    return (110000 * valuelist3.length).toLocaleString() + "원";
  }
}
function patentMonth() {
  let firstgoodsList = JSON.parse(sessionStorage.getItem("firstgoods"));
  if (firstgoodsList.length > 0) {
    return addMonth(janLastDate, 3);
  } else {
    return addMonth(janLastDate, 15);
  }
}
function patentSelect() {
  let firstgoodsList = JSON.parse(sessionStorage.getItem("firstgoods"));
  if (firstgoodsList.length > 0) {
    return "선택함";
  } else {
    return "선택하지 않음";
  }
}
var goodsList = [];
let janLastDate = new Date();
var tomorow = addDay(janLastDate);
var month12 = addMonth(janLastDate, 15);
var month5 = addMonth(janLastDate, 3);
var month52 = addMonth2(janLastDate, 3);

function Successmo(props) {
  const animals = props.skarb;
  const animalList = animals.map((animal) => (
    <div
      key={animal.id}
      style={{ marginLeft: "5%", marginRight: "5%", marginBottom: "10px" }}
    >
      <div
        className="Medium"
        style={{ fontSize: "22px", color: "#000000", display: "inline-block" }}
      >
        {animal.lastgoods.split("+")[0]}
      </div>
    </div>
  ));

  return <div>{animalList}</div>;
}

function Successmo2(props) {
  const animals = props.skarb;
  const animalList = animals.map((animal) => (
    <div
      key={animal.id}
      style={{ marginLeft: "5%", marginRight: "5%", marginBottom: "3px" }}
    >
      <div
        className="Regular"
        style={{ fontSize: "16px", color: "#000000", display: "inline-block" }}
      >
        {animal.lastgoods.split("+")[0]}
      </div>
    </div>
  ));

  return <div>{animalList}</div>;
}
function Success3() {
  function formatKoreanMobilePhoneNumber(number) {
    // 휴대전화 형식: 세 자리(010), 그 다음 세 자리, 마지막 네 자리
    var regex = /(\d{3})(\d{3,4})(\d{4})/;
    return number.replace(regex, "$1-$2-$3");
  }
  let storedValue = sessionStorage.getItem("firstgoods");
  let storedValue2 = sessionStorage.getItem("ajaxData");
  let firstgoodsk = 0;
  firstgoodsk = JSON.parse(storedValue2).length;
  // if (storedValue) {
  //   try {
  //     firstgoodsk = JSON.parse(storedValue).length;
  //   } catch (e) {
  //     console.error("JSON 파싱 에러:", e);
  //     firstgoodsk = 0; // 에러 발생 시 0으로 설정
  //   }
  // } else {
  //   firstgoodsk = JSON.parse(storedValue2).length;
  //   alert(firstgoodsk);
  // }
  const [prcount, setPrcount] = useState(0);
  const [comment, setComment] = useState("");
  const [text, setText] = useState("");
  const [coevent, setCoevent] = useState("");
  const [cobtn1, setCobtn] = useState(couponbtn3);
  const [btn, setBtn] = useState(0);
  const [style1, setStyle1] = useState();
  const valuelist = JSON.parse(sessionStorage.getItem("valuelist3"));
  let brandname = sessionStorage.getItem("brandname");
  let customeremail = localStorage.getItem("real_email");
  let customerPhone1 = localStorage.getItem("customerPhone");
  let customerPhone = formatKoreanMobilePhoneNumber(customerPhone1);
  const [valuelist2, setValuelist2] = useState([]);
  const [valuelistz, setValuelistz] = useState([]);
  var aspr = sessionStorage.getItem("nprice");
  let skarbads = parseInt(aspr, 10).toLocaleString();
  const [price, setPrice] = useState(skarbads + "원");
  const [price2, setPrice2] = useState(skarbads);
  const [price3, setPrice3] = useState(skarbads + "원");
  const [price4, setPrice4] = useState(skarbads);
  const [discount, setDiscount] = useState(0);
  let person = "";
  let product = "";
  let percom = "";
  const [sucproduct2, setSucproduct2] = useState("로고 없음");
  const [product3, setProduct3] = useState("로고 없음");
  const onChange = (e) => {
    setText(e.target.value);
  };
  useEffect(() => {
    // valuelist가 제대로 초기화되었는지 확인
    if (Array.isArray(valuelist)) {
      for (let i = 0; i < valuelist.length; i++) {
        if (valuelist[i]) {
          // valuelist[i]가 null이거나 undefined가 아닌지 확인
          valuelist[i]["valuelist2"] = "checkgray";
          valuelist[i]["month"] = month12;
        } else {
          console.warn(`valuelist[${i}]가 null 또는 undefined입니다.`);
        }
      }
      setValuelistz(valuelist);
      sessionStorage.setItem("price", skarbads);
    } else {
      console.error("valuelist가 배열이 아닙니다.");
    }
  }, []);

  useEffect(() => {
    let imgToken = sessionStorage.getItem("token");
    $.ajax({
      type: "POST",
      url: "https://nametecadmin.kr/getfolderdata1",
      data: JSON.stringify({
        token: imgToken,
      }),
      processData: false,
      contentType: "application/json",
      async: false,
      success: function (data) {
        if (data.status == 200) {
          product = data.data;
          $("#disp").css("display", "");
          sessionStorage.setItem("fast", "N");
          if (product == null) {
          } else {
            setProduct3(
              <img
                style={{ maxHeight: "140px", maxWidth: "140px" }}
                src={"https://nametecadmin.kr/static/data/" + product}
              />
            );
            setSucproduct2(
              <img
                style={{ maxHeight: "83px", maxWidth: "83px" }}
                src={"https://nametecadmin.kr/static/data/" + product}
              />
            );
          }
        } else {
        }
      },
      err: function (err) {
        alert(err.status);
      },
    });
  }, []);
  const applyCoupon = () => {
    sessionStorage.removeItem("coupon");
    let fast = sessionStorage.getItem("fast");
    if (fast == "Y") {
      setText("");
      setComment("");
      sessionStorage.setItem("fast", "N");
      const numbern = price2.replace(/,/g, "");
      const number3n = Number(numbern);
      let firstgoods = sessionStorage.getItem("firstgoods");
      let skarbadsaas;
      let number4n = 0;
      if (firstgoods == "[]" || firstgoods == "") {
        number4n = number3n + 11000 * firstgoodsk;
      } else {
        number4n = number3n + 33000 * firstgoodsk;
      }
      skarbadsaas = number4n.toLocaleString();
      setPrice3(skarbadsaas + "원");
      setPrice4(skarbadsaas);
      setDiscount(0);
      $("#discountAmount").css("border-bottom", "solid 2px #b7b7b7");
      setCobtn(couponbtn3);
    } else {
      $.ajax({
        type: "POST",
        url: "https://nametecadmin.kr/checkcoupon",
        data: JSON.stringify({
          coupon_number: text,
        }),
        processData: false,
        contentType: "application/json",
        async: false,
        success: function (data) {
          if (data.status == 200) {
            let askrb = data.data[0].coupon_name;
            if (
              askrb.indexOf("tiny33000") != -1 ||
              askrb.indexOf("ground") != -1 ||
              askrb.indexOf("HAPPY2025") != -1 ||
              askrb.indexOf("wibit") != -1
            ) {
              sessionStorage.setItem("fast", "Y");
              let skakalal = 0;
              skakalal = 33000 * firstgoodsk;
              setDiscount(skakalal.toLocaleString());
              const numbern3 = price2.replace(/,/g, "");
              const number3an = Number(numbern3);
              let number4ns = 0;
              number4ns = number3an - skakalal;
              let skarbadsasas = number4ns.toLocaleString();
              setPrice3(skarbadsasas + "원");
              setPrice4(skarbadsasas);
            }
            let firstgoods = sessionStorage.getItem("firstgoods");
            if (firstgoods == "[]" || firstgoods == "") {
              // alert("asd");
              // setComment("심사기간 단축 선택시 적용되는 쿠폰입니다.");
              // setCobtn(cocencle);
              // $("#couponComment").css("color", "#4a64f5");
              // sessionStorage.setItem("coupon", askrb);
              sessionStorage.setItem("fast", "Y");
              let skakalal1 = 0;
              skakalal1 = 11000 * firstgoodsk;
              setDiscount(skakalal1.toLocaleString());
              const numbern33 = price2.replace(/,/g, "");
              const number3an3 = Number(numbern33);
              let number4ns23 = 0;
              number4ns23 = number3an3 - skakalal1;
              let skarbadsasas = number4ns23.toLocaleString();
              setPrice3(skarbadsasas + "원");
              setPrice4(skarbadsasas);
            }
            setComment("쿠폰이 적용되었습니다.");
            setCobtn(cocencle);
            $("#couponComment").css("color", "#898989");
            sessionStorage.setItem("coupon", askrb);
          } else {
            setCobtn(cobtn);
            setComment("쿠폰이 유효하지 않습니다.");
            $("#couponComment").css("color", "#4a64f5");
          }
        },
      });
    }
  };
  const onKeyUP = (event) => {
    if (event.target.value.length > 0) {
      $("#discountAmount").css("border-bottom", "solid 1px #4a64f5");
      $("#discountAmount").css("color", "#000");
      setCobtn(cobtn);
    } else {
      $("#discountAmount").css("border-bottom", "solid 1px #e0e0e0");
      $("#discountAmount").css("color", "#ccc");
      setCobtn(couponbtn3);
    }
  };
  const onReset = () => {
    setText("");
    setComment("");
    let coupons = sessionStorage.getItem("coupon");
    if (coupons == null) {
    } else {
      const numbern = price2.replace(/,/g, "");
      const number3n = Number(numbern);
      let number4n = 0;
      let firstgoods = sessionStorage.getItem("firstgoods");
      if (firstgoods == "[]" || firstgoods == "") {
        number4n = number3n + 11000 * firstgoodsk;
      } else {
        number4n = number3n + 33000 * firstgoodsk;
      }
      let skarbadsaas = number4n.toLocaleString();
      setPrice3(skarbadsaas + "원");
      setPrice4(skarbadsaas);
      setDiscount(0);
    }

    sessionStorage.removeItem("coupon");
    sessionStorage.setItem("fast", "N");
    $("#discountAmount").css("border-bottom", "solid 2px #b7b7b7");
    setCobtn(couponbtn3);
  };
  function getIndex(listS, valuelist) {
    return listS.findIndex((obj) => obj.valuelist === valuelist);
  }
  const [renderFlag, setRenderFlag] = useState(true);
  const setfirst = (props, successList) => {
    if (successList.valuelist2 === "checkgray") {
      successList.valuelist2 = "checkblue";
      goodsList.push(successList.lastgoods[0]);
      $("#" + successList.lastgoods[0] + "a").css("display", "");
      $("#" + successList.lastgoods[0] + "b").css("display", "");
      $("#" + successList.lastgoods[0] + "c").css("display", "none");
      $("#" + successList.lastgoods[0] + "d").css("display", "");
      $("#" + successList.lastgoods[0] + "e").css("display", "none");
      $("#" + successList.lastgoods[0] + "f").css("display", "");
      const number = price2.replace(/,/g, "");
      const number3 = Number(number);
      let number4 = 0;
      let fast = sessionStorage.getItem("fast");
      let anana = prcount;
      setPrcount((c) => c + 1);
      if (fast == "Y") {
        number4 = 347000 + number3;
        let skakalal = 0;
        skakalal = 33000 * (anana + 1);
        setDiscount(skakalal.toLocaleString());
      } else {
        number4 = 380000 + number3;
      }
      let skarbadsa = number4.toLocaleString();
      setPrice3(skarbadsa + "원");
      setPrice4(skarbadsa);
    } else {
      for (var i = 0; i < goodsList.length; i++) {
        if (goodsList[i] === successList.lastgoods[0]) {
          goodsList.splice(i, 1);
          i--;
        }
      }
      successList.valuelist2 = "checkgray";
      successList.month = month12;
      $("#" + successList.lastgoods[0] + "a").css("display", "none");
      $("#" + successList.lastgoods[0] + "b").css("display", "none");
      $("#" + successList.lastgoods[0] + "c").css("display", "");
      $("#" + successList.lastgoods[0] + "d").css("display", "none");
      $("#" + successList.lastgoods[0] + "e").css("display", "");
      $("#" + successList.lastgoods[0] + "f").css("display", "none");
      const number2 = price2.replace(/,/g, "");
      const number5 = Number(number2);
      let number6 = 0;
      let fast2 = sessionStorage.getItem("fast");
      let anana = prcount;
      setPrcount((c) => c - 1);
      if (fast2 == "Y") {
        let anana = prcount;
        number6 = number5 - 347000;
        let skakalal = 0;
        skakalal = 33000 * (anana - 1);
        setDiscount(skakalal.toLocaleString());
      } else {
        number6 = number5 - 380000;
      }
      let skarbadsa1 = number6.toLocaleString();
      setPrice3(skarbadsa1 + "원");
      setPrice4(skarbadsa1);
    }
    setRenderFlag(!renderFlag);
  };
  useEffect(() => {
    setPrice(price3);
    setPrice2(price4);
    sessionStorage.setItem("price", price3);
  }, [renderFlag, price3]);
  let wholeCount = JSON.parse(sessionStorage.getItem("valuelist3")).length;
  return (
    <div>
      <MobileView>
        <div
          style={{
            margin: "auto",
            maxWidth: "520px",
            fontFamily: "'Noto Sans KR', sans-serif",
          }}
        >
          <TopbarComponentWithoutPage path={"/infomcheck"} />
          <div style={{ height: "5px" }}></div>
          <div style={{ width: "342px", margin: "auto" }}>
            <GuidelineComponent2
              title1={"변리사에게 전달할"}
              title2={"내용을 확인하세요"}
              blue={"."}
              help={"접수 이후에도 변리사의 상담을 통해"}
              help2={"내용을 변경할 수 있습니다."}
            />
          </div>
          <div>
            <div
              style={{
                marginRight: "calc(5% + 64px)",
                float: "right",
                color: "#4a64f5",
                fontSize: "12px",
              }}
            >
              로고
            </div>
            <div
              style={{
                marginLeft: "5%",
                color: "#4a64f5",
                fontSize: "12px",
                marginTop: "25px",
              }}
            >
              상표명
            </div>
            <div
              className="Regular"
              style={{
                textAlign: "center",
                fontSize: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                border: "1px solid rgb(204, 204, 204)",
                width: "83px",
                height: "83px",
                marginRight: "5%",
                float: "right",
                color: "#b7b7b7",
              }}
            >
              {sucproduct2}
            </div>
            <div
              className="Medium"
              id="brandNamea"
              style={{
                marginLeft: "5%",
                color: "#000000",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              {brandname}
            </div>

            <div
              style={{ marginLeft: "5%", color: "#4a64f5", fontSize: "12px" }}
            >
              전화번호
            </div>
            <div
              className="Medium"
              id="lastCheckPhonenumber"
              style={{
                marginLeft: "5%",
                color: "#000000",
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              {customerPhone}
            </div>
            <div
              style={{ marginLeft: "5%", color: "#4a64f5", fontSize: "12px" }}
            >
              이메일
            </div>
            <div
              className="Medium"
              id="lastCheckEmail"
              style={{
                marginBottom: "10px",
                marginLeft: "5%",
                color: "#000000",
                fontSize: "16px",
                marginRight: "5%",
              }}
            >
              {customeremail}
            </div>
            <div
              id=""
              style={{ fontSize: "12px", color: "#4a64f5", marginLeft: "5%" }}
            >
              {" "}
              제품/서비스{" "}
            </div>
            <Successmo2 skarb={valuelistz} setfirst={setfirst} />

            <div
              style={{
                marginTop: "10px",
                fontSize: "14px",
                marginLeft: "5%",
                marginRight: "5%",
                borderBottom: "1px solid #898989",
                paddingBottom: "20px",
              }}
            >
              <div style={{ display: "block" }}>
                <div className="sucin2" style={{ color: "#898989" }}>
                  <p style={{ display: "inline" }}>심사기간 단축 옵션</p>
                  <span
                    className="Regular"
                    style={{ color: "#515151", float: "right" }}
                  >
                    {patentSelect()}
                  </span>
                </div>

                <div className="sucin2" style={{ color: "#898989" }}>
                  <p style={{ display: "inline" }}>상표 출원 예상일</p>
                  <span
                    className="Regular"
                    style={{ color: "#515151", float: "right" }}
                  >
                    {tomorow}
                  </span>
                </div>

                <div className="sucin2" style={{ color: "#898989" }}>
                  <p style={{ display: "inline" }}>심사 결과 통보 예상일</p>
                  <span
                    className="Regular"
                    style={{ color: "#515151", float: "right" }}
                  >
                    {patentMonth()}
                  </span>
                </div>
                <div className="sucin2" style={{ color: "#898989" }}>
                  <p style={{ display: "inline" }}>특허청 관납료</p>
                  <span
                    className="Regular"
                    style={{ color: "#515151", float: "right" }}
                  >
                    {patentPrice()}
                  </span>
                </div>
                <div className="sucin2" style={{ color: "#898989" }}>
                  <p style={{ display: "inline" }}>네임텍 수수료</p>
                  <span
                    className="Regular"
                    style={{ color: "#515151", float: "right" }}
                  >
                    {patentPrice2()}
                  </span>
                </div>
                <div
                  className="sucin2"
                  style={
                    discount == 0 ? { display: "none" } : { color: "#898989" }
                  }
                >
                  <p style={{ display: "inline" }}>프로모션 할인</p>
                  <span
                    className="Regular"
                    style={{ color: "#515151", float: "right" }}
                  >
                    -{discount}원
                  </span>
                </div>
              </div>
            </div>

            <div
              className=""
              id="textconcheck"
              style={{
                width: "calc(100% - 40px)",
                paddingLeft: "3px",
                marginLeft: "5%",
                marginRight: "5%",
              }}
            >
              <div
                style={{
                  color: "#4a64f5",
                  fontSize: "12px",
                  marginTop: "20px",
                  marginBottom: "10px",
                }}
              >
                프로모션 코드
              </div>

              <div style={{ display: "block" }}>
                <input
                  autoComplete="off"
                  type="text"
                  name=""
                  id="discountAmount"
                  onChange={onChange}
                  value={text}
                  onKeyUp={onKeyUP}
                  className="informchecktext Medium"
                  placeholder="코드 입력"
                  style={{
                    border: "none",
                    color: "#cccccc",
                    borderBottom: "solid 1px #e0e0e0",
                  }}
                />

                <img
                  id="cobtn13"
                  src={cobtn1}
                  onClick={applyCoupon}
                  style={{ height: "40px", float: "right" }}
                />
              </div>
              <div
                id="couponComment"
                style={{
                  fontSize: "12px",
                  color: "#4a64f5",
                  marginBottom: "20px",
                }}
              >
                <a>{comment}</a>
              </div>
            </div>
            <div className="res" style={{ maxWidth: "520px" }} id="2222">
              <div
                style={{
                  paddingBottom: "30px",
                  borderBottom: "solid 2px #efefef",
                  paddingTop: "24px",
                }}
              >
                <div
                  id="mas"
                  style={{
                    display: "inline",
                    float: "left",
                    fontSize: "20px",
                    fontWeight: "500",
                    marginLeft: "5%",
                    transform: "translateY(-15px)",
                  }}
                >
                  총계
                </div>
                <div
                  id="res"
                  style={{
                    display: "inline",
                    fontWeight: "500",
                    float: "right",
                    fontSize: "20px",
                    marginRight: "5%",
                    transform: "translateY(-15px)",
                  }}
                >
                  {price}
                </div>
              </div>
            </div>
            <div style={{ height: "150px" }}></div>
            <div
              style={{
                backgroundColor: "#ffffff",
                textAlign: "center",
                width: "100%",
                boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.14)",
                position: "fixed",
                bottom: " 0px",
                maxWidth: "520px",
              }}
              id="1111"
            ></div>
          </div>

          <Link to="/payment">
            <div
              id="next_button"
              className="noto"
              style={{
                cursor: "pointer",
                position: "fixed",
                bottom: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#4a64f5",
              }}
            >
              <div id="dam" style={{ color: "#ffffff" }}>
                {" "}
                출원하기
              </div>
            </div>
          </Link>
        </div>
      </MobileView>
      <BrowserView>
        <div style={{ margin: "0 auto" }}>
          <BrowserHeader />
          <div
            id="disp"
            style={{
              width: "1400px",
              display: "none",
              paddingLeft: "calc(50% - 700px)",
            }}
          >
            <img
              src={ask111}
              style={{
                marginTop: "123px",
                marginLeft: "calc(50% - 602px)",
                width: "690px",
              }}
            />
            <div
              id="askBox33"
              className="askBox33"
              style={{
                border: "1px solid #e0e0e0",
                width: "940px",
                marginLeft: "calc(50% - 492px)",
                marginTop: "50px",
                borderRadius: "10px",
                boxShadow: "1px 1px 4px 0 rgba(0, 0, 0, 0.16)",
                paddingLeft: "50px",
                paddingTop: "50px",
                paddingBottom: "40px",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  width: "300px",
                  position: "absolute",
                }}
              >
                <div
                  className="Regular"
                  style={{
                    marginLeft: "5%",
                    color: "#4a64f5",
                    fontSize: "18px",
                  }}
                >
                  상표명
                </div>

                <div
                  className="Medium"
                  id="brandNamea"
                  style={{
                    marginLeft: "5%",
                    color: "#000000",
                    fontSize: "22px",
                    marginBottom: "10px",
                  }}
                >
                  {brandname}
                </div>

                <div
                  className="Regular"
                  style={{
                    marginTop: "12px",
                    marginLeft: "5%",
                    color: "#4a64f5",
                    fontSize: "18px",
                  }}
                >
                  전화번호
                </div>
                <div
                  className="Medium"
                  id="lastCheckPhonenumber"
                  style={{
                    marginLeft: "5%",
                    color: "#000000",
                    fontSize: "22px",
                    marginBottom: "10px",
                  }}
                >
                  {customerPhone}
                </div>
                <div
                  className="Regular"
                  style={{
                    marginTop: "12px",
                    marginLeft: "5%",
                    color: "#4a64f5",
                    fontSize: "18px",
                  }}
                >
                  이메일
                </div>
                <div
                  className="Medium"
                  id="lastCheckEmail"
                  style={{
                    marginBottom: "10px",
                    marginLeft: "5%",
                    color: "#000000",
                    fontSize: "22px",
                    paddingBottom: "18px",
                  }}
                >
                  {customeremail}
                </div>
                <div
                  className="Regular"
                  style={{
                    marginTop: "12px",
                    marginLeft: "5%",
                    color: "#4a64f5",
                    fontSize: "18px",
                  }}
                >
                  로고
                </div>
                <div
                  className="Medium"
                  style={{
                    textAlign: "center",
                    marginTop: "5px",
                    border: "1px solid rgb(204, 204, 204)",
                    width: "140px",
                    height: "140px",
                    marginLeft: "5%",
                    color: "#b7b7b7",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {product3}
                </div>
              </div>

              <div
                style={{
                  display: "inline-block",
                  marginLeft: "300px",
                  width: "600px",
                }}
              >
                <div
                  id=""
                  className="Regular"
                  style={{
                    fontSize: "18px",
                    color: "#4a64f5",
                    marginLeft: "5%",
                    marginBottom: "5px",
                  }}
                >
                  {" "}
                  제품/서비스{" "}
                </div>
                <Successmo skarb={valuelistz} setfirst={setfirst} />

                <div style={{ marginTop: "5%", fontSize: "14px" }}>
                  <div
                    className="in2"
                    style={{
                      margin: "0px 17px 10px 0px",
                      color: "#515151",
                      display: "inline-block",
                      width: "90%",
                      paddingBottom: "20px",
                      marginLeft: "5%",
                      borderBottom: "1px solid #898989",
                    }}
                  >
                    <div className="sucin2" style={{ color: "#898989" }}>
                      <p
                        className="Regular"
                        style={{ display: "inline", fontSize: "19px" }}
                      >
                        심사기간 단축 옵션{" "}
                      </p>
                      <span
                        className=" Regular"
                        style={{
                          color: "#515151",
                          fontSize: "19px",
                          float: "right",
                        }}
                      >
                        {patentSelect()}
                      </span>
                    </div>
                    <div
                      className="sucin2"
                      style={{ color: "#898989", marginTop: "7px" }}
                    >
                      <p
                        className="Regular"
                        style={{ display: "inline", fontSize: "19px" }}
                      >
                        상표 출원 예상일
                      </p>
                      <span
                        className=" Regular"
                        style={{
                          fontSize: "19px",
                          color: "#515151",
                          float: "right",
                        }}
                      >
                        {tomorow}
                      </span>
                    </div>

                    <div
                      className="sucin2"
                      style={{ color: "#515898989151", marginTop: "7px" }}
                    >
                      <p
                        className="Regular"
                        style={{ display: "inline", fontSize: "19px" }}
                      >
                        심사 결과 통보 예상일{" "}
                      </p>
                      <span
                        className=" Regular"
                        style={{
                          color: "#515151",
                          fontSize: "19px",
                          float: "right",
                        }}
                      >
                        {patentMonth()}
                      </span>
                    </div>
                    <div
                      className="sucin2"
                      style={{ color: "#898989", marginTop: "7px" }}
                    >
                      <p
                        className="Regular"
                        style={{ display: "inline", fontSize: "19px" }}
                      >
                        특허청 관납료
                      </p>
                      <span
                        className=" Regular"
                        style={{
                          color: "#515151",
                          fontSize: "19px",
                          float: "right",
                        }}
                      >
                        {patentPrice()}
                      </span>
                    </div>
                    <div
                      className="sucin2"
                      style={{ color: "#898989", marginTop: "7px" }}
                    >
                      <p
                        className="Regular"
                        style={{ display: "inline", fontSize: "19px" }}
                      >
                        네임텍 수수료
                      </p>
                      <span
                        className=" Regular"
                        style={{
                          color: "#515151",
                          fontSize: "19px",
                          float: "right",
                        }}
                      >
                        {patentPrice2()}
                      </span>
                    </div>
                    <div
                      className="sucin2"
                      style={
                        discount == 0
                          ? { display: "none" }
                          : { color: "#898989", marginTop: "7px" }
                      }
                    >
                      <p
                        className="Regular"
                        style={{ display: "inline", fontSize: "19px" }}
                      >
                        프로모션 할인
                      </p>
                      <span
                        className=" Regular"
                        style={{
                          color: "#515151",
                          fontSize: "19px",
                          float: "right",
                        }}
                      >
                        -{discount}원
                      </span>
                    </div>
                  </div>
                  <div
                    className="sucin211"
                    style={{
                      marginTop: "2px",
                      marginLeft: "5%",
                      marginRight: "5%",
                    }}
                  >
                    <p
                      className="Medium"
                      style={{ display: "inline", fontSize: "19px" }}
                    >
                      총계
                    </p>
                    <span
                      className=" Medium"
                      style={{ fontSize: "19px", float: "right" }}
                    >
                      {price2}원
                    </span>
                  </div>
                </div>

                <div
                  className=""
                  id="textconcheck"
                  style={{ width: "calc(100% - 40px)", margin: "14px 0 0 5%" }}
                >
                  <div
                    className="Regular"
                    style={{
                      color: "#4a64f5",
                      fontSize: "19px",
                      marginTop: "60px",
                      marginBottom: "10px",
                    }}
                  >
                    프로모션 코드
                  </div>
                  <div style={{ display: "block" }}>
                    <input
                      autoComplete="off"
                      type="text"
                      name=""
                      id="discountAmount"
                      onChange={onChange}
                      value={text}
                      onKeyUp={onKeyUP}
                      className="informchecktext2 Medium"
                      placeholder="코드 입력"
                      style={{
                        opacity: "0.7",
                        border: "none",
                        color: "#000000",
                        borderBottom: "solid 2px #e0e0e0",
                      }}
                    />
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "9px",
                        marginLeft: "-30px",
                        position: "absolute",
                      }}
                      onClick={onReset}
                      src={x_round}
                    />
                    <img
                      id="cobtn13"
                      src={cobtn1}
                      onClick={applyCoupon}
                      style={{
                        width: "120px",
                        height: "40px",
                        position: "absolute",
                      }}
                    />
                  </div>
                  <div
                    id="couponComment"
                    style={{
                      fontSize: "12px",
                      color: "#898989",
                      marginBottom: "20px",
                    }}
                  >
                    <a>{comment}</a>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: "300px" }}></div>
          </div>
          <div
            id="introFooter"
            style={{
              float: "right",
              backgroundColor: "#f5f6ff",
              height: "116px",
              lineHeight: "110px",
            }}
          >
            <Link to="/payment">
              <img
                src={new_blue}
                style={{
                  cursor: "pointer",
                  width: "200px",
                  marginLeft: "20px",
                  marginTop: "27px",
                  float: "right",
                  marginRight: "calc(50% - 485px)",
                }}
              />
            </Link>
            <a href="http://pf.kakao.com/_lWuhs/chat">
              <img
                src={kakaoaa}
                style={{
                  cursor: "pointer",
                  width: "283px ",
                  marginTop: "27px",
                  float: "right",
                }}
              />
            </a>
            <div
              className="Bold1"
              style={{ fontSize: "30px", marginLeft: "calc(50% - 485px)" }}
            >
              <a>
                총 <a style={{ color: "#4a64f5" }}>{wholeCount}</a>건 /{" "}
                <a style={{ color: "#4a64f5" }}>{price2}</a>원
              </a>
            </div>
          </div>
        </div>
      </BrowserView>
    </div>
  );
}

export default Success3;
