import React, { useState, useEffect, useRef } from "react";
import "../style.css";
import styled, { keyframes } from "styled-components";
import FadeBanner from "../components/FadeBanner";
import FadeBanner2 from "../components/FadeBanner2";
import arrowdown from "../data/arrowdown@3x.png";
import arrowup from "../data/arrowup@3x.png";
import MainHeader from "../components/MainHeader";
import pc_brandsearch_bt from "../image/pc_brandsearch_bt.png";
import mo_brandsearch_bt from "../image/mo_brandsearch_bt.png";
import BrowserHeader from "../components/BrowserHeader";
import start_butto3n from "../image/start_butto3n.png";
import m_main_process1_2 from "../image/m_main_process1-2.png";
import m_main_process2_2 from "../image/m_main_process2-2.png";
import patent_introduce from "../image/patent_introduce.png";
import m_jeong from "../image/m_jeong.png";
import m_kim from "../image/m_kim.png";
import "../text.css";
import phone_mockup from "../image/phone_mockup.png";
import m_main_receipt from "../image/m_main_receipt.png";
import m_main_mock from "../image/sitemockup_mo.png";
import main_service from "../image/benefit_pc.png";
import main_price from "../image/main_price.png";
import main_mock from "../image/sitemockup_pc.png";
import main_progress18 from "../image/znznz.png";
import ScrollBanner from "../components/ScrollBanner";
import ScrollBanner2 from "../components/ScrollBanner2";
import main_process17 from "../image/main_process17.png";
import main_office from "../image/main_office.png";
import price1_pc from "../image/price1_pca.png";
import main_tag from "../image/main_tag.png";
import m_main_service from "../image/benefit_mo.png";
import main_receipt from "../image/main_receipt.png";
import price2_pc from "../image/price2_pc.png";
import plus_pc from "../image/plus_pc.png";
import laptop from "../image/laptop.png";
import price2_mo from "../image/price2_mo.png";
import adsdsazxz from "../image/adsdsazxz.png";
import plus_mo from "../image/plus_mo.png";
import price1_mo from "../image/price1_mo3.png";
import logo1 from "../image/sample1.png";
import logo2 from "../image/sample2.png";
import logo3 from "../image/sample3.png";
import logo4 from "../image/sample4.png";
import logo5 from "../image/logo5.png";
import logo6 from "../image/logo6.png";
import logo7 from "../image/logo7.png";
import logo8 from "../image/logo8.png";
import logo9 from "../image/logo9.png";
import logo10 from "../image/logo10.png";
import logo11 from "../image/logo11.png";
import logo12 from "../image/logo12.png";
import logo13 from "../image/logo13.png";
import logo14 from "../image/logo14.png";
import remockup from "../image/remockup.png";
import icon_min from "../image/icon_min@2x.png";
import icon_kakao from "../image/icon_kakao@2x.png";
import main_3dbadge from "../image/main_case.png";
import service from "../image/service.png";
import xz21sd from "../image/xz21sd.png";
import m_main_process1 from "../image/m_main_process1.png";
import m_main_process2 from "../image/m_main_process2.png";
import button1 from "../image/button1.png";
import adasdasdasd from "../image/adasdasdasd.png";
import start_button123 from "../image/start_button123.png";
import moman from "../image/st312art.png";
import pcman from "../image/pc-man.png";
import button2 from "../image/button2.png";
import button3 from "../image/button3.png";
import button4 from "../image/button4.png";
import zxcw132 from "../image/zxcw132.png";
import zxcsqe123 from "../image/zxcsqe123.png";
import bl1 from "../image/bl1@.png";
import icon_ai from "../image/icon_ai@2x.png";
import b22x from "../image/b2@2x.png";
import icon_phone from "../image/icon_phone@2x.png";
import icon_insta from "../image/icon_insta@2x.png";
import icon_naver from "../image/icon_naver.png";
import start_button from "../image/start_button@3x.png";
import start_button2 from "../image/start_button2@3x.png";
import kakaoBtn from "../image/kakaoBtn.png";
import kakaoBtn2 from "../image/kakaoBtn2.png";
import mainimg from "../image/mainimg.png";
import kim4 from "../image/mo_kim.png";
import mo_je from "../image/mo_je.png";
import comparison from "../image/comparison.png";
import process from "../image/process.png";
import mfb from "../image/mfb.png";
import price1 from "../image/price.png";
import unni from "../image/unni.png";
import hana from "../image/hana.png";
import price from "../image/price.png";
import pagearrow from "../image/pagearrow.png";
import smart from "../image/smart.png";
import group from "../image/group.png";
import progressaa from "../image/progressaa.png";
import companyaaa from "../image/companyaaa.png";
import pc_je from "../image/pc_je.png";
import pc_kim from "../image/pc_kim.png";
import zxczxz from "../image/11111.png";
import kimzzz from "../image/kimzzz.png";
import pcprom from "../image/pc_pro.png";
import zxzxn from "../image/amamam.png";
import BrowserHeader2 from "../components/BrowserHeader2";
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  IsMobile,
  TabletView,
} from "react-device-detect";
import $ from "jquery";
import { useLocation } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";

const PIXEL_ID = "266266868791844";
ReactPixel.init(PIXEL_ID);
ReactPixel.pageView();

function Intro() {
  const Dropdown = styled.div`
    display: flex;
    justify-content: space-between;
    width: 60%;
    height: 60px;
    margin: 20px auto 20px auto;
    padding: 11px 15.5px 11px 15px;
    border: solid 1px #e0e0e0;
    background-color: "#fafafa";
    box-shadow: rgba(0, 0, 0, 0.5);
    border-radius: 5px;

    > .dropdowntxt-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > .dropdown-txt {
        padding: 0 10px 0 0;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }

      > .dropdown-help {
        font-size: 16px;
        font-stretch: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }
    }

    > .dropdown-arrow {
      justify-self: flex-end;
      margin: 22px 0;
      width: 20px;
      height: 10px;
    }
  `;

  const DropdownContent = styled.div`
    width: 335px;
    height: 283px;
    padding: 20px 15.5px 20px 15px;
    margin: 0px 20px 0px 20px;
    border: solid 1px #e0e0e0;
    background-color: #fafafa;
    display: ${(props) => (props.click ? "block" : "none")};

    > .dropdowncontent {
      width: 335px;
      height: 54px;
      margin: 0 0 6px;
      font-family: NotoSansCJKKR;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #515151;
    }
  `;

  const [click1, setClick1] = useState(false);
  const [click2, setClick2] = useState(false);
  const [width, setWidth] = useState("70vw");

  const bottom = useRef(null);

  const clickHandler1 = () => {
    setClick1(!click1);
  };

  const clickHandler2 = () => {
    setClick2(!click2);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const widthHandler = () => {
    if (width > 540) {
      width = 462;
    } else {
      width = "70vw";
    }
  };
  const getUrlParams = () => {
    var params = {};
    window.location.search.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function (str, key, value) {
        params[key] = value;
      }
    );
    return params;
  };
  const NaverLoginStart = () => {
    if (window.location.hash == "") {
    } else {
      let naver_access_token = window.location.href.split("=")[1].split("&")[0];
      var utmRoute = sessionStorage.getItem("utmRoute");
      $.ajax({
        url: "https://nametecadmin.kr/getNaverProfile",
        type: "post",
        data: JSON.stringify({
          token: naver_access_token,
          utmRoute: utmRoute,
        }),
        contentType: "application/json; charset=utf-8",
        dataType: "JSON",
        crossDomain: true,
        success: function (data1) {
          if (data1.status == 200) {
            var profileData = data1;
            let timestampSecond = Math.floor(+new Date() / 1000);
            localStorage.setItem("timestamp", timestampSecond);
            localStorage.setItem("customeremail", profileData["email"]);
            localStorage.setItem("customerPhone", profileData["mobile"]);
            localStorage.setItem("customername", profileData["name"]);
            localStorage.setItem("customerToken", naver_access_token);
            localStorage.setItem("customerClass", profileData["class"]);
            localStorage.setItem("real_email", profileData["real_email"]);
            window.location.replace("/");
          }
          if (data1.status == 401) {
            alert("ssd");
          }
        },
        error: function (request, status, error) {},
      });
    }
  };
  const [pro1, setPro1] = useState(m_main_process1);
  const [width1, setWidth1] = useState(340);
  const [pro2, setPro2] = useState(m_main_process2);
  const [changeImg1, setChangeImg1] = useState("calc");
  const [changeImg2, setChangeImg2] = useState("calc");
  const [changeText1, setChangeText1] = useState("View more");
  const [changeText2, setChangeText2] = useState("View more");
  const changePic1 = () => {
    if (pro1 == m_main_process1) {
      setPro1(m_main_process1_2);
      setChangeText1("");
      setChangeImg1("changeImg");
    } else {
      setPro1(m_main_process1);
      setChangeText1("View more");
      setChangeImg1("calc");
    }
  };
  const changePic2 = () => {
    if (pro2 == m_main_process2) {
      setPro2(m_main_process2_2);
      setChangeText2("");
      setChangeImg2("changeImg");
      setWidth1(340);
    } else {
      setPro2(m_main_process2);
      setChangeText2("View more");
      setChangeImg2("calc");
      setWidth1(340);
    }
  };
  const texts2 = [
    "2022년 6월 29일 등록 완료",
    "2024년 6월 14일 등록 완료",
    "2022년 9월 2일 등록 완료",
    "2023년 10월 19일 등록 완료",
    "2023년 10월 16일 등록 완료",
    "2023년 10월 12일 등록 완료",
    "2024년 4월 4일 등록 완료",
    "2024년 5월 20일 등록 완료",
    "2024년 9월 9일 등록 완료",
    "2024년 6월 12일 출원 완료",
    "2024년 6월 26일 출원 완료",
    "2024년 7월 8일 출원 완료",
    "2024년 7월 16일 출원 완료",
    "2024년 7월 31일 출원 완료",
  ];
  const texts3 = [
    "출원 및 등록을",
    "출원 및 등록을",
    "출원 및 등록을",
    "출원 및 등록을",
    "출원 및 등록을",
    "출원 및 등록을",
    "출원 및 등록을",
    "출원 및 등록을",
    "출원 및 등록을",
    "출원을",
    "출원을",
    "출원을",
    "출원을",
    "출원을",
  ];
  const [currentIndex2, setCurrentIndex2] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex2((prevIndex) => (prevIndex + 1) % texts2.length);
    }, 3000); // 5초마다 텍스트 변경

    return () => clearInterval(intervalId); // 컴포넌트가 언마운트될 때 interval 해제
  }, [texts2.length]);

  const texts = [
    "‘mfb’",
    "‘리이제’",
    "‘요미야미’",
    "‘기노스코’",
    "‘백미별장’",
    "TONZ",
    "‘빌리브모어’",
    "‘초록은하수’",
    "‘언어VISION’",
    "‘NEWHOW’",
    "‘POSTGO’",
    "‘The ONE Barista & Baking Academy’",
    "‘데생헤어’",
    "‘애플망고1947’",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000); // 5초마다 텍스트 변경

    return () => clearInterval(intervalId); // 컴포넌트가 언마운트될 때 interval 해제
  }, [texts.length]);

  useEffect(() => {
    NaverLoginStart();
    let customeremail1 = localStorage.getItem("customeremail");
  }, []);
  const banners = [
    <img
      style={{ width: "300px", height: "150px", marginLeft: "-10px" }}
      src={logo1}
      alt="Banner 1"
    />,
    <img
      style={{ width: "280px", height: "150px" }}
      src={logo2}
      alt="Banner 2"
    />,
    <img
      style={{ width: "300px", height: "150px", marginLeft: "-10px" }}
      src={logo3}
      alt="Banner 3"
    />,
    <img
      style={{ width: "280px", height: "150px" }}
      src={logo4}
      alt="Banner 4"
    />,
    <img style={{ width: "280px" }} src={logo5} alt="Banner 5" />,
    <img style={{ width: "280px" }} src={logo6} alt="Banner 6" />,
    <img style={{ width: "280px" }} src={logo7} alt="Banner 7" />,
    <img style={{ width: "280px" }} src={logo8} alt="Banner 8" />,
    <img style={{ width: "280px" }} src={logo9} alt="Banner 9" />,
    <img style={{ width: "280px" }} src={logo10} alt="Banner 10" />,
    <img style={{ width: "280px" }} src={logo11} alt="Banner 11" />,
    <img style={{ width: "280px" }} src={logo12} alt="Banner 12" />,
    <img style={{ width: "280px" }} src={logo13} alt="Banner 13" />,
    <img style={{ width: "280px" }} src={logo14} alt="Banner 14" />,
  ];
  const boxFade = keyframes`
0% {
    -webkit-transform:  translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform:  translate(0px, 20px);
    opacity: 0;
  }
`;
  const Box = styled.div`
    animation: ${boxFade} 1.5s infinite;
  `;
  const banners2 = [
    <img
      style={{ width: "170px", height: "85px" }}
      src={logo1}
      alt="Banner 1"
    />,
    <img
      style={{ width: "170px", height: "85px" }}
      src={logo2}
      alt="Banner 2"
    />,
    <img
      style={{ width: "170px", height: "85px" }}
      src={logo3}
      alt="Banner 3"
    />,
    <img
      style={{ width: "170px", height: "85px" }}
      src={logo4}
      alt="Banner 4"
    />,
    <img style={{ width: "170px" }} src={logo5} alt="Banner 5" />,
    <img style={{ width: "170px" }} src={logo6} alt="Banner 6" />,
    <img style={{ width: "170px" }} src={logo7} alt="Banner 7" />,
    <img style={{ width: "170px" }} src={logo8} alt="Banner 8" />,
    <img style={{ width: "170px" }} src={logo9} alt="Banner 9" />,
    <img style={{ width: "170px" }} src={logo10} alt="Banner 10" />,
    <img style={{ width: "170px" }} src={logo11} alt="Banner 11" />,
    <img style={{ width: "170px" }} src={logo12} alt="Banner 12" />,
    <img style={{ width: "170px" }} src={logo13} alt="Banner 13" />,
    <img style={{ width: "170px" }} src={logo14} alt="Banner 14" />,
  ];
  const boxFade2 = keyframes`
0% {
  -webkit-transform:  translate(0, 0);
  opacity: 0;
}
50% {
  opacity: 1;
}
100% {
  -webkit-transform:  translate(0px, 20px);
  opacity: 0;
}
`;
  const Box2 = styled.div`
    animation: ${boxFade2} 1.5s infinite;
  `;
  let customeremail1 = localStorage.getItem("customeremail");

  return (
    <div
      style={{
        colorScheme: "light",
        backgroundColor: "white !important",
        color: "black !important",
      }}
    >
      <MobileView>
        <div style={{ margin: "0 auto", maxWidth: "520px", lineHeight: "1.5" }}>
          <MainHeader />

          <div>
            <div>
              <div
                className="Bold2"
                style={{
                  fontSize: "34px",
                  textAlign: "center",
                  marginTop: "50px",
                  lineHeight: "1.3",
                }}
              >
                <a style={{ color: "#4a64f5" }}>추가 수수료 없는 </a>
                <br />
                원스톱 상표등록
              </div>
              <div
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  marginTop: "30px",
                  lineHeight: "1.6",
                }}
              >
                <a className="Light1">
                  상표등록 수수료 딱 한번만 내면 상담, 검토,
                  <br />
                  서류 대응, 심사 거절 시 재출원까지 모두 제공 :)
                </a>
              </div>
            </div>
            <img
              src={phone_mockup}
              style={{
                width: "204px",
                marginTop: "35px",
                marginLeft: "calc(50% - 102px)",
              }}
            />
            <div
              style={{
                position: "relative",
                marginBottom: "20px",
                margin: "auto",
                borderRadius: "10px",
              }}
            >
              <div style={{ display: "inline-block", marginTop: "27px" }}>
                {customeremail1 === null ? (
                  <Link to="/login">
                    <button
                      className="Bold1 btn"
                      type="button"
                      id="start_btn4"
                      style={{ position: "absolute", cursor: "pointer" }}
                    >
                      상표등록 시작하기
                    </button>
                  </Link>
                ) : (
                  <Link to="/brandregister" id="brandtag">
                    <button
                      className="Bold1 btn"
                      type="button"
                      id="start_btn4"
                      style={{ position: "absolute", cursor: "pointer" }}
                    >
                      상표등록 시작하기
                    </button>
                  </Link>
                )}
                <ScrollBanner2 />
              </div>
            </div>

            <Link to="/brandsearch" style={{ textDecoration: "none" }}>
              <img
                src={mo_brandsearch_bt}
                style={{
                  width: "300px",
                  display: "inline-block",
                  marginTop: "55px",
                  marginLeft: "calc(50% - 150px)",
                }}
              />
            </Link>
            <img
              src={main_3dbadge}
              style={{
                width: "262px",
                display: "inline-block",
                marginTop: "60px",
                marginLeft: "calc(50% - 131px)",
              }}
            />
          </div>

          <div
            id="bottom"
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "30px",
              backgroundColor: "#edeffe",
              textAlign: "center",
              borderRadius: "30px",
              paddingBottom: "39px",
              boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.29)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={main_tag} style={{ width: "200px" }} />
            <FadeBanner2 banners={banners2} />

            <div
              style={{
                borderRadius: " 30px",
                width: "300px",
                paddingTop: "10px",
                lineHeight: "1.6",
                height: "115px",
                marginTop: "35px",
                backgroundColor: "#fff",
                boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div
                className="Medium"
                style={{ marginTop: "6px", fontSize: "14px" }}
              >
                <a style={{ color: "#4a64f5" }}>{texts[currentIndex]}</a>{" "}
                고객님은 <br />
                네임텍상표에서 상표 {texts3[currentIndex]}
                <br />
                진행했습니다 :)
              </div>
              <div>
                <a style={{ color: "#898989", fontSize: "14px" }}>
                  {texts2[currentIndex]}
                </a>
              </div>
            </div>
          </div>

          <div
            className="Bold"
            style={{ marginTop: "60px", textAlign: "center", fontSize: "28px" }}
          >
            네임텍상표, 뭐가 다른가요<a style={{ color: "#4a64f5" }}>?</a>
          </div>

          <div
            id="bottom"
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "30px",
              paddingTop: "60px",
              backgroundColor: "#fafafa",
              textAlign: "center",
              borderRadius: "30px",
              boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.29)",
            }}
          >
            <a className="Bold" style={{ fontSize: "28px" }}>
              언제, 어디서든, 누구나
              <br />
              쉽고 빠른 비대면 신청
            </a>
            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              <a className="Light" style={{ fontSize: "14px" }}>
                네임텍상표는 <a className="Medium">스마트폰, 노트북</a>을 통해{" "}
                <br />
                <a className="Medium">언제, 어디서든, 비대면으로</a>
                <br />
                상표출원 서비스를 신청하실 수 있습니다.
              </a>
            </div>
            <a className="Light" style={{ fontSize: "14px" }}>
              카카오톡, 네이버 계정으로 <a className="Medium">10초만에 가입</a>
              하고
              <br />
              <a className="Medium">3분 안에 모든 신청을 완료</a>하실 수
              있습니다.
            </a>

            <img
              src={m_main_mock}
              style={{
                width: "300px",
                marginTop: "76px",
                marginBottom: "60px",
              }}
            />
          </div>

          <div
            id="bottom"
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "30px",
              paddingTop: "60px",
              backgroundColor: "#fafafa",
              textAlign: "center",
              borderRadius: "30px",
              boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.29)",
            }}
          >
            <a className="Bold" style={{ fontSize: "28px" }}>
              최소 비용으로
              <br />
              최대 서비스 제공
            </a>

            <div style={{ marginTop: "30px", marginBottom: "30px" }}>
              <a className="Light" style={{ fontSize: "14px" }}>
                저렴한 비용의 상표출원 서비스는
                <br />
                자세히 알아보면
                <a className="Medium">
                  {" "}
                  추가 비용이 계속 붙어
                  <br />
                  결국 큰 금액
                </a>
                이 되는 경우가 많습니다.
              </a>
            </div>

            <div style={{ marginBottom: "30px" }}>
              <a className="Light" style={{ fontSize: "14px" }}>
                네임텍 상표는 수수료를 최초에 딱 한 번만 받고
                <br />
                <a className="Medium">
                  꼭 필요한 서비스를 추가비용 없이 모두 제공합니다.
                </a>
              </a>
            </div>

            <div style={{ marginBottom: "30px" }}>
              <a className="Light" style={{ fontSize: "14px" }}>
                또한, 신청 후 마음이 바뀌어 취소하더라도
                <br />
                <a className="Medium" style={{ color: "#4a64f5" }}>
                  특허청 접수 직전까지는 취소 수수료 없이
                  <br />
                  100% 환불이 가능
                </a>
                하기 때문에
                <br />
                출원 신청 후,{" "}
                <a className="Medium">
                  1:1 변리사 상담 단계에서
                  <br />
                  출원 여부를 최종 결정
                </a>
                하시면 됩니다.
              </a>
            </div>

            <div style={{ marginBottom: "30px" }}>
              <a className="Medium" style={{ fontSize: "14px" }}>
                출원에 실패하면,
                <a style={{ color: "#4a64f5" }}>
                  추가 관납료만 받고
                  <br />될 때 까지 재출원
                </a>
                <a className="Light">해드립니다.</a>
              </a>
            </div>

            <img
              src={m_main_receipt}
              style={{
                width: "318px",
                marginTop: "66px",
                marginBottom: "60px",
              }}
            />
          </div>

          <div
            id="bottom"
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "30px",
              paddingTop: "60px",
              backgroundColor: "#fafafa",
              textAlign: "center",
              borderRadius: "30px",
              boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.29)",
            }}
          >
            <a className="Bold" style={{ fontSize: "28px" }}>
              빈틈없이 꼼꼼하게
              <br />
              챙겨드리는 서비스
            </a>
            <div style={{ marginTop: "30px" }}>
              <a className="Light" style={{ fontSize: "14px" }}>
                서비스 수수료는 저렴하지만
                <br />
                빈틈없는 서비스를 제공합니다.
              </a>
            </div>
            <div style={{ marginTop: "30px" }}>
              <a className="Light" style={{ fontSize: "14px" }}>
                <a className="Medium" style={{ color: "#4a64f5" }}>
                  신청하시는 모든 고객님과
                  <br />
                  변리사 1:1 상담 및 상세 검토
                </a>
                를<br />
                진행해 안전하게 출원합니다.
              </a>
            </div>

            <div style={{ marginTop: "30px" }}>
              <a className="Light" style={{ fontSize: "14px" }}>
                또한, 출원부터 등록이 끝나는 시점까지
                <br />
                <a className="Medium">
                  의견 대응 등의 모든 서류처리를
                  <br />
                </a>
                <a className="Medium" style={{ color: "#4a64f5" }}>
                  추가 비용 없이 진행
                </a>
                해드립니다.
              </a>
            </div>

            <img
              src={m_main_service}
              style={{
                width: "301px",
                marginTop: "66px",
                marginBottom: "60px",
              }}
            />
          </div>

          <div
            className="Bold"
            style={{ marginTop: "60px", textAlign: "center", fontSize: "28px" }}
          >
            상표등록 비용이 궁금해요<a style={{ color: "#4a64f5" }}>!</a>
          </div>

          <div
            id="bottom"
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "30px",
              paddingTop: "60px",
              backgroundColor: "#f5f6ff",
              textAlign: "center",
              borderRadius: "30px",
              boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.29)",
              paddingBottom: "60px",
            }}
          >
            <div className="Bold" style={{ fontSize: "28px" }}>
              일반 상표출원 시<br />
              납부 금액
            </div>

            <div style={{ textAlign: "center" }}>
              <img
                src={main_price}
                style={{ width: "301px", marginTop: "29px" }}
              />
            </div>

            <div
              class="Light1"
              style={{
                fontSize: "14px",
                lineHeight: "1.6",
                marginTop: " 30px",
                textAlign: "center",
                color: "#898989",
              }}
            >
              <a className="Medium" style={{ color: "#000" }}>
                *특허청 관납료
              </a>
              는 네임텍 수수료와 별개로
              <br />
              출원 시{" "}
              <a className="Medium" style={{ color: "#000" }}>
                특허청에 필수로 납부하는 금액
              </a>
              입니다.
            </div>

            <div
              class="Light1"
              style={{
                fontSize: "14px",
                lineHeight: "1.6",
                marginTop: " 30px",
                textAlign: "center",
                color: "#898989",
              }}
            >
              <a className="Medium" style={{ color: "#000" }}>
                *심사기간 단축옵션 , 최종 상표등록 관납료
              </a>{" "}
              등<br />
              더욱 상세한 비용은{" "}
              <a className="Medium" style={{ color: "#000" }}>
                아래 링크에서 확인
              </a>
              하세요.
            </div>

            <div
              className="Light"
              style={{ marginTop: "30px", textAlign: "center" }}
            >
              <a
                href="/costprocess"
                style={{ color: "#4a64f5", fontSize: "14px" }}
              >
                + 더 자세히 알아보기(click)
              </a>
            </div>
          </div>

          <div
            className="Bold"
            style={{ marginTop: "60px", textAlign: "center", fontSize: "28px" }}
          >
            상표등록 과정이 궁금해요<a style={{ color: "#4a64f5" }}>!</a>
          </div>

          <div
            id="bottom"
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "30px",
              paddingTop: "60px",
              backgroundColor: "#fafafa",
              textAlign: "center",
              borderRadius: "30px",
              boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.16)",
            }}
          >
            <div className="Bold" style={{ fontSize: "28px" }}>
              상표등록 신청 과정 안내
            </div>

            <div style={{ textAlign: "center" }}>
              <img src={pro1} style={{ width: "340px", marginTop: "49px" }} />
            </div>

            <div style={{ marginTop: "20px" }}>
              <div>
                <a className="Medium" style={{ fontSize: "20px" }}>
                  {changeText1}
                </a>
              </div>
              <div style={{ marginTop: "10px" }}>
                <img
                  onClick={changePic1}
                  className={changeImg1}
                  src="https://nametecadmin.kr/static/image/zxczxc.png"
                  style={{
                    marginTop: "10px",
                    width: "30px",
                    paddingBottom: "30px",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            id="bottom"
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "30px",
              paddingTop: "60px",
              backgroundColor: "#fafafa",
              textAlign: "center",
              borderRadius: "30px",
              boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.16)",
            }}
          >
            <div className="Bold" style={{ fontSize: "28px" }}>
              신청 이후의 등록 과정 안내
            </div>

            <div style={{ textAlign: "center" }}>
              <img
                src={pro2}
                style={{ width: `${width1}px`, marginTop: "49px" }}
              />
            </div>

            <div style={{ marginTop: "20px" }}>
              <div>
                <a className="Medium" style={{ fontSize: "20px" }}>
                  {changeText2}
                </a>
              </div>

              <div style={{ marginTop: "10px" }}>
                <img
                  onClick={changePic2}
                  className={changeImg2}
                  src="https://nametecadmin.kr/static/image/zxczxc.png"
                  style={{
                    marginTop: "10px",
                    width: "30px",
                    paddingBottom: "30px",
                  }}
                />
              </div>
            </div>
          </div>

          <div
            className="Bold"
            style={{ marginTop: "60px", textAlign: "center", fontSize: "28px" }}
          >
            네임텍 소개<a style={{ color: "#4a64f5" }}>.</a>
          </div>

          <div
            id="bottom"
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "30px",
              paddingTop: "60px",
              backgroundColor: "#f5f6ff",
              textAlign: "center",
              borderRadius: "30px",
              boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.16)",
              paddingBottom: "60px",
            }}
          >
            <div style={{ textAlign: "center", lineHeight: "1.6" }}>
              <a className="Bold" style={{ fontSize: "28px" }}>
                네임텍은 어떤 곳인가요?
              </a>
              <div style={{ fontSize: "14px" }}>
                <div style={{ marginTop: "27px", marginBottom: "30px" }}>
                  <a className="Medium" style={{ fontSize: "14px" }}>
                    네임텍
                  </a>
                  <a className="Light">
                    은 혁신적인 기술과
                    <br />
                    창의적인 아이디어를 가진 개인 및 기업들을 위해
                  </a>
                </div>

                <div style={{ marginBottom: "30px" }}>
                  <a className="Light" style={{ fontSize: "14px" }}>
                    상표, 특허 출원을 통해 클라이언트들의
                    <br />
                    아이디어를 안전하게 보호하고,
                    <br />
                    성공적으로 상업화할 수 있도록
                    <br />
                    도와 드리는 것을 목표로 삼아
                  </a>
                </div>
                <div style={{ marginBottom: "30px" }}>
                  <a className="Medium" style={{ fontSize: "14px" }}>
                    이용하기 쉽고 편리하면서도
                    <br />
                    성공률 높은 출원 서비스
                    <a className="Light">를 제공합니다.</a>
                  </a>
                </div>
                <div style={{ marginBottom: "30px" }}>
                  <a className="Light" style={{ fontSize: "14px" }}>
                    또한,
                    <a className="Medium">
                      {" "}
                      정부지원사업 관련 컨설팅 및 전과정
                      <br />
                      서포트
                    </a>
                    를 진행해드리는 서비스도 제공하고 있습니다.
                  </a>
                </div>

                <div>
                  <a className="Medium" style={{ fontSize: "14px" }}>
                    카카오톡 채널, 전화, 이메일
                    <a className="Light">
                      {" "}
                      등<br />
                      다양한 창구를 열어두고 있으니
                      <br />
                      상표, 특허와 관련된{" "}
                    </a>
                    관련된 어떤 내용이든
                    <br />
                    편하게 문의해주세요 :)
                  </a>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "48px" }}>
              <img src={main_office} style={{ width: "301px" }} />
            </div>
          </div>

          <div
            id="bottom"
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              marginTop: "30px",
              paddingTop: "60px",
              backgroundColor: "#f5f6ff",
              textAlign: "center",
              borderRadius: "30px",
              boxShadow: "3px 3px 3px 0 rgba(0, 0, 0, 0.16)",
              paddingBottom: "60px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                lineHeight: "1.6",
                fontSize: "14px",
              }}
            >
              <div>
                <a className="Bold" style={{ fontSize: "28px" }}>
                  김현호 변리사
                </a>
              </div>

              <img
                src={m_kim}
                style={{
                  width: "200px",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              />
              <div>
                <a className="Light">
                  네임텍 특허법률사무소 대표 변리사 (2020~)
                  <br />
                  KBK 특허법인 (2014~2020)
                  <br />
                  고려대학교 기계공학과 졸업 (2014)
                </a>
              </div>

              <div style={{ marginTop: "50px" }}>
                <a className="Bold" style={{ fontSize: "28px" }}>
                  정의석 변리사
                </a>
              </div>

              <img
                src={m_jeong}
                style={{
                  width: "200px",
                  marginTop: "30px",
                  marginBottom: "30px",
                }}
              />

              <div>
                <a className="Light">
                  네임텍 특허법률사무소 대표 변리사 (2023~)
                  <br />
                  GVIP 특허법률사무소 (2018~2023)
                  <br />
                  리앤권 특허법률사무소 (2016~2018)
                  <br />
                  고려대학교 기계공학과 졸업 (2016)
                </a>
              </div>

              <div
                style={{
                  fontSize: "33px",
                  transform: "rotate(136deg)",
                  marginTop: "35px",
                  color: "#4a64f5",
                }}
              >
                ㅡ
              </div>
              <div style={{ marginTop: "33px", marginBottom: "30px" }}>
                <a
                  className="Bold"
                  style={{ fontSize: "26px", color: "#4a64f5" }}
                >
                  “신뢰할 수 있는 네임텍”
                </a>
              </div>
              <div>
                <a className="Light" style={{ color: "#515151" }}>
                  네임텍을 찾아주신 고객님에게
                  <br />
                  출원, 등록 서비스의 모든 과정과 비용을
                  <br />
                  투명하게 공개하고,
                  <br />
                  상표등록의 모든 과정이 완벽하게 끝날 때 까지
                  <br />
                  담당 변리사가 끝까지 책임지겠습니다.
                </a>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "relative",
              marginBottom: "20px",
              margin: "auto",
              borderRadius: "10px",
            }}
          >
            <div style={{ marginTop: "27px" }}>
              {customeremail1 === null ? (
                <Link to="/login">
                  <button
                    className="Bold1 btn"
                    type="button"
                    id="start_btn4"
                    style={{ cursor: "pointer" }}
                  >
                    상표등록 시작하기
                  </button>
                </Link>
              ) : (
                <Link to="/brandregister" id="brandtag">
                  <button
                    className="Bold1 btn"
                    type="button"
                    id="start_btn4"
                    style={{ cursor: "pointer" }}
                  >
                    상표등록 시작하기
                  </button>
                </Link>
              )}
            </div>
            <Link to="/brandsearch" style={{ textDecoration: "none" }}>
              <img
                src={mo_brandsearch_bt}
                style={{
                  width: "300px",
                  display: "inline-block",
                  marginTop: "10px",
                  marginLeft: "calc(50% - 150px)",
                }}
              />
            </Link>
          </div>
        </div>
        <div
          style={{
            paddingLeft: "19px",
            paddingTop: "55px",
            paddingBottom: "85px",
            margin: "auto",
            backgroundColor: "#fafafa",
            marginTop: "77px",
          }}
        >
          <a className="Medium1" style={{ fontSize: "14px", color: "#000000" }}>
            네임텍 사업자 정보
          </a>
          <div style={{ marginTop: "10px", lineHeight: "14px" }}>
            <a
              className="Light1"
              style={{ fontSize: " 11px", color: "#898989" }}
            >
              대표자 :{" "}
            </a>
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#000000" }}
            >
              &nbsp;&nbsp;김현호, 정의석
            </a>
            <br />
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#898989" }}
            >
              주소 :{" "}
            </a>
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#000000" }}
            >
              &nbsp;&nbsp;서울특별시 금천구 가산디지털2로 144, 4층 414호
            </a>
            <br />
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#898989" }}
            >
              사업자등록번호 :{" "}
            </a>
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#000000" }}
            >
              &nbsp;&nbsp;439-30-01015
            </a>
            <br />
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#898989" }}
            >
              통신판매업 신고번호 :{" "}
            </a>
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#000000" }}
            >
              &nbsp;&nbsp;2024-서울금천-0207호
            </a>
            <br />
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#898989" }}
            >
              전화번호 :{" "}
            </a>
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#000000" }}
            >
              &nbsp;&nbsp;02-922-5710
            </a>
          </div>
          <div style={{ marginTop: "6px" }}>
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#898989" }}
              href="https://nametecadmin.kr/termsOfService"
            >
              서비스 이용약관{" "}
            </a>
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#898989" }}
            >
              &nbsp;|&nbsp;
            </a>
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#898989" }}
              href="https://nametecadmin.kr/privacyPolicy"
            >
              개인정보처리방침
            </a>
          </div>
          <div style={{ marginTop: "6px" }}>
            <a
              className="Light1"
              style={{ fontSize: "11px", color: "#000000" }}
            >
              Copyright©2021 네임텍. All rights reserved.
            </a>
          </div>
          <div style={{ marginTop: "6px", marginBottom: "20px" }}>
            <a href="tel:02-922-5710">
              <img
                src={icon_phone}
                style={{ cursor: "pointer", width: "36px", float: "left" }}
              />
            </a>
            <img
              src={icon_insta}
              onClick={() => {
                window.location = "https://www.instagram.com/name.tec/";
              }}
              style={{
                cursor: "pointer",
                width: "36px",
                float: "left",
                marginLeft: "7px",
              }}
            />
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div
          style={{
            margin: "0 auto",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          <BrowserHeader />
          <div style={{ minWidth: "1360px" }}>
            <div
              style={{
                marginTop: "80px",
                textAlign: "center",
                minWidth: "800px",
                lineHeight: "1.6",
              }}
            >
              <a className="Bold" style={{ fontSize: "40px" }}>
                <a style={{ color: "#4a64f5" }}>추가 수수료 없는</a> 원스톱
                상표등록
              </a>
              <br />
              <a
                className="Light"
                style={{ fontSize: "20px", lineHeight: "1.5" }}
              >
                상표등록 수수료 딱 한번만 내면 상담, 검토, 서류 대응, 심사 거절
                시 재출원까지 모두 제공 :)
              </a>
              <div
                style={{
                  paddingTop: "29px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {customeremail1 === null ? (
                  <Link to="/login" style={{ height: "70px" }}>
                    <img
                      src={zxzxn}
                      style={{
                        cursor: "pointer",
                        width: "313px",
                        height: "70px",
                        marginRight: "20px",
                      }}
                    />
                  </Link>
                ) : (
                  <Link
                    to="/brandregister"
                    id="brandtag2"
                    style={{ height: "70px" }}
                  >
                    <img
                      src={zxzxn}
                      style={{
                        cursor: "pointer",
                        width: "313px",
                        height: "69px",
                        marginRight: "20px",
                      }}
                    />
                  </Link>
                )}

                <Link to="/brandsearch" style={{ textDecoration: "none" }}>
                  <img
                    src={pc_brandsearch_bt}
                    style={{
                      cursor: "pointer",
                      width: "313px",
                      height: "69px",
                      marginTop: "6px",
                    }}
                  />
                </Link>
              </div>
            </div>
            <ScrollBanner />
            <div
              className="grad"
              style={{
                zIndex: "-1",
                backgroundColor: "#222",
                width: "100%",
                marginLeft: "-20px",
                marginTop: "430px",
                position: "absolute",
                height: "1000px",
              }}
            ></div>

            <div
              style={{
                width: "1320px",
                backgroundColor: "#edeffe",
                borderRadius: "30px",
                height: "365px",
                margin: "0px auto",
                marginTop: "80px",
                paddingLeft: "80px",
                display: "flex",
                boxShadow: "10px 10px 20px 0 rgba(0, 0, 0, 0.29)",
              }}
            >
              <div style={{ width: "65%", height: "365px" }}>
                <img
                  src={main_3dbadge}
                  style={{ width: "262px", marginTop: "39px" }}
                />
                <div
                  className="Regular"
                  style={{
                    fontSize: "29px",
                    borderRadius: " 30px",
                    width: "800px",
                    height: "205px",
                    paddingTop: "12px",
                    paddingLeft: "29px",
                    marginTop: "30.5px",
                    backgroundColor: "#fff",
                    boxShadow: "4px 4px 4px 0 rgba(0, 0, 0, 0.16)",
                  }}
                >
                  <div style={{ marginTop: "20px" }}>
                    <a className="Bold" style={{ color: "#4a64f5" }}>
                      {texts[currentIndex]}
                    </a>{" "}
                    고객님은 <br />
                    네임텍상표에서 상표 {texts3[currentIndex]}
                    <br />
                    진행했습니다 :)
                  </div>
                  <div
                    className="Medium"
                    style={{
                      marginTop: "20px",
                      fontSize: "20px",
                      color: "#898989",
                    }}
                  >
                    {texts2[currentIndex2]}
                  </div>
                </div>
              </div>

              <div style={{ width: "35%", height: "365px" }}>
                <img
                  src={main_tag}
                  style={{ width: "337px", marginLeft: "52px" }}
                />
                <FadeBanner banners={banners} />
              </div>
            </div>

            <div
              className="Bold"
              style={{
                marginTop: "97px",
                textAlign: "center",
                fontSize: "40px",
              }}
            >
              네임텍상표, 뭐가 다른가요<a style={{ color: "#4a64f5" }}>?</a>
            </div>

            <div
              style={{
                display: "flex",
                width: "1320px",
                backgroundColor: "#fafafa",
                borderRadius: "30px",
                height: "595px",
                margin: "0 auto",
                marginTop: "60px",
                paddingLeft: "80px",
                boxShadow: "10px 10px 20px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div
                style={{
                  width: "50%",
                  height: "570px",
                  paddingTop: "80px",
                  lineHeight: "1.6",
                }}
              >
                <a className="Bold" style={{ fontSize: "29px" }}>
                  언제, 어디서든, 누구나
                  <br />
                  쉽고 빠른 비대면 신청
                </a>
                <div style={{ marginTop: "27px", marginBottom: "35px" }}>
                  <a className="Light" style={{ fontSize: "22px" }}>
                    네임텍상표는 <a className="Medium">스마트폰, 노트북</a>을
                    통해 <br />
                    <a className="Medium">언제, 어디서든, 비대면으로</a>
                    <br />
                    상표출원 서비스를 신청하실 수 있습니다.
                  </a>
                </div>
                <a className="Light" style={{ fontSize: "22px" }}>
                  카카오톡, 네이버 계정으로{" "}
                  <a className="Medium">10초만에 가입</a>하고
                  <br />
                  <a className="Medium">3분 안에 모든 신청을 완료</a>하실 수
                  있습니다.
                </a>
              </div>
              <div
                style={{ width: "50%", height: "570px", textAlign: "right" }}
              >
                <img
                  src={main_mock}
                  style={{
                    width: "510px",
                    marginTop: "140px",
                    marginRight: "80px",
                  }}
                  alt="Main Mock"
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "1320px",
                backgroundColor: "#fafafa",
                borderRadius: "30px",
                height: "775px",
                margin: "0 auto",
                marginTop: "60px",
                paddingRight: "80px",
                paddingTop: "80px",
                boxShadow: "10px 10px 20px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div
                style={{ width: "50%", height: "750px", paddingLeft: "78px" }}
              >
                <img
                  src={main_receipt}
                  style={{ width: "560px", marginTop: "300px" }}
                />
              </div>
              <div
                style={{
                  width: "50%",
                  height: "750px",
                  textAlign: "right",
                  lineHeight: "1.6",
                }}
              >
                <a className="Bold" style={{ fontSize: "29px" }}>
                  최소 비용으로 최대 서비스 제공
                </a>

                <div style={{ marginTop: "27px", marginBottom: "30px" }}>
                  <a className="Light" style={{ fontSize: "22px" }}>
                    저렴한 비용의 상표출원 서비스는 자세히 알아보면
                    <br />
                    <a className="Medium">추가 비용이 계속 붙어 결국 큰 금액</a>
                    이 되는 경우가 많습니다.
                  </a>
                </div>

                <div style={{ marginBottom: "30px" }}>
                  <a className="Light" style={{ fontSize: "22px" }}>
                    네임텍 상표는 수수료를 최초에 딱 한 번만 받고
                    <br />
                    <a className="Medium">
                      꼭 필요한 서비스를 추가비용 없이 모두 제공합니다.
                    </a>
                  </a>
                </div>
                <div style={{ marginBottom: "30px" }}>
                  <a className="Light" style={{ fontSize: "22px" }}>
                    또한, 신청 후 마음이 바뀌어 취소하더라도
                    <br />
                    <a className="Medium" style={{ color: "#4a64f5" }}>
                      특허청 접수 직전까지는 취소 수수료 없이
                      <br />
                      100% 환불이 가능
                    </a>
                    하기 때문에
                    <br />
                    출원 신청 후,{" "}
                    <a className="Medium">
                      1:1 변리사 상담 단계에서
                      <br />
                      출원 여부를 최종 결정
                    </a>
                    하시면 됩니다.
                  </a>
                </div>
                <div style={{ marginBottom: "30px" }}>
                  <a className="Medium" style={{ fontSize: "22px" }}>
                    출원에 실패했을 경우,
                    <a className="Medium" style={{ color: "#4a64f5" }}>
                      관납료만 받고
                      <br />될 때 까지 재출원{" "}
                    </a>
                    <a className="Light">해드립니다.</a>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="grad"
              style={{
                zIndex: "-1",
                backgroundColor: "#222",
                width: "100%",
                marginLeft: "-20px",
                marginTop: "750px",
                position: "absolute",
                height: "1200px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "1320px",
                backgroundColor: "#fafafa",
                borderRadius: "30px",
                height: "728px",
                margin: "0 auto",
                marginTop: "60px",
                paddingLeft: "80px",
                boxShadow: "10px 10px 20px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div
                style={{ width: "50%", paddingTop: "80px", lineHeight: "1.6" }}
              >
                <a className="Bold" style={{ fontSize: "29px" }}>
                  빈틈없이 꼼꼼하게 챙겨드리는 서비스
                </a>
                <div style={{ marginTop: "27px", marginBottom: "35px" }}>
                  <a className="Light" style={{ fontSize: "22px" }}>
                    서비스 수수료는 저렴하지만
                    <br />
                    빈틈없는 서비스를 제공합니다.
                  </a>
                </div>
                <div style={{ marginBottom: "30px" }}>
                  <a className="Medium" style={{ fontSize: "22px" }}>
                    <a className="Medium" style={{ color: "#4a64f5" }}>
                      신청하시는 모든 고객님과
                      <br />
                      변리사 1:1 상담 및 상세 검토
                    </a>
                    <a className="Light">
                      를<br />
                      진행해 안전하게 출원합니다.
                    </a>
                  </a>
                </div>

                <div style={{ marginTop: "27px", marginBottom: "30px" }}>
                  <a className="Light" style={{ fontSize: "22px" }}>
                    또한, 출원부터 등록이 끝나는 시점까지
                    <br />
                    <a className="Medium">
                      의견 대응 등의 모든 서류처리를
                      <br />
                      <a style={{ color: "#4a64f5" }}>추가 비용 없이 진행</a>
                    </a>
                    해드립니다.
                  </a>
                </div>
              </div>
              <div
                style={{ width: "50%", height: "570px", textAlign: "right" }}
              >
                <img
                  src={main_service}
                  style={{
                    width: "530px",
                    marginTop: "260px",
                    marginRight: "77px",
                  }}
                  alt="Main Mock"
                />
              </div>
            </div>

            <div
              className="Bold"
              style={{
                marginTop: "97px",
                textAlign: "center",
                fontSize: "40px",
              }}
            >
              상표등록 비용이 궁금해요<a style={{ color: "#4a64f5" }}>!</a>
            </div>

            <div
              style={{
                textAlign: "center",
                width: "1400px",
                backgroundColor: "#fafafa",
                borderRadius: "30px",
                height: "610px",
                margin: "0 auto",
                marginTop: "60px",
                paddingTop: "83px",
                boxShadow: "10px 10px 20px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div className="Bold" style={{ fontSize: "29px" }}>
                일반 상표출원 시 납부 금액
              </div>

              <div style={{ textAlign: "center" }}>
                <img
                  src={main_price}
                  style={{ width: "379px", marginTop: "29px" }}
                />
              </div>

              <div
                class="Light1"
                style={{
                  fontSize: "22px",
                  lineHeight: "1.6",
                  marginTop: " 30px",
                  textAlign: "center",
                }}
              >
                <a className="Medium">*특허청 관납료</a>는 네임텍 수수료와
                별개로
                <br />
                출원 시 <a className="Medium">특허청에 필수로 납부하는 금액</a>
                입니다.
              </div>

              <div
                class="Light1"
                style={{
                  fontSize: "22px",
                  lineHeight: "1.6",
                  marginTop: " 30px",
                  textAlign: "center",
                }}
              >
                <a className="Medium">
                  *심사기간 단축옵션, 최종 상표등록 관납료
                </a>{" "}
                등<br />
                더욱 상세한 비용은 <a className="Medium">아래 링크에서 확인</a>
                하세요.
              </div>

              <div
                className="Light"
                style={{ marginTop: "30px", textAlign: "center" }}
              >
                <a
                  href="/costprocess"
                  style={{ color: "#4a64f5", fontSize: "22px" }}
                >
                  + 더 자세히 알아보기(click)
                </a>
              </div>
            </div>

            <div
              className="Bold"
              style={{
                marginTop: "97px",
                textAlign: "center",
                fontSize: "40px",
              }}
            >
              상표등록 과정이 궁금해요<a style={{ color: "#4a64f5" }}>!</a>
            </div>

            <div
              style={{
                textAlign: "center",
                width: "1400px",
                backgroundColor: "#fafafa",
                borderRadius: "30px",
                height: "925px",
                margin: "0 auto",
                marginTop: "80px",
                paddingTop: "80px",
                boxShadow: "10px 10px 20px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div
                className="Bold"
                style={{
                  fontSize: "29px",
                  textAlign: "left",
                  marginLeft: "80px",
                }}
              >
                상표등록 신청 과정 안내
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src={main_process17}
                  style={{ width: "1200px", marginTop: "29px" }}
                />
              </div>
            </div>

            <div
              className="grad"
              style={{
                zIndex: "-1",
                backgroundColor: "#222",
                width: "100%",
                marginLeft: "-20px",
                marginTop: "1000px",
                position: "absolute",
                height: "1300px",
              }}
            ></div>

            <div
              style={{
                textAlign: "center",
                width: "1400px",
                backgroundColor: "#fafafa",
                borderRadius: "30px",
                height: "925px",
                margin: "0 auto",
                marginTop: "80px",
                paddingTop: "75px",
                boxShadow: "10px 10px 20px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div
                className="Bold"
                style={{
                  fontSize: "29px",
                  textAlign: "left",
                  marginLeft: "80px",
                }}
              >
                신청 이후의 등록 과정 안내
              </div>
              <div style={{ textAlign: "center" }}>
                <img
                  src={main_progress18}
                  style={{ width: "1200px", marginTop: "29px" }}
                />
              </div>
            </div>

            <div
              className="Bold"
              style={{
                marginTop: "97px",
                textAlign: "center",
                fontSize: "40px",
              }}
            >
              네임텍 소개<a style={{ color: "#4a64f5" }}>.</a>
            </div>

            <div
              style={{
                width: "1320px",
                backgroundColor: "#fafafa",
                borderRadius: "30px",
                height: "1063px",
                margin: "0 auto",
                marginTop: "60px",
                paddingRight: "80px",
                paddingTop: "80px",
                boxShadow: "10px 10px 20px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div style={{ textAlign: "right", lineHeight: "1.6" }}>
                <a className="Bold" style={{ fontSize: "29px" }}>
                  네임텍은 어떤 곳인가요?
                </a>
                <div style={{ fontSize: "22px" }}>
                  <div style={{ marginTop: "27px", marginBottom: "30px" }}>
                    <a className="Medium" style={{ fontSize: "22px" }}>
                      네임텍
                    </a>
                    <a className="Light">
                      은 혁신적인 기술과
                      <br />
                      창의적인 아이디어를 가진 개인 및 기업들을 위해
                    </a>
                  </div>

                  <div style={{ marginBottom: "30px" }}>
                    <a className="Light" style={{ fontSize: "22px" }}>
                      상표, 특허 출원을 통해 클라이언트들의 아이디어를 안전하게
                      보호하고
                      <br />
                      성공적으로 상업화할 수 있도록 도와 드리는 것을 목표로 삼아
                      <br />
                      <a className="Medium">
                        이용하기 쉽고 편리하면서도 성공률 높은 출원 서비스
                      </a>
                      를 제공합니다.
                    </a>
                  </div>

                  <div style={{ marginBottom: "30px" }}>
                    <a className="Light" style={{ fontSize: "22px" }}>
                      또한,
                      <a className="Medium">
                        {" "}
                        정부지원사업 관련 컨설팅 및<br />
                        전과정 서포트
                      </a>
                      를 진행해드리는 서비스도 제공하고 있습니다.
                    </a>
                  </div>

                  <div style={{ marginBottom: "30px" }}>
                    <a className="Medium" style={{ fontSize: "22px" }}>
                      카카오톡 채널, 전화, 이메일
                      <a className="Light">
                        {" "}
                        등 다양한 창구를 열어두고 있으니
                        <br />
                        상표, 특허와 관련된{" "}
                      </a>
                      관련된 어떤 내용이든 편하게 문의해주세요 :)
                    </a>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "95px", marginLeft: "80px" }}>
                <img src={main_office} style={{ width: "646px" }} />
              </div>
            </div>

            <div
              style={{
                width: "1400px",
                backgroundColor: "#f5f6ff",
                borderRadius: "30px",
                height: "880px",
                margin: "0 auto",
                marginTop: "60px",
                textAlign: "center",
                boxShadow: "10px 10px 20px 0 rgba(0, 0, 0, 0.16)",
              }}
            >
              <div style={{ marginTop: "95px" }}>
                <img
                  src={patent_introduce}
                  style={{ width: "1046px", marginTop: "83px" }}
                />
              </div>
            </div>

            <div style={{ paddingTop: "51px", textAlign: "center" }}>
              {customeremail1 === null ? (
                <Link to="/login">
                  <img
                    src={zxzxn}
                    onClick=""
                    style={{
                      cursor: "pointer",
                      width: "313px",
                      marginRight: "20px",
                    }}
                  />
                </Link>
              ) : (
                <Link to="/brandregister" id="brandtag2">
                  <img
                    src={zxzxn}
                    onClick=""
                    style={{
                      cursor: "pointer",
                      width: "313px",
                      marginRight: "20px",
                    }}
                  />
                </Link>
              )}
              <Link to="/brandsearch" style={{ textDecoration: "none" }}>
                <img
                  src={pc_brandsearch_bt}
                  style={{
                    cursor: "pointer",
                    width: "313px",
                    height: "69px",
                    marginTop: "6px",
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div
          style={{
            height: "400.5px",
            paddingBottom: "26px",
            paddingLeft: "calc(50% - 700px)",
            backgroundColor: "#fafafa",
            marginTop: "80px",
            lineHeight: "1.4",
          }}
        >
          <div
            className="Medium"
            style={{ fontSize: "23px", paddingTop: "76px", marginTop: "170px" }}
          >
            네임텍 사업자 정보
          </div>

          <div
            className="Light"
            style={{ paddingTop: "17px", fontSize: "18px", width: "1400px" }}
          >
            <a style={{ paddingTop: "17px", color: "#898989" }}>
              대표자 : <a style={{ color: "#000000" }}>김현호, 정의석</a> |{" "}
            </a>
            <a style={{ paddingTop: "17px", color: "#898989" }}>
              주소 :{" "}
              <a style={{ color: "#000000" }}>
                서울특별시 금천구 가산디지털2로 144, 4층 414호
              </a>
              <br />
            </a>

            <a style={{ paddingTop: "17px", color: "#898989" }}>
              사업자 등록번호 : <a style={{ color: "#000000" }}>439-30-01015</a>{" "}
              |{" "}
            </a>
            <a style={{ paddingTop: "17px", color: "#898989" }}>
              통신판매업 신고번호 :{" "}
              <a style={{ color: "#000000" }}>2024-서울금천-0207호</a>
              <br />
            </a>

            <a style={{ paddingTop: "17px", color: "#898989" }}>
              전화번호 : <a style={{ color: "#000000" }}>02-922-5710</a>
            </a>
          </div>

          <div style={{ paddingTop: "32px" }}>
            <a
              className="Medium"
              style={{ fontSize: "18px", color: "#898989" }}
            >
              <a href="https://nametecadmin.kr/termsOfService">
                서비스 이용약관
              </a>{" "}
              |{" "}
              <a href="https://nametecadmin.kr/privacyPolicy">
                개인정보처리방침
              </a>
            </a>
            <br />
            <a className="Light" style={{ fontSize: "18px", color: "#000000" }}>
              Copyright©2021 네임텍. All rights reserved.
            </a>
          </div>

          <div style={{ paddingTop: "28px" }}>
            <a href="https://instagram.com/name.tec">
              <img
                src={icon_insta}
                style={{ cursor: "pointer", width: "60px", height: "60px" }}
              />
            </a>
            <a href="https://blog.naver.com/name-tec">
              <img
                src={icon_naver}
                onClick=""
                style={{
                  cursor: "pointer",
                  width: "57px",
                  height: "57px",
                  marginLeft: "10px",
                }}
              />
            </a>
          </div>
        </div>
      </BrowserView>
    </div>
  );
}

export default Intro;

$(document).ready(function () {
  var target = document.getElementById("channel-chat-button");
  var targetTop = window.pageYOffset + target.getBoundingClientRect().top;
  var floatPosition = parseInt($("#channel-chat-button").css("top"));
  // scroll 인식
  $(window)
    .scroll(function () {
      // 현재 스크롤 위치
      var currentTop = window.pageYOffset;

      if (currentTop + floatPosition - 10 > document.body.clientHeight - 1100) {
        var bannerTop = document.body.clientHeight - 1200 + "px";
      } else {
        var bannerTop = currentTop + floatPosition - 10 + "px";
      }
      //이동 애니메이션
      $("#channel-chat-button").stop().animate(
        {
          top: bannerTop,
        },
        500
      );
    })
    .scroll();
});
