import React, { useState, useEffect,memo } from 'react'
import '../style.css'
import '../text.css'
import TopbarComponentWithoutPage from '../components/Topbarwithoutpage'
import high from '../image/high.png'
import checkgray from '../image/1.png'
import checkblue from '../image/2.png'
import bluearr from '../image/bluearr.jpg'
import rechoice from '../image/re.png'
import ask5 from '../image/zma.png'
import kakaoaa from '../image/kakaoaa.png'
import loadingdot from '../image/loadingdot.gif'
import asdzx from '../image/asdzx.png'
import load_mo from '../image/load_mo.png'
import Nextbutton from '../components/Nextbutton'
import nextbtn_new from '../image/nextbtn_new.png'
import $ from 'jquery'
import new_blue from '../image/new_blue.png'
import af389 from '../image/1f389.png'
import af3892 from '../image/se_mo.png'
import sclock from '../image/time.png'
import { BrowserRouter, Route, Switch as Routes, Link, NavLink, useParams } from 'react-router-dom'
import { BrowserView, MobileView, isBrowser, IsMobile, TabletView } from 'react-device-detect'
import BrowserHeader from '../components/BrowserHeader'
var goodsList = [];
function addDay(date) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1
  );

  var year = addMonthFirstDate.getFullYear();
  var month = new String(addMonthFirstDate.getMonth() + 1);
  var day = new String(addMonthFirstDate.getDate());


  if (month.length == 1) {
      month = "0" + month;
  }
  if (day.length == 1) {
      day = "0" + day;
  }

  return year + "-" + month + "-" + day;
}
function addMonth(date, month) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
      date.getFullYear(),
      date.getMonth() + month,
      date.getDate()
  );

  // month달 후의 말일
  let addMonthLastDate = new Date(
      addMonthFirstDate.getFullYear(),
      addMonthFirstDate.getMonth() + month
      , 0
  );

  let result = addMonthFirstDate;
  if (date.getDate() > addMonthLastDate.getDate()) {
      result.setDate(addMonthLastDate.getDate());
  } else {
      result.setDate(date.getDate());
  }

  var year = result.getFullYear();
  var month = new String(result.getMonth() + 1);
  var day = new String(result.getDate());


  if (month.length == 1) {
      month = "0" + month;
  }
  if (day.length == 1) {
      day = "0" + day;
  }

  return year + "-" + month + "월 예상";
}
function addMonth2(date, month) {
  // month달 후의 1일
  let addMonthFirstDate = new Date(
      date.getFullYear(),
      date.getMonth() + month,
      date.getDate()
  );

  // month달 후의 말일
  let addMonthLastDate = new Date(
      addMonthFirstDate.getFullYear(),
      addMonthFirstDate.getMonth() + month
      , 0
  );

  let result = addMonthFirstDate;
  if (date.getDate() > addMonthLastDate.getDate()) {
      result.setDate(addMonthLastDate.getDate());
  } else {
      result.setDate(date.getDate());
  }

  var year = result.getFullYear();
  var month = new String(result.getMonth() + 1);
  var day = new String(result.getDate());


  if (month.length == 1) {
      month = "0" + month;
  }
  if (day.length == 1) {
      day = "0" + day;
  }

  return "심사 단축 시 "+year + "-" + month + "월 예상";
}

let janLastDate = new Date();
var tomorow = addDay(janLastDate);
var month12 = addMonth(janLastDate, 15);
var month5 = addMonth(janLastDate, 3);
var month52 = addMonth2(janLastDate,3);
function AnimalFriends(props) {
  const animals = props.skarb
  const animalList = animals.map((animal) => (
    <div key={animal.id} className="Bold" style={{ paddingTop: '20px',borderBottom:'1px solid #b7b7b7'}}>
      <img
        onClick={() => props.onClick1(props, animal)}
        src={animal.valuelist2}
        style={{ width: '20px', float: 'left', marginTop: '-0.5px' ,cursor:'pointer'}}
      />
      <a id={animal.valuelist[0]}  onClick={() => props.onClick1(props, animal)} className="Bold" style={{ fontSize: '16px', marginLeft: '5px', marginTop: '15px' ,color:'#4a64f5',cursor:'pointer'}}>
        {animal.valuelist}
      </a>
      

    
            <div className='Regular' style={{marginTop:'12px',fontSize:'14px',display:'grid'}}>
              <div>
              <a className="" style={{color:'#515151',float:'left'}}>특허청 관납료</a>
                <a className="2nd" style={{opacity: '1',color: '#515151',display:'inline-block',float:'right'}}>52,000원</a>
            </div>
                
                
            </div>



            <div className='Regular' id={animal.valuelist[0]+"a"} style={{display:'none',marginTop:'3px',height:'20px',fontSize:'14px'}}>
                    <div id="divplus10" style={{color: '#4a64f5',float:'right'}}>+160,000원 </div>
                </div>



                <div className='Regular' style={{marginTop:'3px',fontSize:'14px',display:'grid'}}>
                  <div>
                  <a className="" style={{color:'#515151',float:'left'}}>네임텍 수수료</a>
                <a className="2nd" style={{opacity: '1',color: '#515151',display:'inline-block',float:'right'}}>110,000원</a>
                  </div>
              
            </div>
            <div className='Regular' id={animal.valuelist[0]+"b"} style={{display:'none',marginTop:'3px',height:'20px',fontSize:'14px'}}>
                    <div id="divplus10" style={{color: '#4a64f5',float:'right'}}>+200,000원 </div>
                </div>


                

            <div className="in2" style={{color:'#898989', display:'inline-block',width:'100%',paddingBottom:'20px',fontSize : "14px",borderBottom :  '1px dashed #e0e0e0'}}>

                <div className="Regular" style={{color: '#515151',marginTop:'3px'}}>
                    <p style={{display: 'inline',opacity:' 1'}}>상표 출원 예상일</p>
                    <span className="" style={{float:'right'}}>{tomorow}</span>
                </div>

                <div id={animal.valuelist[0]+"c"} className="Regular" style={{color: '#515151',marginTop:'3px'}}>
                    <p style={{display: 'inline',opacity: '1'}}>심사 결과 통보 예상일 </p>
                    <span className="" style={{color:'#000000',float:'right'}}>{month12}</span>
                </div>
                <div id={animal.valuelist[0]+"d"} className="Regular" style={{color: '#b7b7b7',display:'none',marginTop:'3px'}}>
                    <p style={{display: 'inline',opacity: '1'}}>심사 결과 통보 예상일 </p>
                    <span className="" style={{color:'#b7b7b7',float:'right'}}>{month12}</span>
                </div>
                <div id={animal.valuelist[0]+"e"} className="Regular" style={{color: '#b7b7b7',marginTop:'3px'}}>
                    <p style={{display: 'inline',opacity: '1'}}>심사기간 단축 옵션 선택 시</p>
                    <span className="" style={{color:'#b7b7b7',float:'right'}}>{month5}</span>
                    
                </div>
                <div id={animal.valuelist[0]+"f"} className="Regular" style={{color: '#4a64f5',display:'none',marginTop:'3px'}}>
                    <p style={{display: 'inline',opacity: '1'}}>심사기간 단축 옵션 선택 시</p>
                    <span className="" style={{color:'#4a64f5',float:'right'}}>{month5}</span>
                </div>
            </div>
       
            <div>
            <div  className="Regular" style={{color: '#000000',marginTop:'20px',fontSize:'16px',paddingBottom:'20px'}}>
                    <p className='Regular' style={{display: 'inline'}}>심사기간 단축 옵션 선택 시</p>
                  <label className="switch2" style={{float : 'right',color:'#fff'}}>
                      <input id={animal.valuelist[0]+"g"} type="checkbox" style={{marginLeft:'7px'}} onClick={() => props.setfirstn( props,animal)} className="chchch" />
                        <span style={document.querySelector('#'+animal.valuelist[0]+"g")?.checked ? {paddingTop:'2.9px',paddingLeft:'5px'} : {paddingLeft:'35px',paddingTop:'1.2px'}} className="slider2 round Bold1">{document.querySelector('#'+animal.valuelist[0]+"g")?.checked ? ':D' : '(:'}</span>
                  </label>
                </div>
            </div>




    </div>
  ))

  return <div>{animalList}</div>
}
function AnimalFriends2(props) {
  const animals = props.skarb
  const animalList = animals.map((animal) => (
    <div key={animal.id} className="Bold" style={{ marginTop: '27.5px',marginLeft:'23px' ,borderBottom:'1px solid #b7b7b7'}}>
      <img
        onClick={() => props.onClick1(props, animal)}
        src={animal.valuelist2}
        style={{ width: '35px', float: 'left', marginTop: '-3px' ,cursor:'pointer'}}
      />
      <a id={animal.valuelist[0]}  onClick={() => props.onClick1(props, animal)} className="Bold" style={{ fontSize: '22px', marginLeft: '5px', marginTop: '15px' ,color:'#4a64f5',cursor:'pointer'}}>
        {animal.valuelist}
      </a>
      

    
            <div className='Regular' style={{marginTop:'12px',fontSize:'19px',display:'grid'}}>
              <div>
              <a className="" style={{float:'left'}}>특허청 관납료</a>
                <a className="2nd" style={{opacity: '1',color: '#515151',display:'inline-block',float:'right'}}>52,000원</a>
            </div>
                
                
            </div>



            <div className='Regular' id={animal.valuelist[0]+"a"} style={{display:'none',marginTop:'12px',height:'20px',fontSize:'19px'}}>
                    <div id="divplus10" style={{color: '#4a64f5',float:'right'}}>+160,000원 </div>
                </div>



                <div className='Regular' style={{marginTop:'12px',fontSize:'19px',display:'grid'}}>
                  <div>
                  <a className="" style={{float:'left',opacity:' 0.7'}}>네임텍 수수료</a>
                <a className="2nd" style={{opacity: '1',color: '#515151',display:'inline-block',float:'right'}}>110,000원</a>
                  </div>
              
            </div>
            <div className='Regular' id={animal.valuelist[0]+"b"} style={{display:'none',marginTop:'12px',height:'20px',fontSize:'19px'}}>
                    <div id="divplus10" style={{color: '#4a64f5',float:'right'}}>+200,000원 </div>
                </div>


                

            <div className="in2" style={{color:'#898989', display:'inline-block',width:'100%',paddingBottom:'27.5px',fontSize : "19px",borderBottom :  '1px dashed #e0e0e0'}}>

                <div className="Regular" style={{color: '#515151',marginTop:'12px'}}>
                    <p style={{display: 'inline',opacity:' 0.7'}}>상표 출원 예상일</p>
                    <span className="" style={{float:'right'}}>{tomorow}</span>
                </div>

                <div id={animal.valuelist[0]+"c"} className="Regular" style={{color: '#515151',marginTop:'12px'}}>
                    <p style={{display: 'inline',opacity: '0.7'}}>심사 결과 통보 예상일 </p>
                    <span className="" style={{color:'#000000',float:'right'}}>{month12}</span>
                </div>
                <div id={animal.valuelist[0]+"d"} className="Regular" style={{color: '#b7b7b7',display:'none',marginTop:'12px'}}>
                    <p style={{display: 'inline',opacity: '0.7'}}>심사 결과 통보 예상일 </p>
                    <span className="" style={{color:'#b7b7b7',float:'right'}}>{month12}</span>
                </div>
                <div id={animal.valuelist[0]+"e"} className="Regular" style={{color: '#b7b7b7',marginTop:'12px'}}>
                    <p style={{display: 'inline',opacity: '0.7'}}>심사기간 단축 옵션 선택 시</p>
                    <span className="" style={{color:'#b7b7b7',float:'right'}}>{month5}</span>
                    
                </div>
                <div id={animal.valuelist[0]+"f"} className="Regular" style={{color: '#4a64f5',display:'none',marginTop:'12px'}}>
                    <p style={{display: 'inline',opacity: '1'}}>심사기간 단축 옵션 선택 시</p>
                    <span className="" style={{color:'#4a64f5',float:'right'}}>{month5}</span>
                </div>
            </div>
       
            <div>
            <div  className="Regular" style={{color: '#000000',marginTop:'30.5px',fontSize:'19px',paddingBottom:'32px'}}>
                    <p className='Regular' style={{display: 'inline'}}>심사기간 단축 옵션 선택 시</p>
                  <label className="switch1" style={{float : 'right',color:'#fff'}}>
                      <input id={animal.valuelist[0]+"g"} type="checkbox" style={{marginLeft:'3px'}} onClick={() => props.setfirstn( props,animal)} className="chchch" />
                        <span style={document.querySelector('#'+animal.valuelist[0]+"g")?.checked ? {paddingTop:'4px',paddingLeft:'8px'} : {paddingLeft:'44px',paddingTop:'2px'}} className="slider1 round Bold1">{document.querySelector('#'+animal.valuelist[0]+"g")?.checked ? ':D' : '(:'}</span>
                  </label>
                </div>
            </div>




    </div>
  ))

  return <div>{animalList}</div>
}


var valuelist3 = []
var valuelist4 = []
function Test5() {
  function addMonth(date, month) {
    // month달 후의 1일
    let addMonthFirstDate = new Date(
        date.getFullYear(),
        date.getMonth() + month,
        date.getDate()
    );

    // month달 후의 말일
    let addMonthLastDate = new Date(
        addMonthFirstDate.getFullYear(),
        addMonthFirstDate.getMonth() + month
        , 0
    );

    let result = addMonthFirstDate;
    if (date.getDate() > addMonthLastDate.getDate()) {
        result.setDate(addMonthLastDate.getDate());
    } else {
        result.setDate(date.getDate());
    }

    var year = result.getFullYear();
    var month = new String(result.getMonth() + 1);
    var day = new String(result.getDate());


    if (month.length == 1) {
        month = "0" + month;
    }
    if (day.length == 1) {
        day = "0" + day;
    }

    return year + "년 " + month + "월 등록이 예상";
}
let janLastDate = new Date();
var month12 = addMonth(janLastDate, 15);

  const [isLoaded, setIsLoaded] = useState(false);
  const [goodslist8, setGoodslist8] = useState([])
  const [monthtext , setMonthtext] = useState(month12);
  const [valuelist, setValuelist] = useState([])
  const [valuelist2, setValuelist2] = useState([])
  const [brandnamet, setBrandnamet] = useState(2)
  const [successtext, setSuccesstext] = useState()
  const [successtext2, setSuccesstext2] = useState()
  const [successtext3, setSuccesstext3] = useState()
  const [successtexta, setSuccesstexta] = useState()
  const [successtexta2, setSuccesstexta2] = useState()
  const [successtexta3, setSuccesstexta3] = useState()
  const [successtexta4, setSuccesstexta4] = useState()
  const [successimg, setSuccessimg] = useState()
  let selectclasses =  sessionStorage.getItem('selectclasses')
  let selectclasses2 = selectclasses.split(',')
  const [count, setCount] = useState(selectclasses2.length)
  const [fcount , setFcount] = useState(0);
  const [fprice , setFprice] = useState(0);
  const [price1,setPrice1] = useState(0)
  const [price2,setPrice2] = useState(0)
  const [testCount, setTestCount] = useState(true)

  useEffect(() => {
    let customeremail2 = 'test'
    let customername2 = 'test'
    let encodeGoods = sessionStorage.getItem('brandname')
    let tokenName = sessionStorage.getItem('token')
    let encodeClass = sessionStorage.getItem('selectclasses')

    $.ajax({
      url: 'https://nametecadmin.kr/nokiprisapi3',

      type: 'get',

      accept: 'application/json',

      data: {
        상표명: encodeGoods,
        류: encodeClass,
        customername: customername2,
        customeremail: customeremail2,
        token: tokenName,
      },

      dataType: 'JSON',
      crossDomain: true,
      //              complete: function () {
      //
      //             document.getElementById("page21").style.display = '';
      //             }
      //             ,
      success: function (data1) {
      valuelist3 = []
      valuelist4 = []
      sessionStorage.setItem("firstgoods", JSON.stringify([]));
        $('#loadingDiv').css('display', 'none')
        $('#introFooter').css('display', '')
        $('#bodydiv').css('display', '')
        if (data1.status == 200) {
          sessionStorage.setItem('ajaxData', JSON.stringify(data1['result']))
          setBrandnamet(sessionStorage.getItem('brandname'))

          
            setSuccesstext('상표 등록 가능성')
            setSuccesstext2(Math.trunc(data1['result'][0]['percentage']))
            setSuccesstext3('동일/유사한 상표명이 없어요.')
            setSuccesstexta('매우 높은 확률로 상표등록이 가능하니')
            setSuccesstexta2('지금 바로 간편등록을 진행하여')
            setSuccesstexta3('상표를 선점하세요 :)')
            setSuccesstexta4('*상표 보호를 위해 빨리 등록하실 것을 추천해요!')
            setSuccessimg(af389)
          let btn_value = sessionStorage.getItem('btn_value')
          let btn_value2 = btn_value.split(',')
          setPrice(162000*btn_value2.length)
          setPrice1(52000*btn_value2.length)
          setPrice2(110000*btn_value2.length)
          for (let i = 0; i < btn_value2.length; i++) {
            let dict = {}
            dict['valuelist'] = btn_value2[i]
            dict['valuelist2'] = checkblue
            dict['valuelistc'] = 'checkgray' 
            valuelist3.push(data1['result'][i])
            valuelist4.push(data1['result'][i])
            setValuelist((valuelist) => [...valuelist, dict])
          }
          sessionStorage.setItem('valuelist3', JSON.stringify(valuelist3) );
          setCount(btn_value2.length)
          //                           for (let i = 0; i < data1['result'].length; i++) {
          //                           arraydata = data1['result'][i]['lastgoods'].split("+")
          //                               sessionStorage.setItem("result "+i + "", JSON.stringify(data1['result'][i]));
        }

        if (data1.status == 401) {
        }
        if (data1.status == 400) {
        }
      },
      error: function (request, status, error) {},
    })
  }, [])
  
  function getIndex(listS, valuelist) {
    return listS.findIndex((obj) => obj.valuelist === valuelist)
  }
  const [count3, setCount3] = useState([])
  const [renderFlag, setRenderFlag] = useState(true)
  const [eventcss, setEventcss] = useState('disabled-link')
  const [nextbtn, setNextbtn] = useState(nextbtn_new)
  useEffect(() => {
    onKeyUP()
  })
  const [text, setText] = useState(['선택해제', checkblue, 'smallChoiceBoxWhite'])
  const [price, setPrice] = useState(0)
  
  const onClick1 = (props, successList) => {
    let successProps = props.skarb;
    let successIndex = getIndex(successProps, successList.valuelist);
    if (successList.valuelist2 == checkblue) {
      if(successList.valuelistc==='checkblue') {
        const checkbox = document.querySelector('#' + successList.valuelist[0] + "g");
        checkbox.checked = false;
        setfirstn(props, successList)
        setPrice1(price1 - 212000);
        setPrice2(price2 - 330000);
    }else{
      setPrice1(price1 - 52000);
      setPrice2(price2 - 110000);
    }
        $('#'+successList.valuelist[0]).css('color', '#b7b7b7');
        for (let i = 0; i < successProps.length; i++) {
            if (i == successIndex) {
                successProps[i]['valuelist2'] = checkgray;
                
            }
        }
        for(var i = 0; i < valuelist3.length; i++){
            if (valuelist3[i]['lastgoods'][0] === successList.valuelist[0]) {
                valuelist3.splice(i, 1);
                i--;
            }
        }
        sessionStorage.setItem('valuelist3', JSON.stringify(valuelist3));
        setCount(count - 1);
       


    } else if (successList.valuelist2 == checkgray) {
      
        $('#'+successList.valuelist[0]).css('color', '#4a64f5');
        for (let i = 0; i < successProps.length; i++) {
            if (i == successIndex) {
                successProps[i]['valuelist2'] = checkblue;
            }
        }
        for(var i = 0; i < valuelist4.length; i++){
            if (valuelist4[i]['lastgoods'][0] === successList.valuelist[0]) {
                valuelist3.push(valuelist4[i]);
            }
        }
        sessionStorage.setItem('valuelist3', JSON.stringify(valuelist3));
        setCount(count + 1);
        setPrice1(price1 + 52000);
        setPrice2(price2 + 110000);
    }
    setRenderFlag(!renderFlag);
    setCount3(successProps);
};

  useEffect(() => {
    setValuelist(count3)
  }, [renderFlag, count3])




  
var firstmonth = addMonth(janLastDate, 3);
  
 
  const onKeyUP = () => {
    if (count > 0) {
      $('#next_button').css('background-color', '#4a64f5')
      $('#dam').css('color', '#ffffff')
      setEventcss('')
      setNextbtn(new_blue)
    } else {
      $('#next_button').css('background-color', '#efefef')
      $('#dam').css('color', '#b7b7b7')
      setEventcss('disabled-link')
      setNextbtn(nextbtn_new)
    }
  }
      const setfirstn = (props,successList) => {
        if (isLoaded) {
          if(successList.valuelistc==='checkgray'){
            setMonthtext(firstmonth)
            if(successList.valuelist2===checkgray){
              onClick1(props, successList)
              setCount(count + 1);
              setPrice1(price1+212000);
              setPrice2(price2+330000);}
              else{
                setPrice1(price1+160000);
                setPrice2(price2+200000);
              }
          successList.valuelistc='checkblue'
          // setGoodslist8((goodslist8) => [...goodslist8, successList.valuelist[0]])
          goodsList.push(successList.valuelist[0])
          sessionStorage.setItem("firstgoods", JSON.stringify(goodsList));
          $('#'+successList.valuelist[0]+"a").css('display', '');
          $('#'+successList.valuelist[0]+"b").css('display', '');
          $('#'+successList.valuelist[0]+"c").css('display', 'none');
          $('#'+successList.valuelist[0]+"d").css('display', '');
          $('#'+successList.valuelist[0]+"e").css('display', 'none');
          $('#'+successList.valuelist[0]+"f").css('display', '');
          }
  
          else {
            
          for(var i = 0; i < goodsList.length; i++){
            if (goodsList[i] === successList.valuelist[0]) {
              goodsList.splice(i, 1);
              i--;
            }
          }
          if (goodsList.length == 0){
            setMonthtext(month12)
          }
          setPrice1(price1-160000);
          setPrice2(price2-200000);
          sessionStorage.setItem("firstgoods", JSON.stringify(goodsList));
          successList.valuelistc='checkgray'
          successList.month=month12
                  $('#'+successList.valuelist[0]+"a").css('display', 'none');
                  $('#'+successList.valuelist[0]+"b").css('display', 'none');
                  $('#'+successList.valuelist[0]+"c").css('display', '');
                  $('#'+successList.valuelist[0]+"d").css('display', 'none');
                  $('#'+successList.valuelist[0]+"e").css('display', '');
                  $('#'+successList.valuelist[0]+"f").css('display', 'none');
  
          }
  
      }
    }
    
    useEffect(() => {
      sessionStorage.setItem('nprice',price1+price2)
  }, [price1, price2]); 

    function CurrencyFormat({ amount }) {
      const formattedAmount = amount.toLocaleString('ko-KR', {
          style: 'currency',
          currency: 'KRW',
          maximumFractionDigits: 0  // 소수점 없이
      });
  
      return <span>{formattedAmount}</span>;
  }
  useEffect(() => {
      $('#next_button').css('background-color', '#efefef')
            $('#dam').css('color', '#b7b7b7')
            setEventcss('disabled-link')
            setNextbtn(nextbtn_new)
    }, [])
    useEffect(() => {
        // 데이터 불러오기 등의 작업
        setIsLoaded(true);
    }, []);
  return (
    <div>
      <TabletView>
      <div
          id="loadingDiv"
          style={{ margin: 'auto', maxWidth: '520px', fontFamily: "'Noto Sans KR', sans-serif" }}
        >
          <img
            src={load_mo}
            style={{ width: '132px', marginTop: '220px', marginLeft: 'calc(50% - 66px)' }}
          />
          <div>
            <img
              src={loadingdot}
              style={{ width: '50px', marginTop: '16px', marginLeft: 'calc(50% - 25px)' }}
            />
          </div>
          <div
            className="Bold"
            style={{ textAlign: 'center', fontSize: '30px', marginTop: '22px' }}
          >
            <a>
              입력해 주신 상표명을 <br />
              검토중입니다.
            </a>
          </div>
          <div
            className="Light"
            style={{ textAlign: 'center', fontSize: '16px', marginTop: '16px', color: '#4A64F5' }}
          >
            <a>동일/유사 상표명이 없으면<br/>
바로 상표등록 신청이 가능합니다!</a>
          </div>
        </div>
        <div
          id="bodydiv"
          style={{
            margin: 'auto',
            maxWidth: '520px',
            fontFamily: "'Noto Sans KR', sans-serif",
            display: 'none',
          }}
        >
          <TopbarComponentWithoutPage path={'/brandcategoryregister'} />

          <div style={{ display: 'inline-block' }}>
            <img
              src={asdzx}
              style={{
                width: '72px',
                position: 'absolute',
                marginLeft: '16px',
                marginTop: '-65px',
              }}
            />
          </div>
          <div className="headLine2 Bold" style={{ display: 'inline-block', marginLeft: '102px' }}>
            입력해주신 상표명을
            <br />
            검토한 결과에요<a style={{ color: '#4A64F5' }}>!</a>
          </div>
          <div className="midbox1">
            <div
              style={{
                paddingTop: '13px',
                marginBottom:'-16px',
                marginRight: '20px',
                marginLeft: '16px',
              }}
            >
              <a
                className="Bold"
                style={{
                  fontSize: '26px',
                  marginTop: '15px',
                  backgroundColor: '#edeffe',
                  textDecoration: 'underline',
                  textDecorationColor: '#4a64f5',
                  textUnderlinePosition: 'under',
                  textDecorationThickness: '3px',
                }}
              >
                {brandnamet}
              </a>
              <div className="Bold" style={{ marginTop: '20px', lineHeight: '1.5' }}>
                <a id="successtext1a" style={{ color: '#4A64F5' }}>
                  {successtext}
                </a>
                <img
                  src={successimg}
                  style={{
                    width: '16px',
                    marginLeft: '3px',
                    position: 'absolute',
                    marginTop: '3px',
                  }}
                />
                <br />
                <a>{successtext2}%</a>
              </div>
              <br />
            </div>
            <div>
              <div style={{ paddingTop: '7px', paddingBottom: '15px', lineHeight: '1.3',borderTop: '2px dotted #e0e0e0',marginRight:'20px' }}>
                <a className="Regular" style={{ fontSize: '12px', color: '#515151' }}>
                  {successtexta}
                  <br />
                  {successtexta2}
                  <br />
                  {successtexta3}
                </a>
                <br />
                <br />

                <a className="Regular" style={{ fontSize: '12px', color: '#4a64f5' }}>
                  {successtexta4}
                </a>
              </div>
            </div>
          </div>
          <div className="midbox12" style={{ paddingBottom: '45px' }}>
            <div
              style={{
                paddingTop: '13px',
                borderBottom: '2px dotted #e0e0e0',
                marginRight: '20px',
                marginLeft: '13px',
              }}
            >
              <div style={{ paddingBottom: '10px' }}>
                <AnimalFriends skarb={valuelist} onClick1={onClick1} text={text} />
              </div>
            </div>
            <div
              style={{
                marginLeft: '15px',
                borderBottom: '2px dotted #e0e0e0',
                marginRight: '20px',
                paddingBottom: '20px',
              }}
            >
              <div
                style={{
                  paddingTop: '7px',
                  paddingBottom: '15px',
                  lineHeight: '1.8',
                  fontSize: '14px',
                }}
              >
                <div className="Medium">
                  <a style={{ color: '#515151', float: 'left' }}>네임텍상표 수수료</a>
                  <a style={{ color: '#898989', float: 'right' }}>110,000원 X {count}건</a>
                  <br />
                  <a style={{ color: '#515151', float: 'left' }}>특허청 관납료</a>
                  <a style={{ color: '#898989', float: 'right' }}>52,000원 X {count}건</a>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: '13px', marginLeft: '13px' }}>
              <a className="Bold" style={{ fontSize: '16px' }}>
                총계
              </a>
              <a
                className="Medium"
                style={{ fontSize: '16px', color: '#4a64f5', float: 'right', marginRight: '20px' }}
              >
                {price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
              </a>
              <div style={{ paddingTop: '7px', paddingBottom: '10px' }}></div>
              <Link to="/brandregister">
                <img
                  src={rechoice}
                  style={{ width: '100px', float: 'right', marginTop: '3px', marginRight: '20px' }}
                />
              </Link>
            </div>
          </div>
          <div style={{ height: '150px' }}></div>
          <Link to="/test6" className={eventcss}>
            <div
              id="next_button"
              className="noto"
              style={{
                cursor: 'pointer',
                position: 'fixed',
                bottom: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#efefef',
              }}
            >
              <div id="dam" style={{ color: '#b7b7b7' }}>
                {' '}
                선택완료
              </div>
            </div>
          </Link>
        </div>
      </TabletView>
      <MobileView>
        <div
          id="loadingDiv"
          style={{ margin: 'auto', maxWidth: '520px', fontFamily: "'Noto Sans KR', sans-serif" }}
        >
          <img
            src={load_mo}
            style={{ width: '132px', marginTop: '230px', marginLeft: 'calc(50% - 66px)' }}
          />
          <div>
            <img
              src={loadingdot}
              style={{ width: '50px', marginTop: '16px', marginLeft: 'calc(50% - 25px)' }}
            />
          </div>
          <div
            className="Bold"
            style={{ textAlign: 'center', fontSize: '30px', marginTop: '22px' }}
          >
            <a>
              잠시만 기다려 주세요 :)
            </a>
          </div>
          {/* <div
            className="Light"
            style={{ textAlign: 'center', fontSize: '16px', marginTop: '16px', color: '#4A64F5' }}
          >
            <a>동일/유사 상표명이 없으면<br/>
바로 상표등록 신청이 가능합니다!</a>
          </div> */}
        </div>
        <div
          id="bodydiv"
          style={{
            margin: 'auto',
            maxWidth: '520px',
            fontFamily: "'Noto Sans KR', sans-serif",
            display: 'none',
          }}
        >
          <TopbarComponentWithoutPage path={'/brandcategoryregister'} />

          <div style={{ display: 'inline-block' }}>
            <img
              src={asdzx}
              style={{
                width: '72px',
                position: 'absolute',
                marginLeft: '16px',
                marginTop: '-65px',
              }}
            />
          </div>
          <div className="headLine2 Bold" style={{ display: 'inline-block', marginLeft: '102px' }}>
          출원에 예상되는 기간,
            <br />
            비용을 확인하세요<a style={{ color: '#4A64F5' }}>!</a>
          </div>
          <div className="midbox1">
            <div
              style={{
                paddingTop: '13px',
                marginBottom:'-16px',
                marginRight: '20px',
                marginLeft: '16px',
              }}
            >
              <div style={{
                    backgroundColor: '#edeffe',
                    padding:'3px',
                    borderBottom:'3px solid #4a64f5',
                    marginTop:'6px',
                    display:'inline-block'
              }}>
                <a
                  className="Bold"
                  style={{
                    fontSize: '22px',
                  }}
                >
                  {brandnamet}
                </a>
                </div>
                <div
                className="Medium"
                style={{ fontSize: '16px',marginTop:'15px',lineHeight:'1.5',paddingBottom:'15px',borderBottom:'1px dashed #ccc'}}
              >
                <a style={{fontSize:'16px'}}>지금 출원하면 <a className='Bold1' style={{color:'#4a64f5'}}>{monthtext}</a>됩니다.
                <br />
                상표 보호를 위해 빨리 등록하실 것을 추천드려요!</a>
                
              </div>
              
              <br />
            </div>
            <div style={{ marginLeft: '15px' }}>
            <div style={{ paddingTop: '11px', paddingBottom: '20px', lineHeight: '22px', textIndent: '20px' }}>
    <img
        src={sclock}
        style={{
            width: '14px',
            position: 'absolute',
            marginTop:'2px'
        }}
    />
    <p className="Medium" style={{ fontSize: '14px', margin: 0 }}>
        ‘심사기간 단축 옵션’<span className='Regular' style={{ color: '#515151' }}>을 선택하시면 </span>
        
        <span style={{ textDecoration: 'underline' }}>
            15개월 이상 소요<br />되는 특허청 심사기간을&nbsp;
        </span>
        <span style={{ textDecoration: 'underline' }}>
             약 2~3개월 수준으로 단축
        </span><span className='Regular' style={{ color: '#515151' }}> 할 수<br />있어요!</span>
    </p>
</div>
            </div>
          </div>
          <div className="midbox12" style={{ paddingBottom: '45px' }}>
            <div
              style={{
                marginRight: '18px',
                marginLeft: '18px',
              }}
            >
              <div style={{}}>
              <AnimalFriends skarb={valuelist} onClick1={onClick1} text={text} setfirstn={setfirstn} />
              </div>
            </div>
            <div
              style={{
                marginLeft: '18px',
                borderBottom: '2px dotted #e0e0e0',
                marginRight: '18px',
                paddingBottom: '23px',
              }}
            >
              <div
                style={{
                  paddingTop: '20px',
                  paddingBottom: '15px',
                  lineHeight: '1.8',
                  fontSize: '16px',
                }}
              >
                <div className="Medium">
                  <a style={{ color: '#515151', float: 'left' }}>특허청 관납료</a>
                  <a style={{ color: '#898989', float: 'right' }}>{price1.toLocaleString()}원</a>
                  <br />
                  <a style={{ color: '#515151', float: 'left' }}>네임텍상표 수수료</a>
                  <a style={{ color: '#898989', float: 'right' }}>{price2.toLocaleString()}원</a>
                </div>
              </div>
            </div>
            <div style={{ paddingTop: '15px', marginLeft: '18px' }}>
              <a className="Bold" style={{ fontSize: '16px' }}>
                총계
              </a>
              <a
                className="Medium"
                style={{ fontSize: '16px', color: '#4a64f5', float: 'right', marginRight: '18px' }}
              >
                {(price1+price2).toLocaleString()}원
              </a>
              <div style={{ paddingTop: '3px', paddingBottom: '10px' }}></div>
              <Link to="/brandregister">
                <img
                  src={rechoice}
                  style={{ width: '100px', float: 'right', marginTop: '3px', marginRight: '18px' }}
                />
              </Link>
            </div>
          </div>
          <div style={{ height: '100px' }}></div>
          <Link to="/test6" className={eventcss}>
            <div
              id="next_button"
              className="noto"
              style={{
                cursor: 'pointer',
                position: 'fixed',
                bottom: '0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#efefef',
              }}
            >
              <div id="dam" style={{ color: '#b7b7b7' }}>
                {' '}
                다음
              </div>
            </div>
          </Link>
        </div>
      </MobileView>
      <BrowserView>
        <BrowserHeader />
        <div
          id="loadingDiv"
          style={{ margin: 'auto', maxWidth: '520px', fontFamily: "'Noto Sans KR', sans-serif" }}
        >
          <img
            src={load_mo}
            style={{ width: '132px', marginTop: '230px', marginLeft: 'calc(50% - 66px)' }}
          />
          <div>
            <img
              src={loadingdot}
              style={{ width: '50px', marginTop: '16px', marginLeft: 'calc(50% - 25px)' }}
            />
          </div>
          <div
            className="Bold"
            style={{ textAlign: 'center', fontSize: '30px', marginTop: '22px' }}
          >
            <a>잠시만 기다려 주세요 :)</a>
          </div>
        </div>
        <div id="bodydiv" style={{ display: 'none' }}>
        <div style={{width:'1400px',paddingLeft:"calc(50% - 700px)"}}>
          <img
            src={ask5}
            style={{
              width: '690px',
              marginTop: '123px',
              marginLeft: 'calc(50% - 602px)',
              marginBottom: '50px',
            }}
          />
          <div
            className="midbox34"
            style={{
              margin: '0 auto',
              textAlign: 'center',
              border: '1px solid #e0e0e0',
              width: '850px',
              marginLeft: 'calc(50% - 425px)',
              borderRadius: '10px',
              boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)',
            }}
          >
            <div
              className="Bold"
              style={{
                paddingTop: '20px',
                borderBottom: '1px dashed #e0e0e0',
                marginRight: '20px',
                marginLeft: '20px',
              }}
            >
              <div style={{ display: 'inline-block', textAlign: 'center' }}>
              <div style={{
                    backgroundColor: '#edeffe',
                    padding:'2px 6px',
                    borderBottom:'3px solid #4a64f5',
                    marginTop:'15px'
              }}>
                <a
                  className="Bold"
                  style={{
                    fontSize: '30px',
                  }}
                >
                  {brandnamet}
                </a>
                </div>
                
                <br />
              </div>
              <div
                className="Bold"
                style={{ marginBottom: '14px', fontSize: '20px'}}
              >
                <a style={{fontSize:'20px'}}>지금 출원하면 <a style={{color:'#4a64f5'}}>{monthtext}</a>됩니다.
                <br />
                상표 보호를 위해 빨리 등록하실 것을 추천드려요!</a>
                
              </div>
              
            </div>
            <div>
              <div style={{ paddingTop: '14px', paddingBottom: '35px', lineHeight: '1.3' }}>
              <img
              src={sclock}
              style={{
                width: '14px',
                position:'absolute',
                marginTop:'1.5px',
                marginLeft:'-17px'
              }}
            />
                <a className="Medium" style={{ fontSize: '14px'}}>
                ‘심사기간 단축 옵션’<a className='Regular' style={{color:'#515151'}}>을 선택하시면</a>
                  <br />
                  <a style={{textDecoration:'underline'}}>15개월 이상 소요되는 특허청 심사기간을</a>
                  <br />
                  <a style={{textDecoration:'underline'}}>약 2~3개월 수준 으로 단축</a><a className='Regular' style={{color:'#515151'}}>할 수 있어요!</a>
                </a>
              </div>
            </div>
          </div>
          <div
            className="midbox123"
            style={{
              margin: '0 auto',
              border: '1px solid #e0e0e0',
              width: '850px',
              marginLeft: 'calc(50% - 425px)',
              marginTop: '50px',
              borderRadius: '10px',
              boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)',
            }}
          >
            <div
              style={{
                paddingTop: '15px',
                marginRight: '36px',
                marginLeft: '13px',
              }}
            >
              <div>
                <AnimalFriends2 skarb={valuelist} onClick1={onClick1} text={text} setfirstn={setfirstn} />
              </div>
            </div>
            <div
              style={{
                paddingTop: '3px',
                paddingBottom: '27.5px',
                paddingTop:'27.5px',
                borderBottom: '1px dashed #e0e0e0',
                marginRight: '36px',
                marginLeft: '36px',
                marginBottom: '27.5px',
              }}
            >
              <div style={{}}>
                <div className="Regular">
                  <a
                    className="Bold"
                    style={{
                      fontSize: '22px',
                      marginTop: '15px',
                      color: '#515151',
                    }}
                  >
                    특허청 관납료
                  </a>
                  <a
                    style={{
                      cursor: 'pointer',
                      float: 'right',
                      color: '#898989',
                      fontSize: '22px',
                    }}
                    className={'priceFont Regular1'}
                  >
                    {price1.toLocaleString()}원
                  </a>
                </div>

                <div className="Regular" style={{ marginTop: '20px'}}>
                  <a
                    className="Bold"
                    style={{
                      fontSize: '22px',
                      marginTop: '15px',
                      color: '#515151',
                    }}
                  >
                    네임텍상표 수수료
                  </a>
                  <a
                    style={{
                      cursor: 'pointer',
                      float: 'right',
                      color: '#898989',
                      fontSize: '22px',
                    }}
                    className={'priceFont Regular1'}
                  >
                   {price2.toLocaleString()}원
                  </a>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: '15px' }}>
              <div>
                <a className="Bold" style={{ fontSize: '22px', marginLeft: '20px' }}>
                  총계
                </a>
                <a
                  className="Medium"
                  style={{
                    fontSize: '22px',
                    color: '#4a64f5',
                    float: 'right',
                    marginRight: '36px',
                  }}
                >
                  {(price1+price2).toLocaleString()}원
                </a>
              </div>
              <Link to="/brandregister">
                <img
                  src={rechoice}
                  style={{
                    width: '130px',
                    position: 'absolute',
                    marginTop: '3px',
                    marginLeft: '669px',
                    marginTop: '20px',
                  }}
                />
              </Link>
            </div>
            <div style={{ height: '60px',marginBottom:'30px' }}></div>
          </div>
          <div style={{ height: '300px' }}></div>
        </div>
        </div>
        <div
          id="introFooter"
          style={{
            float: 'right',
            backgroundColor: '#f5f6ff',
            height: '116px',
            lineHeight: '110px',
            display:'none'
          }}
        >
          <Link to="/test6" className={eventcss}>
            <img
              src={nextbtn}
              style={{
                cursor: 'pointer',
                width: '200px',
                marginLeft: '20px',
                marginTop: '27px',
                float: 'right',
                marginRight: 'calc(50% - 485px)',
              }}
            />
          </Link>
          <a href='http://pf.kakao.com/_lWuhs/chat'>
          <img
            src={kakaoaa}
            style={{ cursor: 'pointer', width: '283px ', marginTop: '27px', float: 'right' }}
          />
          </a>
          <div className="Bold1" style={{ fontSize: '30px', marginLeft: 'calc(50% - 485px)' }}>
            <a>
              총 <a style={{ color: '#4a64f5' }}>{count}</a>개 카테고리 선택 완료
            </a>
          </div>
        </div>
      </BrowserView>
    </div>
  )
}

export default Test5
