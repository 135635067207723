import React, { useState } from "react";
import KakaoLogin from "react-kakao-login";
import TopbarComponent from "../components/Topbar";
import GuidelineComponent from "../components/Guideline";
import Nextbutton from "../components/Nextbutton";
import NaverLogin from "../components/NaverLogin";
import SocialKakao from "../components/SocialKakao";
import SocialKakao2 from "../components/SocialKakao2";
import "../style.css";
import x_round from "../image/x_round.png";
import TopbarComponentWithoutPage from "../components/Topbarwithoutpage";
import character_profile from "../image/login_mo.png";
import askask from "../image/askask.png";
import asdas123a from "../image/asdas123a.png";
import kakaoaa from "../image/kakaoaa.png";
import naverbtn from "../image/naverbtn.png";
import nextbtn_new from "../image/nextbtn_new.png";
import new_blue from "../image/new_blue.png";
import "../text.css";
import navernaver from "../image/naver@3x.png";
import kakaotalk1 from "../image/kakaotalk@3x.png";
import TestNaver from "../components/TestNaver";
import $ from "jquery";
import { BrowserRouter, Route, Switch, Link, NavLink, useParams } from 'react-router-dom';
import {
  BrowserView,
  MobileView,
  isBrowser,
  IsMobile,
  TabletView,
} from "react-device-detect";
import BrowserHeader from "../components/BrowserHeader";
import { useEffect } from "react";

function TestLogin() {
  const naverlogin = () => {
    let nv = document.getElementById("naverIdLogin_loginButton");
    let img = nv.childNodes[0];

    img.click();
  };

  return (
    <div>
      <TabletView>
        <div style={{ margin: "auto", maxWidth: "520px" }}>
          <TopbarComponentWithoutPage path={"/"} />
          <div style={{ textAlign: "center" }}>
            <img
              src={character_profile}
              style={{ width: "114px", height: "114px", marginTop: "20%" }}
            />
          </div>
          <div
            className="Bold1"
            style={{
              textAlign: "center",
              margin: "0px",
              marginTop: "18px",
              marginBottom: "69px",
              fontSize: "30px",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "1.27",
              color: "#000000",
            }}
          >
            간편 로그인하고 <br />
            바로 출원 신청 하세요.
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              src={navernaver}
              onClick={naverlogin}
              style={{ cursor: "pointer" }}
            />
          </div>
          <NaverLogin />
          <div style={{ textAlign: "center" }}>
            <SocialKakao2 />
          </div>
        </div>
      </TabletView>
      <MobileView>
        <div style={{ margin: "auto", maxWidth: "520px" }}>
          <TopbarComponentWithoutPage path={"/"} />
          <div style={{ textAlign: "center" }}>
            <img
              src={character_profile}
              style={{ width: "114px", height: "114px", marginTop: "20%" }}
            />
          </div>
          <div
            className="Bold1"
            style={{
              textAlign: "center",
              margin: "0px",
              marginTop: "18px",
              marginBottom: "69px",
              fontSize: "30px",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "1.27",
              color: "#000000",
            }}
          >
            간편 로그인하고 <br />
            바로 출원 신청 하세요.
          </div>
          <div style={{ textAlign: "center" }}>
            <img
              src={navernaver}
              onClick={naverlogin}
              style={{ cursor: "pointer", width: "353px", height: "70px" }}
            />
          </div>
          <NaverLogin />
          <div style={{ textAlign: "center" }}>
            <SocialKakao2 />
          </div>
        </div>
      </MobileView>
      <BrowserView>
        <div style={{ margin: "0 auto", minWidth: "1000px" }}>
          <BrowserHeader />
          <div
            className="askBox"
            style={{
              width: "900px",
              marginLeft: "calc(50% - 500px)",
              marginTop: "150px",
            }}
          >
            <div
              className="Bold"
              style={{ fontSize: "40px", marginLeft: "139px" }}
            >
              간편 로그인 하고 5분만에 상표등록 하기:)
            </div>
            <div style={{ width: "980px", height: "334px", marginTop: "50px" }}>
              <img
                src={naverbtn}
                onClick={naverlogin}
                style={{
                  cursor: "pointer",
                  width: "380px",
                  marginTop: "40px",
                  marginLeft: "301px",
                }}
              />
              <NaverLogin />
              <SocialKakao />
              <div
                className="Regular"
                style={{
                  width: "420px",
                  height: "69px",
                  marginTop: "30px",
                  paddingTop: "21px",
                  borderRadius: "10px",
                  backgroundColor: "#edeffe",
                  textAlign: "center",
                  marginLeft: "280px",
                }}
              >
                <a>
                  <a
                    style={{ color: "#4a64f5", textDecoration: "underline" }}
                    href="https://nametecadmin.kr/termsOfService"
                  >
                    서비스 이용약관
                  </a>{" "}
                  및{" "}
                  <a
                    href="https://nametecadmin.kr/privacyPolicy"
                    style={{ color: "#4a64f5", textDecoration: "underline" }}
                  >
                    개인정보취급방침
                  </a>
                  의<br />
                  내용을 확인 하였으며, 동의합니다
                </a>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
    </div>
  );
}

export default TestLogin;
